import React, { useState, useRef } from "react";
import Head from "../../../layout/head/Head";
import Content from "../../../layout/content/Content";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import classnames from "classnames";
import { useEffect } from "react";
import { URLS } from "../../../constants/urls";
import { BlockHead, BlockHeadContent } from "../../../components/Component";
import { DropdownItem, UncontrolledDropdown, DropdownMenu, DropdownToggle, CardTitle } from "reactstrap";
import {
  Block,
  BlockTitle,
  BlockBetween,
  BlockDes,
  Icon,
  Row,
  Col,
  Button,
  DataTableHead,
  DataTableBody,
  DataTableRow,
  DataTableItem,
  PaginationComponent,
  ReactDataTable,
  UserAvatar,
  PreviewCard,
} from "../../../components/Component";
import { Link } from "react-router-dom";
import { Card, Badge } from "reactstrap";
import { useHistory } from "react-router-dom";
import { Modal, ModalFooter, ModalHeader, ModalBody } from "reactstrap";
import { makePostRequest, makeGetRequest } from "../../../service/api";
import ReactPaginate from "react-paginate";
import { AreaChart, Area, BarChart, Bar, ResponsiveContainer, CartesianGrid, XAxis, YAxis, Tooltip, Legend, Label, LineChart, Line, LabelList } from "recharts";
import moment from "moment";
import DatePicker from "react-datepicker";
import Select from "react-select";
import { parseBusinessHours } from "@fullcalendar/core";
import Highcharts, { chart } from "highcharts";
import { findUpper, capital } from "../../../utils/Utils";
import HighchartsReact from 'highcharts-react-official';
// import exporting from "highcharts/modules/exporting";
import { ToastContainer, toast } from "react-toastify";

// exporting(Highcharts);

const ExcelJS = require('exceljs');

function ResourceSignupReport() {

  let history = useHistory();
  const [activeTab, setActiveTab] = useState("1");
  const [activeIconTab, setActiveIconTab] = useState("5");
  const [activeAltTab, setActiveAltTab] = useState("9");
  //   const [verticalTab, setVerticalTab] = useState("1");
  const [verticalIconTab, setVerticalIconTab] = useState("1");
  const [resourceSignupData, setResourceSignupData] = useState([]);
  const [resourceChartData, setResourceChartData] = useState([]);
  const [loader, setLoader] = useState(false);
  const [loader1, setLoader1] = useState(false);
  const [selectyear, setSelectyear] = useState(new Date().getFullYear());
  const [displayvalue, setDisplayValue] = useState("1");
  const [startdate, setStartDate] = useState("2023-01-01");
  const [enddate, setEndDate] = useState(new Date());
  const [selectedfromdate, setSelectedFromDate] = useState(null);
  const [selectedtodate, setSelectedToDate] = useState(null);
  const [user, setUser] = useState("");
//   const [userNameData, setUserNameData] = useState([])
//   const [userName, setNameUser] = useState("")
  const [limitvalue, setlimitvalue] = useState("")
  const [sortvalue, setSortValue] = useState("")
  const [showchart, setshowchart] = useState([])
//   const [getAllResourceNew, setgetAllResourceNew] = useState([]);
//   const [resourceType, setResourceType] = useState("");
  const [resourceTypeText, setResourceTypeText] = useState("");
  const [datepickershow, setDatePickerShow] = useState(false);
  const [datepickershow1, setDatePickerShow1] = useState(false);

  const [toDateError, settoDateError] = useState("");
  const [formDateError, setformDateError] = useState("");
  const [customerSpaceData, setCustomerSpaceData] = useState([])
  const [isOpen, setIsOpen] = useState(false);
  const [totalCount,setTotalCount]=useState("");
  const [zipCode,setZipCode]=useState("");

  const year = 2023;
  const curryear = new Date().getFullYear();
  let years = Array.from(new Array(curryear - 2023 + 2), (val, index) => index + year);
  const colorThemes = ["primary", "purple", "danger", "warning", "success", "pink", "secondary", "blue"]
  let chartdata = []
  let SpaceData = []


  const [mentoringDetails, setMentoringDetails] = useState("");
  const [signUpDetails, setSignupDetails] = useState("");
   const [signupDetailModal,setSignupDetailModal]=useState(false);

          const SignupModalFunction = () => {
            setSignupDetailModal(!signupDetailModal);
          }
        
          const showSignupdetails = (id) => {
            setSignupDetails(id)
            console.log(id, "id")
            setSignupDetailModal(true);
            }


            function getTime(time) {
              let [h, m] = time.split(':');
             
              
              let res,prefix;
              let date = new Date();
              if(h > 12)
              {
                h = h - 12;
                prefix="PM"
              }
              else
              {
                prefix="AM"
              }
               date.setHours(h, m, 0)
               date.toString();
               if(date.getHours() < 10 && date.getMinutes() < 10)
                 res = `0${date.getHours()}:0${date.getMinutes()}`;
              else    if(date.getHours() < 10 && date.getMinutes() > 10)
                res = `0${date.getHours()}:${date.getMinutes()}`;
                else
              if(date.getMinutes() < 10 && date.getHours() > 10)
                res = `${date.getHours()}:0${date.getMinutes()}`;
               else
              
               res = `${date.getHours()}:${date.getMinutes()}`
                res = res + " " + prefix;
              return res;
             
            }

   




  /*===========================filter ALL code here =====================*/

 const [groupType,setGroupType]=useState("");
 const [resourceTypeGraph,setResourceTypeGraph]=useState("");
 
 const [resourceTypedata,setResourceTypeData]=useState([]);
 const [resourceValue,setResourceValue]=useState("");
 const [resourceValueGraph,setResourceValueGraph]=useState("");
 
 const [resourceUserName,setResourceUserName]=useState("");
 const [userNameData,setUserNameData]=useState([]);
 const [resourceUserNameGraph,setResourceUserNameGraph]=useState("");
 
 const [groupName,setGroupName]=useState("");
 const [groupData,setGroupData]=useState([]);
 const [groupNameGraph,setGroupNameGraph]=useState("");

  const [customerData,setCustomerData]=useState([]);
  const [customerName,setCustomerName]=useState("");

  const [mentorData,setMentorData]=useState([]);
  const [mentorName,setMentorName]=useState("");
  const [mentorNameGraph,setMentorNameGraph]=useState("")

  const [childData,setChildData]=useState([]);
  const [childName,setChildName]=useState("");
  const [childNameGraph,setChildNameGraph]=useState("")
 
 console.log(resourceUserName,"user",groupName,"group",groupType,"groupType",resourceValue,"resource")
 /*=======================Get Resource Api=============================*/
 
     const getAllResourceType = () => {
       let data=
         {
          "item_per_page":20,
          "page_number":1,
          "resource_type_status":groupType,
          "user_id":mentorName,
          "group_resource_id":groupName,
       }
      
    //  setLoader(true);
     makePostRequest(URLS.GETALLRESOURCE_SIGNUP_FILTER,null, data, null)
       .then(res => {
         console.log(res,"resourcedata");
         if (res.code == 200) {
           setResourceTypeData(res.data.data);
          //  setLoader(false);
         }
       })
       .catch((error) => {
         console.log(error);
       })
 
   }
 
   useEffect(()=>{
    getAllResourceType();
     },[groupType,mentorName,groupName])
     
    //  useEffect(()=>{
    //   getResourceSignup ();
    // },[mentorName,groupName])
 
   /*=======================Get User Api=============================*/
 
   const getAllUser = () => {
     let data=
       {
          "item_per_page":20,
          "page_number":1,
         resource_type_status:"individual",
     }
    
   setLoader(true);
   makePostRequest(URLS.GET_ALL_GROUPORUSER_SIGNUP,null, data, null)
     .then(res => {
       console.log(res,"userdata");
       if (res.code == 200) {
         setUserNameData(res.data.data);
         setLoader(false);
       }
     })
     .catch((error) => {
       console.log(error);
     })
 
 }
 
 
 
 /*=======================Get Group Api=============================*/
 
 const getAllGroup = () => {
   let data=
     {
        "item_per_page":20,
        "page_number":1,
        resource_type_status: groupType,
   }
  
 setLoader(true);
 makePostRequest(URLS.GET_ALL_GROUPORUSER_SIGNUP,null, data, null)
   .then(res => {
     console.log(res,"Groupdata");
     if (res.code == 200) {
       setGroupData(res.data.data);
       setLoader(false);
     }
   })
   .catch((error) => {
     console.log(error);
   })
 
 }

/*=======================Mentor Api=============================*/
 
const getAllMentor = () => {
  let data=
    {
    
      "page_number":1,
      
  }
 
// setLoader(true);
makePostRequest(URLS.GETALLMENTOR_MS_SIGNUP,null, data, null)
  .then(res => {
    console.log(res,"Mentordata");
    if (res.code == 200) {
      setMentorData(res.data.data);
      setLoader(false);
    }
  })
  .catch((error) => {
    console.log(error);
  })

}


/*=======================Get Child Api=============================*/
 
const getAllChild = () => {
  let data=
    {
     
      "page_number":1,
      
  }
 
setLoader(true);
makePostRequest(URLS.GETALLCHILD_MS_SIGNUP,null, data, null)
  .then(res => {
    console.log(res,"Childdata");
    if (res.code == 200) {
      setChildData(res.data.data);
      setLoader(false);
    }
  })
  .catch((error) => {
    console.log(error);
  })

}


/*=======================Get Customer Api=============================*/
 
const getAllCustomer = () => {
  let data=
    {
     
      "page_number":1,
      
  }
 
setLoader(true);
makePostRequest(URLS.GETALLCUSTOMER_MS_SIGNUP,null, data, null)
  .then(res => {
    console.log(res,"Customerdata");
    if (res.code == 200) {
      setCustomerData(res.data.data);
      setLoader(false);
    }
  })
  .catch((error) => {
    console.log(error);
  })

}
 
 const handleResourceGroupTypeList =(e)=>{
   setGroupType(e.target.value)

   if(e.target.value==="group"){
    setMentorName("")
    setResourceValue("")
  }

  if(e.target.value==="individual"){
    setGroupName("")
    setResourceValue("")
  }
  if (e.target.value === "") {
    setGroupName("");
    setMentorName("");
    setResourceValue("");
    
  }

 }
 const handleResourceTypeGraph=(e)=>{
   setResourceTypeGraph(e.target.value)

   if(e.target.value==="group"){
     setMentorNameGraph("")
     setResourceValueGraph("")
  }

  if(e.target.value==="individual"){
    setGroupNameGraph("")
    setResourceValueGraph("")
  }
  if (e.target.value === "") {
    setGroupNameGraph("");
     setMentorNameGraph("");
    setResourceValueGraph("");
    
  }
 }
 
 
   const handleResourceTypeList=(e)=>{
     setResourceValue(e.target.value)
   }
 
   const handleResourceTypeListGraph=(e)=>{
     setResourceValueGraph(e.target.value)
   }
   const handleResourceUserListGraph=(e)=>{
     setResourceUserNameGraph(e.target.value)
   }
 
   const handleResourceGroupListGraph=(e)=>{
    setGroupNameGraph(e.target.value)
  }
  const handleResourceChildListGraph=(e)=>{
    setChildNameGraph(e.target.value)
  }
  const handleResourceMentorListGraph=(e)=>{
    setMentorNameGraph(e.target.value)
  }
 
   const handleResourceUserList=(e)=>{
     setResourceUserName(e.target.value)
   }
 
   const handleResourceGroupList=(e)=>{
     setGroupName(e.target.value)
   }
 
   const handleResourceCustomerList=(e)=>{
    setCustomerName(e.target.value)
  }


  const handleResourceMentorList=(e)=>{
    setMentorName(e.target.value)
  }
  const handleResourceChildList=(e)=>{
    setChildName(e.target.value)
  }
  const handleZipCode =(e)=>{
    setZipCode(e.target.value)
}
 
 useEffect(()=>{
   getAllResourceType();
   getAllUser();
   getAllGroup();
   getAllCustomer();
   getAllMentor();
   getAllChild();
 },[])




  const handleyear = (e) => {
    setSelectyear(e.target.value);
  }

//   const HandleUserName = (e) => {
//     setNameUser(e.value);
//   }

  const HandleFromDate = (e) => {
    setSelectedFromDate(e);
    setDatePickerShow(!datepickershow);
    setformDateError("");
  }
  //console.log(selectedfromdate, "selectedfromdate")

  const HandleToDate = (e) => {
    setSelectedToDate(e);
    setDatePickerShow1(!datepickershow1);
    settoDateError("");

  }

  const handlechart = (e) => {
    getresourceChartData();
    // setNameUser("");
  }
  const HandleLimitValue = (e) => {

    setlimitvalue(e.target.value)
    setCurrentPage(1)
  }
  const HandleSort = (e) => {
    setSortValue(e.target.value);
    setCurrentPage(1)
  }
  function handleclickdatepickericon() {
    setDatePickerShow(!datepickershow);
  }
  function handleclickdatepickericon1() {
    setDatePickerShow1(!datepickershow1);
  }

//   const handleResourceType = (e) => {
//     let name = e.target.name;
//     let value = e.target.value;
//     if (name === "resourceType") {
//       let index = e.nativeEvent.target.selectedIndex;
//       let label = e.nativeEvent.target[index].text;
//       setResourceTypeText(label);
//       setResourceType(value);
//       //  console.log("name",label,"value",value)
//     } else if (name === "curriculamContentType") {
//       setCurriculamContentType(value);
//     } else if (name === "experienceType") {
//       setExperienceType(value);
//     } else if (name === "facilityType") {
//       setFacilityType(value);
//     }
//     else if (name === "fundingType") {
//       setFundingType(value);
//     }
//     else if (name === "organizationType") {
//       setOrganizationType(value);
//     }
//     else if (name === "studentSupportType") {
//       setStudentSupportType(value);
//     }
//     else if (name === "teacherParentSupportType") {
//       setTeacherParentSupportType(value);
//     }
//     else if (name === "timeFrameTypeCariculam") {
//       setTimeFrameTypeCariculam(value);
//     } else if (name === "timeFrameTypeExperience") {
//       setTimeFrameTypeExperience(value);
//     }
//     else {
//       setState({ ...state, [name]: value });
//     }
//   }

  const ExportEcelFile = () => {
    setLoader(true);
    let data = {
      start_date: selectedfromdate ? moment(selectedfromdate).format("YYYY-MM-DD") : "",
      end_date: selectedtodate ? moment(selectedtodate).add(1, 'd').format("YYYY-MM-DD") : "",
       export_data: "yes",
       limit:limitvalue ? parseInt(limitvalue) : 10,
      page: currentPage ? currentPage : 1,
      child_id:childName ? childName :"",
      mentor_id:mentorName ? mentorName :"",
      // resource_user_id:customerName ? customerName :"",
       resource_id:resourceValue ? resourceValue :"",
      resource_type_status:groupType ? groupType :"",
      group_id:groupName ? groupName :"",
      zipcode:zipCode ? zipCode :"",
    };

    makePostRequest(URLS.GETRESOURCESIGNUPLIST, null, data, null)
      .then((res) => {
        if (res.code == 200) {
          //console.log("Resource export", res.data.data)
          const workbook = new ExcelJS.Workbook();
          const sheet = workbook.addWorksheet("ResourceSignup");
          sheet.properties.defaultRowHeight = 30;



          sheet.getRow(1).font = {
            size: 10,
            bold: true,
          }

          sheet.columns = [
            {
              header: "S.No",
              key: 'S_No',
              width: 5
            },

            {
              header: "Customer Name",
              key: 'USER_NAME',
              width: 30
            },
            {
              header: "Date Registered",
              key: 'saveddate',
              width: 30
            },

            {
              header: "Email",
              key: 'OWNEREMAIL',
              width: 30
            },
            {
              header: "Phone",
              key: 'OWNWERPHONE',
              width: 30
            },

            {
              header: "Resource Name",
              key: 'Resource_Name',
              width: 20
            },
            {
              header: "Student Name",
              key: 'ChildName',
              width: 20
            },

             {
              header: "Session Date",
              key: 'Date',
              width: 20
            },

            {
              header: "Session Time",
              key: 'Time',
              width: 20
            },
          ];
          res.data.data?.map((val, i) => {


           let datesignup= val?.tbl_user_mentoring_session_datetimes && val?.tbl_user_mentoring_session_datetimes.map((value, i) => value?.user_session_date ? moment(value?.user_session_date).format("MM-DD-YYYY") : "--" )
           let flatarray = datesignup?.join(',');
           let time=val?.tbl_user_mentoring_session_datetimes && val?.tbl_user_mentoring_session_datetimes.map((value, i) =>value?.user_session_time ? getTime(value?.user_session_time) :"--")
           let times= time?.join(',')
// console.log(flatarray,"flat array")
            sheet.addRow({
              S_No: (i + 1),
              saveddate: val?.created_at ? moment(val?.created_at).format("MM-DD-YYYY") : "--",
              USER_NAME: val?.purchased_user?.firstName + " " + val.purchased_user?.lastName,
              OWNEREMAIL: val?.purchased_user?.email,
              OWNWERPHONE: val?.purchased_user?.phone,
              Resource_Name: val?.tbl_user_resource?.resource_title,
              ChildName:val?.tbl_child.firstName ? val?.tbl_child.firstName :"--" +" "+ val?.tbl_child.lastName,
            // Date: val?.tbl_user_mentoring_session_datetimes && val?.tbl_user_mentoring_session_datetimes.map((value, i) => value?.user_session_date ? moment(value?.user_session_date).format("MM-DD-YYYY") : "--" ),
            Date:flatarray,
//Time:val?.tbl_user_mentoring_session_datetimes && val?.tbl_user_mentoring_session_datetimes.map((value, i) =>value?.user_session_time ? getTime(value?.user_session_time) :"--"),
Time:times

            })
           
          })

          workbook.xlsx.writeBuffer().then(data => {
            const blob = new Blob([data], {
              type: "application/vnd.openxmlformats-officedocument.spreadsheet.sheet",
            })
            const url = window.URL.createObjectURL(blob);
            const anchor = document.createElement('a');
            anchor.href = url;
            anchor.download = 'ResourceSignup.xlsx';
            anchor.click();
            window.URL.revokeObjectURL(url)
          })
          setLoader(false);
         console.log(res.data, "res signup")

        }
      })
      .catch((error) => {
        console.log(error);
      });

  }

//   const getAllUserType = () => {
//     setLoader(true);
//     makeGetRequest(URLS.GET_ALL_RESOURCE_TYPE, {}, null)
//       .then(res => {
//         if (res.code == 200) {
//           setgetAllResourceNew(res.data);
//           setLoader(false);
//         }
//       })
//       .catch((error) => {
//         console.log(error);
//       })

//   }

  const openModal = () => {
    setIsOpen(!isOpen);
  };

  
  const handlesearch = (e) => {
    // setLoader(true);

    // let data = {
    //   start_date: selectedfromdate ? moment(selectedfromdate).format("YYYY-MM-DD") : "2023-01-01",
    //   end_date: selectedtodate ? moment(selectedtodate).add(1, 'd').format("YYYY-MM-DD") : moment(new Date()).format("YYYY-MM-DD"),
    //   user_id: userName ? userName : "",
    //   page_number:currentPage?currentPage:1,
    //   item_per_page:limitvalue?parseInt(limitvalue):10,

    // };

    // console.log(data, "data")
    // makePostRequest(URLS.GET_CUSTOMER_LOGGEDIN_REPORTSLIST, null, data, null)
    //   .then((res) => {
    //     // setLoader(true)
    //     if (res.code === 200) {
    //       console.log(res.data.user_data, "login data");
    //        setCustomerloginreport(res?.data?.user_data);
    //       setLoader(false);

    //     } else {
    //       setLoader(false);
    //     }
    //   })
    //   .catch((error) => {
    //     console.log(error);
    //   });
    // // getcustomerloginreportchart();
    // setNameUser("");
    if (selectedfromdate != null && selectedtodate == null) {
      settoDateError("please select valid end date")
    }
    else if (selectedtodate != null && selectedfromdate == null) {
      setformDateError("please select valid start date ")
    }
    else if (selectedfromdate > selectedtodate) {
      toast.error("end date should be greater than start date")
    }
    else {

      getResourceSignup();
      setCurrentPage(1)
    }
  }


  //user type get api GET_USER_TYPE

//   const getUserName = () => {
//     makePostRequest(URLS.GET_ALL_ONLY_CUSTOMER, null, {}, null)
//       .then((res) => {
//        // console.log(res)
//         if (res.code == 200) {
//           let option = [];
//           option.push({ value: "", label: "All Users" })
//           res.data.length > 0 && res.data.map((val) => {
//             option.push({ value: val.id, label: val.firstName + " " + val.lastName })
//           })
//           setUserNameData(option);
//         }
//       })
//       .catch((error) => {
//         console.log(error);
//       });
//   };

  const getresourceChartData = () => {
    setLoader(true);
    let data = {
      year: selectyear,
    child_id:childNameGraph ? childNameGraph :"",
   mentor_id:mentorNameGraph ? mentorNameGraph :"",
   group_id:groupNameGraph ? groupNameGraph :"",
   resource_type_status:resourceTypeGraph ? resourceTypeGraph :"",
   resource_id:resourceValueGraph ? resourceValueGraph :"",
    };
    console.log("data chart:::", data)
    makePostRequest(URLS.GETRESOURCESIGNUPGRAPH, null, data, null)
      .then((res) => {
        // setLoader(true)
        if (res.code === 200) {
          console.log(res, "ResourceGraphres");
          setResourceChartData(res?.data?.graph_data);
          setLoader(false);

        } else {
          setLoader(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const [clearStateGraph, setClearStateGraph] = useState(false);
  const [clearStateList, setClearStateList] = useState(false);
  /*==============================Resource SignUp List Api ==============================*/

  const [currentPage, setCurrentPage] = useState(1);
  const [count, setCount] = useState();

  const getResourceSignup = () => {
    setLoader1(true);
    let data = {
      start_date: selectedfromdate ? moment(selectedfromdate).format("YYYY-MM-DD") : "",
      end_date: selectedtodate ? moment(selectedtodate).add(1, 'd').format("YYYY-MM-DD") : "",
       export_data: "no",
       limit:limitvalue ? parseInt(limitvalue) : 10,
      page: currentPage ? currentPage : 1,
      child_id:childName ? childName :"",
      mentor_id:mentorName ? mentorName :"",
      // resource_user_id:customerName ? customerName :"",
       resource_id:resourceValue ? resourceValue :"",
      resource_type_status:groupType ? groupType :"",
      group_id:groupName ? groupName :"",
      zipcode:zipCode ? zipCode :"",
    };
    console.log(data, "data")
    makePostRequest(URLS.GETRESOURCESIGNUPLIST, null, data, null)
      .then((res) => {
        // setLoader(true)
        if (res.code === 200) {
          console.log(res, "Resourcesignup  data list");
          setResourceSignupData(res?.data.data);
          setCount(Math.ceil(res.data.count / parseInt(limitvalue ? limitvalue : 10)))
          setLoader1(false);
          setTotalCount(res.data.count)

        } else {
          setLoader1(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
    // setNameUser("");
  }

  const handlePageChange = (selectedPage) => {
    setCurrentPage(selectedPage.selected + 1)
  };
  // const fetchData = () => {
  //   let data = {
  //     start_date: selectedfromdate ? moment(selectedfromdate).format("YYYY-MM-DD") : "",
  //     end_date: selectedtodate ? moment(selectedtodate).add(1, 'd').format("YYYY-MM-DD") : "",
  //      export_data: "no",
  //      limit:limitvalue ? parseInt(limitvalue) : 10,
  //     page: currentPage ? currentPage : 1,
  //     child_id:childName ? childName :"",
  //     mentor_id:mentorName ? mentorName :"",
  //     // resource_user_id:customerName ? customerName :"",
  //      resource_id:resourceValue ? resourceValue :"",
  //     resource_type_status:groupType ? groupType :"",
  //     group_id:groupName ? groupName :"",
  //     zipcode:zipCode ? zipCode :"",
  //   }
  //   makePostRequest(URLS.GETRESOURCESIGNUPLIST, null, data, null)
  //     .then((res) => {
  //       if (res.code == 200) {
         
  //         setResourceSignupData(res?.data.data);
  //         setCount(Math.ceil(res.data.count / parseInt(limitvalue ? limitvalue : 10)))
  //       }
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // };

     const clearFilterGraph = () => {
    clearFilterGraphFun();
  }

  const clearFilterGraphFun = () => {
	setSelectyear(new Date().getFullYear());
    setResourceTypeGraph("");
    setMentorNameGraph("");
	  setGroupNameGraph("");
    setResourceValueGraph("");
    setClearStateGraph(!clearStateGraph);
    
  }
  
    const clearFilterList = () => {
    clearFilterListFun();
  }

  const clearFilterListFun = () => {
    setlimitvalue("");
    setSelectedFromDate(null);
    setSelectedToDate(null);
	  setZipCode("");
    setGroupType("");
	  setGroupName("");
    setMentorName("");
    setCurrentPage(1);
    setResourceValue("");

    setClearStateList(!clearStateList);
  }
  
  


  useEffect(() => {
   // fetchData()
   getResourceSignup();
  }, [currentPage, limitvalue, sortvalue])


  useEffect(() => {
    getResourceSignup();
    getresourceChartData();
  }, []);


   useEffect(() => {
     getresourceChartData();
   }, [clearStateGraph])
   
    useEffect(() => {
     getResourceSignup();
   }, [clearStateList])
   
  useEffect(() => {
    getchartdata();
  }, [resourceChartData])

  const getchartdata = () => {
    resourceChartData?.map((val) => {
      chartdata.push(val?.total_signup_count)

    })
    setshowchart(chartdata)
  }


  const columns = [
    {
      name: "#",
      selector: (row) => row.id,
      grow: 1,
      minWidth: "50px",
      style: { minWidth: "50px" },
      borderRadius: "4px",
      cell: (row, index) => (
        <>
          <div className="user-card mt-2 mb-2"> { currentPage==1?(index + 1):((currentPage-1)*(limitvalue!=""?parseInt(limitvalue):10))+(index + 1)} </div>
        </>
      ),
    },
    {
        name: "Date Registered",
        selector: (row) => "",
        grow: 2,
  
        cell: (row, index) => (
          <>
            <div className="user-card mt-2 mb-2">
            <span className="tb-lead">
              {row?.created_at ? moment(row?.created_at).format("MM-DD-YYYY") : "--"}
              </span>
            </div>
          </>
        ),
      },
      {
        name: "Resource Name",
        selector: (row) => row.month,
        grow: 2,
  
        cell: (row, index) => (
          <>
            <div className="user-card mt-2 mb-2">
              <div className="user-info">
                <span className="sub-text">
                  {row?.tbl_user_resource?.resource_title ? row?.tbl_user_resource?.resource_title : "--"}
                </span>
  
               
              </div>
            </div>
          </>
        ),
      },
      {
        name: "Owner Name",
        selector: (row) => "",
        grow:2,
        sortable: true,
        cell: (row) => (
        <div className="user-card mt-2 mb-2">
          <div className="user-info">
          <span className="sub-text">{ row?.sold_by_user.firstName ? row?.sold_by_user.firstName + " " + row?.sold_by_user.lastName :"--"}</span>
          
          </div>
        </div>
    
        ),
      },

    {
      name: "Customer Details",
      selector: (row) => row?.tbl_purchased_user,
      grow:2,
      cell: (row, index) => (
        <>
         
          <div className="user-card mt-2 mb-2">
            <div className="user-info">
              <span className="tb-lead">
                {row?.purchased_user ? (capital(row?.purchased_user?.firstName + " " + row?.purchased_user?.lastName?.trim())) : "--"}
              </span>
              <span className="sub-text">
                {row?.purchased_user ?(row?.purchased_user?.email):""}
              </span>
              {/* <span className="sub-text">
                {row?.user ?(row?.user?.phone):""}
              </span> */}
            </div>
          </div>
        </>
      ),
    },
    
    {
      name: "Student Name",
      selector: (row) => "",
      grow:2,
      sortable: true,
      cell: (row) => (
      <div className="user-card mt-2 mb-2">
        <div className="user-info">
        <span className="sub-text">{ row?.tbl_child.firstName ? row?.tbl_child.firstName :"--"}{" "}{row?.tbl_child.lastName}</span>
        
        </div>
      </div>
  
      ),
    },
    {
      name: "Signup Date/Time ",
      selector: (row) => "",
      grow:2,
      sortable: true,
      cell: (row) => (
      <div className="user-card mt-2 mb-2">
        <div className="user-info">
        {row?.tbl_user_mentoring_session_datetimes && row?.tbl_user_mentoring_session_datetimes?.map((val, i) => {
                        return (
                          <div>
                          <span className="tb-lead">{val?.user_session_date ? moment(val?.user_session_date).format("MM-DD-YYYY") : "--"} </span>
                          <span className="sub-text">{val?.user_session_time ? getTime(val?.user_session_time) :"--"}</span>
                          </div>
                          
                        ) })}
         
        </div>
      </div>
  
      ),
    },
    {
      name: "Zip Code",
      selector: (row) => "",
     minWidth: "90px",
      style: { minWidth: "90px" },
      cell: (row, index) => (
        <>
         
          <div className="user-card mt-2 mb-2">
            <div className="user-info">
             
              <span className="tb-lead">
                {row?.purchased_user.zipcode ? (row?.purchased_user.zipcode) : "--"}
              </span>
             
            </div>
          </div>
        </>
      ),
    },
    {
      name: "View",
      selector: (row) => "",
	  minWidth: "80px",
      style: { minWidth: "80px" },
      cell: (row) => (
        <div className="user-card mt-2 mb-2">
          <button
            className="dropdown-item"
            onClick={() => {
              showSignupdetails(row);
            }}
          >
            <Icon name="eye"></Icon>
          </button>
        </div>
      ),
    },
  ]
  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };
  const toggleIconTab = (icontab) => {
    if (activeIconTab !== icontab) setActiveIconTab(icontab);
  };
  const toggleAltTab = (alttab) => {
    if (activeAltTab !== alttab) setActiveAltTab(alttab);
  };
    const newRef = useRef(null);
	const newRef1 = useRef(null);
  const handleOutSideClick = (e)=> {
	 if(newRef.current && !newRef.current.contains(e.target)){
         setDatePickerShow(false);
          
	 }		 
  }
  const handleOutSideClick1 = (e)=> {
	 if(newRef1.current && !newRef1.current.contains(e.target)){
            setDatePickerShow1(false);		 
	 }		 
  }
  useEffect(()=>{
	 document.addEventListener("mousedown", handleOutSideClick );
	 document.addEventListener("mousedown", handleOutSideClick1 );
      return ()=> {
       document.removeEventListener("mousedown", handleOutSideClick );
	    document.removeEventListener("mousedown", handleOutSideClick1 );
	  }	   
  });
  return (

    <React.Fragment>


      <Head title="Resource Signup Report"></Head>
      <Content>

        <h3 className="nk-block-title page-title mb5 pb-4">Resource Signup Report</h3>


        {/* {loader ? (
          <div className="overflowloader" id="preload">
            <div className="circle-square" style={{ marginTop: "250px" }} >
              <div className="red" style={{ background: "#AAC0CE" }}></div>
              <div className="blue" style={{ background: "#265472" }}></div>
              <div className="green" style={{ background: "#F19357" }}></div>
              <div className="yellow" style={{ background: "#F0B285" }}></div>
            </div>
          </div>
        ) : ( */}
        <>
          <Card className="card-bordered">
            
            <div className="card-inners relativeClass">
              <div className="card-inners relativeClass">
                <BlockHeadContent>
                  <BlockHead size="lg">
                  <div className="p-4">
                    <div className="filtersidebutton">
                      <h3 className="graphheading  mb-0 mt-1">Graph View</h3>
					  <button className="btn btn-md btn-primary" type="submit"   onClick={clearFilterGraph}>Clear Filters</button>
                    </div>
                    
                    <div className="row">
                      <div className="col-lg-3">
                        <div className="form-control border-0 px-0">
                          <label>
                            <strong>Select Year</strong>
                          </label>

                          <div className="form-control-wrap">
                            <select className="form-select" value={selectyear} onChange={handleyear} >
                              {
                                years?.map((year, index) => {
                                  return <option key={`year${index}`} value={year}>{year}</option>
                                })
                              }
                            </select>

                          </div>
                        </div>
                      </div>
                      

                      <div className="col-lg-3">
                        <div className="form-control border-0 px-0" style={{ marginBottom: "10px" }}>
                          <label><strong>Individual/Group</strong></label>
                          <select className="form-select" name="resourceType" onChange={handleResourceTypeGraph} value={resourceTypeGraph} >
                            <option value=""> Select...  </option>
                            <option value="group"> Group </option>
                            <option value="individual"> Individual </option>
                           
                          </select>
                        </div>
                      </div> 



{/* {resourceTypeGraph=="individual" ?  */}
                      {/* <div className="col-lg-3">
                        <div className="form-control border-0 px-0" style={{ marginBottom: "10px" }}>
                          <label><strong>User Name</strong></label>
                          <select className="form-select" name="resourceType" onChange={handleResourceUserListGraph} value={resourceUserNameGraph} >
                            <option value=""> Select User </option>
                            {
                              userNameData?.map((val, index) => {
                                return (<>
                                  <option value={val?.tbl_user_resource?.tbl_user?.id} key={index + 1}>{val?.tbl_user_resource?.tbl_user?.firstName +" "+ val?.tbl_user_resource?.tbl_user?.lastName}</option>

                                </>)
                              })

                            }
                          </select>
                        </div>
                      </div>  */}

{/* :""} */}

{resourceTypeGraph =="group" ? 
<div className="col-lg-3">
                        <div className="form-control border-0 px-0" style={{ marginBottom: "10px" }}>
                          <label><strong>Group Name</strong></label>
                          <select className="form-select" name="resourceType" onChange={handleResourceGroupListGraph} value={groupNameGraph} >
                            <option value=""> All Group </option>
                            {
                              groupData?.map((val, index) => {
                                return (<>
                                  <option value={val?.tbl_user_resource?.tbl_group?.id} key={index + 1}>{val?.tbl_user_resource?.tbl_group?.group_name}</option>

                                </>)
                              })

                            }
                          </select>
                        </div>
                      </div> 
:""}
                      {/* <div className="col-lg-3">
                        <div className="form-control border-0 px-0" style={{ marginBottom: "10px" }}>
                          <label><strong>Child Name</strong></label>
                          <select className="form-select" name="resourceType" onChange={handleResourceChildListGraph} value={childNameGraph} >
                            <option value=""> Select Child </option>
                            {
                              childData?.map((val, index) => {
                                return (<>
                                  <option value={val?.tbl_child?.id} key={index + 1}>{val?.tbl_child?.firstName +" "+ val?.tbl_child?.lastName}</option>

                                </>)
                              })

                            }
                          </select>
                        </div>
                      </div>  */}
{resourceTypeGraph =="individual" ?
                      <div className="col-lg-3">
                        <div className="form-control border-0 px-0" style={{ marginBottom: "10px" }}>
                          <label><strong>Owner Name</strong></label>
                          <select className="form-select" name="resourceType" onChange={handleResourceMentorListGraph} value={mentorNameGraph} >
                            <option value=""> All Owner </option>
                            {
                              mentorData?.map((val, index) => {
                                return (<>
                                  <option value={val?.sold_by_user?.id} key={index + 1}>{val?.sold_by_user.firstName.trim() +" "+ val?.sold_by_user?.lastName.trim()}</option>

                                </>)
                              })

                            }
                          </select>
                        </div>
                      </div> 
:""}

{groupNameGraph && resourceTypeGraph || mentorNameGraph && resourceTypeGraph? 
                      <div className="col-lg-3">
                        <div className="form-control border-0 px-0" style={{ marginBottom: "10px" }}>
                          <label><strong>Resource Title</strong></label>
                          <select className="form-select" name="resourceType" onChange={handleResourceTypeListGraph} value={resourceValueGraph} >
                            <option value=""> Select... </option>
                            {
                              resourceTypedata?.map((val, index) => {
                                return (<>
                                  <option value={val?.tbl_user_resource?.id} key={index + 1}>{val?.tbl_user_resource?.resource_title}</option>

                                </>)
                              })

                            }
                          </select>
                        </div>
                      </div> 
:""}
                      <div className="col-lg-3">
                        <div className="form-control border-0 px-0">
						<label className="w-100 mb-0">&nbsp;</label>
                          <button className="btn btn-primary" type="submit" onClick={handlechart}>
                            {" "}
                            View{" "}
                          </button>

                        </div>
                      </div>

                     </div>
                    </div>


                    <div className="p-5">
                      <div className="row">
                        <div className="col-lg-6">

                        </div>

                      </div>
                      <div className="relativeClass">
                        {loader ? (
                          <div className="overflowloader" id="preload">
                            <div className="circle-square" >
                              <div className="red" style={{ background: "#AAC0CE" }}></div>
                              <div className="blue" style={{ background: "#265472" }}></div>
                              <div className="green" style={{ background: "#F19357" }}></div>
                              <div className="yellow" style={{ background: "#F0B285" }}></div>
                            </div>
                          </div>
                        ) : (
                          <HighchartsReact
                            highcharts={Highcharts}
                            options={{
                              chart: {
                                type: 'line'
                              },
                              title: {
                                text: 'Resource Signup Chart',
                                style: { "color": "#003C71", "fontSize": "25px" }
                              },

                              xAxis: {
                                categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
                                labels: {
                                  style: {
                                    color: '#364a63'
                                  }
                                }
                              },
                              plotOptions: {
                                line: {
                                  dataLabels: {
                                    enabled: true,
                                    formatter: function () {
                                      return (this.y != 0) ? this.y : "";
                                    }
                                  },
                                  enableMouseTracking: true
                                }
                              },
                              credits: {
                                enabled: false
                              },

                              yAxis: {
                                title: {
                                  text: 'No. of Signup Request',
                                  style: {
                                    color: '#364a63'
                                  }
                                },
                                labels: {
                                  style: {
                                    color: '#364a63'
                                  }
                                }


                              },
                              series: [{
                                name: "Month",
                                data: showchart,
                                color: "rgb(38, 84, 114)"


                              }]
                            }}
                          />
                        )}
                      </div>
                    </div>
                    <div className="p-4">
                    <div className="d-flex justify-content-between mb-2">
                      <div className="filtersidebutton">
                          <h3 className="graphheading mb-0 mt-1">List View</h3>
						  <button className="btn btn-md btn-primary" type="submit" onClick={clearFilterList}>Clear Filters</button>
                        </div>
                      <div className="row">
                        <div className="col-lg-12 d-flex  justify-content-end">

                          {/* <select value={sortvalue} className="form-select" style={{ width: "190px" }} onChange={HandleSort} >
                            <option value="">Sort By</option>
                            <option value="ASCDATE">Date Ascending</option>
                            <option value="DESCDATE">Date Descending</option>
                            <option value="ASCCOUNT">Space used  Ascending</option>
                            <option value="DESCCOUNT">Space used  Descending</option>
                          </select> */}

                          <div><label className="mt-1 ">&nbsp;&nbsp;Show&nbsp;</label></div>
                          <div><select value={limitvalue} className="form-select" style={{ width: "85px" }} onChange={HandleLimitValue}>
                            <option value="10">10</option>
                            <option value="20">20</option>
                            <option value="30">30</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                          </select>
                          </div>
                          <div>
                            &nbsp; &nbsp;
                            <button className="btn btn-primary " onClick={ExportEcelFile}>
                              <em className="icon ni ni-download-cloud"></em><span>Export</span>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row pb-2">

                      {/* <div className="col-lg-3">
                        <div className="form-control border-0 px-0">
                          <label>
                            <strong>User Name</strong>
                          </label>
                          <div className="form-control-wrap">
<i className="fa fa-chevron-down"></i>
                            <Select
                              defaultvalue={userName}
                              options={userNameData}
                              onChange={HandleUserName}
                            />
                          </div>
                        </div>
                      
                      </div> */}

                      <div className="col-lg-3">
                        <div className="form-control border-0 px-0">
                          <label>
                            <strong>Start Date</strong>
                          </label>
                          {/* <input type="date" name="formdate" className="form-control" onChange={HandleFromDate} /> */}

                          <div className="form-control-wrap" ref={newRef}>
                            <div className="form-icon form-icon-left">
                              <span onClick={() => handleclickdatepickericon()}>
                                <Icon name="calendar"></Icon>
                              </span>
                            </div>
                            <DatePicker
                              placeholderText="MM/DD/YYYY"
                              selected={selectedfromdate}
                              onChange={HandleFromDate}
                              onFocus={() => setDatePickerShow(!datepickershow)}
                              className="form-control date-picker"
                              open={datepickershow}
                            />
                          </div>
                        </div>
                        <span className="error">{formDateError}</span>
                      </div>
                      <div className="col-lg-3">
                        <div className="form-control border-0 px-0">
                          <label>
                            <strong>End Date</strong>
                          </label>
                          {/* <input type="date" name="formdate" className="form-control" onChange={HandleFromDate} /> */}

                          <div className="form-control-wrap" ref={newRef1}>
                            <div className="form-icon form-icon-left">
                              <span onClick={() => handleclickdatepickericon1()}>
                                <Icon name="calendar"></Icon>
                              </span>
                            </div>
                            <DatePicker
                              placeholderText="MM/DD/YYYY"
                              selected={selectedtodate}
                              onChange={HandleToDate}
                              onFocus={() => setDatePickerShow1(!datepickershow1)}
                              className="form-control date-picker"
                              open={datepickershow1}
                            />
                          </div>
                        </div>
                        <span className="error">{toDateError}</span>
                      </div>

                      <div className="col-md-3">
                        <label className="lavelinfo">
                          <strong>Zip Code</strong>
                        </label>
                        <div className="form-control-wrap">

                          <input placeholder="Zipcode" type="text" className="form-control pl-2" value={zipCode} onChange={handleZipCode} />
                        </div>
                      </div>
                      

                      <div className="col-lg-3">
                        <div className="form-control border-0 px-0" style={{ marginBottom: "10px" }}>
                          <label><strong>Individual/Group</strong></label>
                          <select className="form-select" name="resourceType" onChange={handleResourceGroupTypeList} value={groupType} >
                            <option value=""> Select...</option>
                            
                                  <option value="group">Group</option>
                                  <option value="individual">Individual</option>

                                
                        
                          </select>
                        </div>
                      </div> 
 {/* {groupType=="individual" ? 
                      <div className="col-lg-3">
                        <div className="form-control border-0 px-0" style={{ marginBottom: "10px" }}>
                          <label><strong>User Name</strong></label>
                          <select className="form-select" name="resourceType" onChange={handleResourceUserList} value={resourceUserName} >
                            <option value=""> Select User </option>
                            {
                              userNameData?.map((val, index) => {
                                return (<>
                                  <option value={val?.tbl_user_resource?.tbl_user?.id} key={index + 1}>{val?.tbl_user_resource?.tbl_user?.firstName +" "+ val?.tbl_user_resource?.tbl_user?.lastName}</option>

                                </>)
                              })

                            }
                          </select>
                        </div>
                      </div> 
                      :""} */}

                      {groupType=="group" ? 
                      <div className="col-lg-3">
                        <div className="form-control border-0 px-0" style={{ marginBottom: "10px" }}>
                          <label><strong>Group Name</strong></label>
                          <select className="form-select" name="resourceType" onChange={handleResourceGroupList} value={groupName} >
                            <option value=""> All Group </option>
                            {
                              groupData?.map((val, index) => {
                                return (<>
                                  <option value={val?.tbl_user_resource?.tbl_group?.id} key={index + 1}>{val?.tbl_user_resource?.tbl_group?.group_name}</option>

                                </>)
                              })

                            }
                          </select>
                        </div>
                      </div> 
:""}
                     

                     {/* <div className="col-lg-3">
                        <div className="form-control border-0 px-0" style={{ marginBottom: "10px" }}>
                          <label><strong>Child Name</strong></label>
                          <select className="form-select" name="resourceType" onChange={handleResourceChildList} value={childName} >
                            <option value=""> Select Child </option>
                            {
                              childData?.map((val, index) => {
                                return (<>
                                  <option value={val?.tbl_child?.id} key={index + 1}>{val?.tbl_child?.firstName +" "+ val?.tbl_child?.lastName}</option>

                                </>)
                              })

                            }
                          </select>
                        </div>
                      </div>  */}

{groupType  =="individual" ? 
                      <div className="col-lg-3">
                        <div className="form-control border-0 px-0" style={{ marginBottom: "10px" }}>
                          <label><strong>Owner Name</strong></label>
                          <select className="form-select" name="resourceType" onChange={handleResourceMentorList} value={mentorName} >
                            <option value=""> All Owner </option>
                            {
                              mentorData?.map((val, index) => {
                                return (<>
                                  <option value={val?.sold_by_user?.id} key={index + 1}>{val?.sold_by_user?.firstName +" "+ val?.sold_by_user?.lastName}</option>

                                </>)
                              })

                            }
                          </select>
                        </div>
                      </div> 
:""}
                      {/*  <div className="col-lg-3">
                        <div className="form-control border-0 px-0" style={{ marginBottom: "10px" }}>
                          <label><strong>Customer Name</strong></label>
                          <select className="form-select" name="resourceType" onChange={handleResourceCustomerList} value={customerName} >
                            <option value=""> Select Customer </option>
                            {
                              customerData?.map((val, index) => {
                                return (<>
                                  <option value={val?.user?.id} key={index + 1}>{val?.user?.firstName +" "+ val?.user?.lastName}</option>

                                </>)
                              })

                            }
                          </select>
                        </div>
                      </div>  */}

                {mentorName && groupType || groupName && groupType ?      
                      <div className="col-lg-3">
                        <div className="form-control border-0 px-0" style={{ marginBottom: "10px" }}>
                          <label><strong>Resource Title</strong></label>
                          <select className="form-select" name="resourceType" onChange={handleResourceTypeList} value={resourceValue} >
                            <option value="">Select... </option>
                            {
                              resourceTypedata?.map((val, index) => {
                                return (<>
                                  <option value={val?.tbl_user_resource?.id} key={index + 1}>{val?.tbl_user_resource?.resource_title}</option>

                                </>)
                              })

                            }
                          </select>
                        </div>
                      </div> 
                  
                  :""}  
                    </div>
                    <div className="d-flex flex-row" style={{ gap: "10px" }}>
                      {/* <div className="col-lg-2 d-flex "> */}
                      <div className="outerTypeBox mb-3">
                        <button className="btn btn-primary" type="submit" onClick={handlesearch}>
                          {" "}
                          Search{" "}
                        </button>

                        {/* <button className="btn btn-md btn-primary mt-2" type="submit" onClick={clearfilter}>Clearfilter</button> */}
                        {/* </div> */}
                      </div>
                      {/* <div className="col-lg-2 d-flex "> */}
                      {/* <div className="outerTypeBox mb-3">
                          <button className="btn btn-primary" onClick={ExportEcelFile}>
                            <em className="icon ni ni-download-cloud"></em><span>Export</span>
                          </button>
                        </div> */}
                      {/* </div> */}
                    </div>
                    </div>
                    <div className="relativeClass newregisterGrouptable">
                      {loader1 ? (
                        <div className="overflowloader" id="preload">
                          <div className="circle-square">
                            <div className="red" style={{ background: "#AAC0CE" }}></div>
                            <div className="blue" style={{ background: "#265472" }}></div>
                            <div className="green" style={{ background: "#F19357" }}></div>
                            <div className="yellow" style={{ background: "#F0B285" }}></div>
                          </div>
                        </div>
                      ) : ("")}

<div className="totalRight"><span style={{paddingRight:"20px"}}><strong>Total Count:{totalCount}{"    "}</strong></span></div>
                      {resourceSignupData?.length == 0 ? <><p className="p-4" style={{ minHeight: "100px" }}>No Data Found</p></> : (
                        <div className="color">
                          <ReactDataTable
                            data={resourceSignupData}
                            columns={columns}
                            className="nk-tb-list"

                          />
                        </div>
                      )}


                    </div>
                    <div className="p-4">
                    {resourceSignupData?.length > 0 ?
                      <div>
                        <ReactPaginate
                          previousLabel={"<<"}
                          nextLabel={">>"}
                          pageCount={count}
                          pageRangeDisplayed={5}
                          marginPagesDisplayed={2}
                          onPageChange={handlePageChange}
                          containerClassName={'pagination'}
                          pageClassName={"page-item"}
                          activeClassName={'active'}
                          pageLinkClassName={"page-link"}
                          previousClassName={"page-item"}
                          previousLinkClassName={"page-link"}
                          nextClassName={"page-item"}
                          nextLinkClassName={"page-link"}
                          breakClassName={"page-item"}
                          breakLinkClassName={"page-link"}
                          forcePage={currentPage-1}
                        />
                      </div>
                      : ""}
                      </div>
                  </BlockHead>
                </BlockHeadContent>
              </div>

            </div>
          </Card>


          <Modal isOpen={signupDetailModal} toggle={SignupModalFunction}>
                      <ModalHeader
                        toggle={SignupModalFunction}
                        close={
                          <button className="close" onClick={SignupModalFunction}>
                            <Icon name="cross" />
                          </button>
                        }
                      >
                       Signup Details
                      </ModalHeader>
                      <ModalBody>
                        <Card className="">
                          <div className="card-inner">
                            <BlockHeadContent>
                              <BlockHead size="lg">
                                <div className="customerDetailsTable">
                                  <div className="row">

                                    <div className="card-title-group">
                                      
                                    </div>
                                   
                                    <table>
                                    <tr><td width="200">Session Date: </td>
									<td width="200">Session Start Time: </td>
									<td width="200">Session End Time: </td></tr>
                                   
                                    {signUpDetails?.tbl_user_mentoring_session_datetimes && signUpDetails?.tbl_user_mentoring_session_datetimes.map((val, i) => {
                              return (
                                <>
                                 
                                <tr> <td width="200">{val?.session_date ? moment(val?.session_date).format("MM-DD-YYYY") : "--"}{" "}</td> 
                                 <td width="200">{val?.session_time ? getTime(val?.session_time ) :"--"}{" "} </td>
                                 <td width="200">{val?.session_end_time ? getTime(val?.session_end_time ) :"--"}{" "} </td></tr> 
                                 
                                  
                                  
                                  
   
                                                   
                                </>
                                
                              ) })}
                                         
                                         
                                            {/* <tr>
                                            
                                                   
                                                    <td>Price per duration: </td><td>{mentoringDetails?.price_per_duration ?  new Intl.NumberFormat("en-US",{
     style:"currency",
    currency:"USD",
  }).format(mentoringDetails.price_per_duration) : "$0.00"}{" "}
                                                     
                                                    </td>
                                                  
                                            </tr> */}
                                            
                                            {/* <tr>
                                          
                                                    <td>Total Amount: </td><td>{mentoringDetails.total_amount ? new Intl.NumberFormat("en-US",{
     style:"currency",
    currency:"USD",
  }).format(mentoringDetails.total_amount) : "$0.00"}{" "}
                                                    </td>
                                                
                                            </tr> */}
                                           

                                   
                                    </table>
                                  </div>
                                </div>
                              </BlockHead>
                            </BlockHeadContent>
                          </div>
                        </Card>
                      </ModalBody>
                      <ModalFooter className="">
                        <Button color="primary" onClick={SignupModalFunction}>
                          OK
                        </Button>
                      </ModalFooter>
                    </Modal>



        </>
        {/* )} */}
      </Content>
    </React.Fragment>
  );
}
export default ResourceSignupReport;
