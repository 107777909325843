import React, { useState } from "react";
import Head from "../../../layout/head/Head";
import Content from "../../../layout/content/Content";
import { useEffect, useRef } from "react";
import { URLS } from "../../../constants/urls";
import { BlockHead, BlockHeadContent } from "../../../components/Component";
import {
  Icon,
  ReactDataTable,
} from "../../../components/Component";
import { Card } from "reactstrap";
import { useHistory } from "react-router-dom";
const ExcelJS = require("exceljs");
import { makePostRequest, makeGetRequest } from "../../../service/api";
import ReactPaginate from "react-paginate";
import Select from "react-select";
import moment from "moment";
import DatePicker from "react-datepicker";
import Highcharts, { chart } from "highcharts";
import HighchartsReact from "highcharts-react-official";
// import exporting from "highcharts/modules/exporting";
import { ToastContainer, toast } from "react-toastify";
import { Modal, ModalFooter, ModalHeader } from "reactstrap";
// exporting(Highcharts);

function MentoringSessionPaymentReport() {

  const [customerstoredreport, setCustomerStoredreport] = useState([]);
  const [loader, setLoader] = useState(false);
  const [loader1, setLoader1] = useState(false);
  const [selectyear, setSelectyear] = useState(new Date().getFullYear());
  const [childId, setChildId] = useState("");
  const [userName, setUserName] = useState("");
  const [childName1, setChildName1] = useState("");
  const [childId1, setChildId1] = useState("");
  const [childNameData1, setChildNameData1] = useState([]);
  const [userNameData, setUserNameData] = useState([]);
  const [childNameData, setChildNameData] = useState([]);
  const [userId, setUserId] = useState("");
  const [selectedfromdate, setSelectedFromDate] = useState(null);
  const [selectedtodate, setSelectedToDate] = useState(null);
  const [savedtype, setSavedType] = useState("");
  const [resourcetype, setResourceType] = useState("");
  const [resourcemode, setResourceMode] = useState("");
  const [getAllResourceNew, setgetAllResourceNew] = useState([]);
  const [limitvalue, setlimitvalue] = useState("");
  const [sortvalue, setSortValue] = useState("");
  const [showchart, setshowchart] = useState([]);
  const [showchart1, setshowchart1] = useState([]);
  const [datepickershow, setDatePickerShow] = useState(false);
  const [datepickershow1, setDatePickerShow1] = useState(false);
  const [toDateError, settoDateError] = useState("");
  const [formDateError, setformDateError] = useState("");
  const [customerbookdata, setCustomerBookData] = useState([]);

  const [isOpen, setIsOpen] = useState(false);
  const [mentorsolddata, setmentorsolddata] = useState([]);
  const [mentorid, setmentorid] = useState("");
  const [resourceDropdown, setResourceDropdown] = useState([]);
  const [dropdownPage, setDropdownPage] = useState(1);
  const [selectedResourse, setselectedResourse] = useState();
  const [mentoringPaymentreportchart, setMentoringPaymentreportchart] = useState([]);
  const [displayvalueSelectUserGraph, setDisplayValueSelectUserGraph] = useState(true);
  const [displayvalueSelectChildGraph, setDisplayValueSelectChildGraph] = useState(true);
  const [displayvalueSelectResourceGraph, setDisplayValueSelectResourceGraph] = useState(true);
  const [displayvalueSelectUserList, setDisplayValueSelectUserList] = useState(true);
  const [displayvalueSelectChildList, setDisplayValueSelectChildList] = useState(true);
  const [displayvalueSelectResourceList, setDisplayValueSelectResourceList] = useState(true);
  const year = 2023;
  const curryear = new Date().getFullYear();
  let years = Array.from(new Array(curryear - 2023 + 2), (val, index) => index + year);
  let chartdata = [];
  let chartdata1 = [];
  const current = new Date();
  const date = moment(current).format("MM-DD-YYYY");

console.log(customerbookdata,"customerbookdata")
  const handleyear = (e) => {
    setSelectyear(e.target.value);
  };
  const HandleFromDate = (e) => {
    setSelectedFromDate(e);
    setDatePickerShow(!datepickershow);
    setformDateError("");
  };

  const HandleToDate = (e) => {
    setSelectedToDate(e);
    setDatePickerShow1(!datepickershow1);
    settoDateError("");
  };
  const HandleUserName = (e) => {
    setUserId(e.value);
    setUserName(e.label);
    setChildId("");
    selectChildRefGraph.current.select.setValue("", "select-option");
  };
  function handleclickdatepickericon() {
    setDatePickerShow(!datepickershow);
  }
  function handleclickdatepickericon1() {
    setDatePickerShow1(!datepickershow1);
  }

  const HandleChildName = (e) => {
    setChildId(e.target.value);
  };
  const HandleChildName1 = (e) => {
    setChildId1(e.value);
    setChildName1(e.label);
  };

  const handlesearch = () => {
    if (selectedfromdate != null && selectedtodate == null) {
      settoDateError("Please select  end date");
    } else if (selectedtodate != null && selectedfromdate == null) {
      setformDateError("Please select  start date ");
    } else if (selectedfromdate > selectedtodate) {
      toast.error("End date should be greater than start date");
    } else {
      getcustomerstoredreport();
      setCurrentPage(1);
    }
  };

  const selectUserRefGraph = useRef(null);
  const selectChildRefGraph = useRef(null);
  const selectResourceRefGraph = useRef(null);
  const selectUserRefList = useRef(null);
  const selectChildRefList = useRef(null);
  const selectResourceRefList = useRef(null);

  const displaySelectUserGraph = () => {
    if (selectUserRefGraph.current) {
      setDisplayValueSelectUserGraph(!displayvalueSelectUserGraph);
      selectUserRefGraph.current.focus();
    }
  };
  const displaySelectChildGraph = () => {
    if (selectChildRefGraph.current) {
      setDisplayValueSelectChildGraph(!displayvalueSelectChildGraph);
      selectChildRefGraph.current.focus();
    }
  };
  const displaySelectResourceGraph = () => {
    if (selectResourceRefGraph.current) {
      setDisplayValueSelectResourceGraph(!displayvalueSelectResourceGraph);
      selectResourceRefGraph.current.focus();
    }
  };

  const displaySelectUserList = () => {
    if (selectUserRefList.current) {
      setDisplayValueSelectUserList(!displayvalueSelectUserList);
      selectUserRefList.current.focus();
    }
  };
  const displaySelectChildList = () => {
    if (selectChildRefList.current) {
      setDisplayValueSelectChildList(!displayvalueSelectChildList);
      selectChildRefList.current.focus();
    }
  };
  const displaySelectResourceList = () => {
    if (selectResourceRefList.current) {
      setDisplayValueSelectResourceList(!displayvalueSelectResourceList);
      selectResourceRefList.current.focus();
    }
  };

  const handlechart = () => {
    mentoringsessionpaymentreportchart();
    setChildId("");
  };
  const HandleLimitValue = (e) => {
    setlimitvalue(e.target.value);
    setCurrentPage(1);
  };
  const HandleSort = (e) => {
    setSortValue(e.target.value);
    setCurrentPage(1);
  };
  const handlementorname = (e) => {
    setmentorid(e.value);
  };

  const HandleSaveType = (e) => {
    setSavedType(e.target.value);
  };
  const HandleResourceType = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    setResourceType(value);
  };
  const handleResourceMode = (e) => {
    setResourceMode(e.target.value);
  };

  const openModal = () => {
    setIsOpen(!isOpen);
  };
  const getmentordata = () => {
    setLoader(true);
    makePostRequest(URLS.MENTOR_PERANT, null, { page_number: 1 }, null)
      .then((res) => {
        if (res.code == 200) {
          setmentorsolddata(res.data?.data);
          setLoader(false);
          setmentorid("");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const viewBookedDetails = (row) => {
    let data = {
      session_date: row.booked_date,
      user_id: row.purchased_user.id,
      child_id: childId ? childId : "",
    };
    makePostRequest(URLS.GET_MENTORING_DETAILS, null, data, null).then((res) => {
      if (res.code == 200) {
        setCustomerBookData(res.data);
        setIsOpen(!isOpen);
      } else {
        toast.error(res.message);
      }
    });
  };
  const ExportEcelFile = () => {
    setLoader(true);

    let data = {
      start_date: selectedfromdate ? moment(selectedfromdate).format("YYYY-MM-DD") : "",
      end_date: selectedtodate ? moment(selectedtodate).format("YYYY-MM-DD") : "",
      user_id: userId ? userId : "",
      child_id: childId ? childId : "",
      mentor_id: mentorid ? mentorid : "",
      resource_id: selectedResourse?.value ? selectedResourse?.value : "",
      page_number: currentPage ? currentPage : 1,
      item_per_page: limitvalue ? parseInt(limitvalue) : 10,
      sort_by: sortvalue ? sortvalue : "",
      export_data: "yes",
    };
    makePostRequest(URLS.GET_MENTORING_SALE_LIST, null, data, null)
      .then((res) => {
        if (res.code == 200) {
          const workbook = new ExcelJS.Workbook();
          const sheet = workbook.addWorksheet("mentoringsessionpaymentreport");
          sheet.properties.defaultRowHeight = 30;

          sheet.getRow(1).font = {
            size: 10,
            bold: true,
          };

          sheet.columns = [
            {
              header: "S.No",
              key: "S_No",
              width: 5,
            },
            {
              header: "Booking Date",
              key: "REGISTER_DATE",
              width: 15,
            },
            {
              header: "Customer Name",
              key: "USER_NAME",
              width: 30,
            },

            {
              header: "Email",
              key: "OWNEREMAIL",
              width: 30,
            },
            {
              header: "Phone",
              key: "OWNWERPHONE",
              width: 30,
            },
            {
              header: "Total Booked Sessions",
              key: "bookedsession",
              width: 20,
            },
            {
              header: "Booking Amount($)",
              key: "bookedamount",
              width: 20,
            },
          ];
          res?.data?.list_data?.map((val, i) => {
            sheet.addRow({
              S_No: i + 1,
              REGISTER_DATE: val.booked_date ? moment(val.booked_date).format("MM-DD-YYYY") : "--",
              USER_NAME: val.purchased_user?.firstName + " " + val.purchased_user?.lastName,
              OWNEREMAIL: val.purchased_user?.email,
              OWNWERPHONE: val.purchased_user?.phone,
              bookedsession: val.total_booked_count ? val.total_booked_count : "--",
              bookedamount: val.total_booked_amount ? val.total_booked_amount : "--",
            });
          });

          workbook.xlsx.writeBuffer().then((data) => {
            const blob = new Blob([data], {
              type: "application/vnd.openxmlformats-officedocument.spreadsheet.sheet",
            });
            const url = window.URL.createObjectURL(blob);
            const anchor = document.createElement("a");
            anchor.href = url;
            anchor.download = "mentoringsessionpaymentreport.xlsx";
            anchor.click();
            window.URL.revokeObjectURL(url);
          });
          setLoader(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleMenuScroll = (event) => {
    const { target } = event;
    if (target.scrollTop === target.scrollHeight - target.clientHeight) {
      getResourceDropdown();
    }
  };

  const getAllUserType = () => {
    setLoader(true);
    makeGetRequest(URLS.GET_ALL_RESOURCE_TYPE, {}, null)
      .then((res) => {
        if (res.code == 200) {
          setgetAllResourceNew(res.data);
          setLoader(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getUserName = () => {
    makePostRequest(URLS.MENTOR_CUSTOMER, null, { page_number: 1 }, null)
      .then((res) => {
        if (res.code == 200) {
          let option = [];
          option.push({ value: "", label: "All" });
          res.data.data.length > 0 &&
            res.data.data.map((val) => {
              option.push({
                value: val.user_id,
                label: val.purchased_user.firstName + " " + val.purchased_user.lastName,
              });
            });
          setUserNameData(option);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getChildName = () => {
    let data = {
      user_id: userId ? userId : "",
    };
    makePostRequest(URLS.GETALL_CHILD, null, data, null)
      .then((res) => {
        if (res.code == 200) {
          setChildNameData(res.data);
          let option = [];
          option.push({ value: "", label: "Select Student" });
          res.data.length > 0 &&
            res.data.map((val) => {
              option.push({ value: val.id, label: val.firstName + " " + val.lastName });
            });
          setChildNameData1(option);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const mentoringsessionpaymentreportchart = () => {
    setLoader(true);
    let data = {
      year: selectyear,
      user_id: userId ? userId : "",
      child_id: childId1 ? childId1 : "",
      mentor_id: mentorid ? mentorid : "",
      resource_id: selectedResourse?.value ? selectedResourse?.value : "",
    };
    makePostRequest(URLS.GET_MENTORING_SALE_GRAPH, null, data, null)
      .then((res) => {
        if (res.code === 200) {
          setMentoringPaymentreportchart(res?.data?.graph_data);
          setLoader(false);
        } else {
          setLoader(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const [clearStateGraph, setClearStateGraph] = useState(false);
  const [clearStateList, setClearStateList] = useState(false);
  /*=================================== customer save report list api==========================*/

  const [currentPage, setCurrentPage] = useState(1);
  const [count, setCount] = useState();

  const getcustomerstoredreport = () => {
    setLoader1(true);
    let data = {
      start_date: selectedfromdate ? moment(selectedfromdate).format("YYYY-MM-DD") : "",
      end_date: selectedtodate ? moment(selectedtodate).format("YYYY-MM-DD") : "",
      user_id: userId ? userId : "",
      child_id: childId ? childId : "",
      mentor_id: mentorid ? mentorid : "",
      resource_id: selectedResourse?.value ? selectedResourse?.value : "",
      page_number: currentPage ? currentPage : 1,
      item_per_page: limitvalue ? parseInt(limitvalue) : 10,
      sort_by: sortvalue ? sortvalue : "",
    };
    makePostRequest(URLS.GET_MENTORING_SALE_LIST, null, data, null)
      .then((res) => {
        if (res.code === 200) {
          setCustomerStoredreport(res?.data?.list_data);
          setCount(Math.ceil(res.data.total_count / parseInt(limitvalue ? limitvalue : 10)));
          setLoader1(false);
        } else {
          setLoader1(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const getResourceDropdown = () => {
    makePostRequest(URLS.GET_RESOURCE_MENTOR, null, { page_number: dropdownPage }, null)
      .then((res) => {
        if (res.code == 200) {
          setResourceDropdown((prevOptions) => [...prevOptions, ...res?.data?.data]);
          setDropdownPage((prevPage) => prevPage + 1);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handlePageChange = (selectedPage) => {
    setCurrentPage(selectedPage.selected + 1);
  };
  
  const clearFilterGraph = () => {
    clearFilterGraphFun();
  };

  const clearFilterGraphFun = () => {
    setSelectyear(new Date().getFullYear());
    setUserId("");
    selectUserRefGraph.current.select.setValue("", "select-option");
    setChildName1("");
    selectChildRefGraph.current.select.setValue("", "select-option");
    setmentorid("");
    selectRefGraphvendor.current.select.setValue("", "select-option");
    selectResourceRefGraph.current.select.setValue("", "select-option");
    setClearStateGraph(!clearStateGraph);
  };

  const clearFilterList = () => {
    clearFilterListFun();
  };

  const clearFilterListFun = () => {
    setSortValue("");
    setlimitvalue("10");
    setUserId("");
    setCurrentPage(1)
    selectUserRefList.current.select.setValue("", "select-option");
    setChildId("");
    setmentorid("");
    selectRefListvendor.current.select.setValue("", "select-option");
    setselectedResourse("");
    selectResourceRefList.current.select.setValue("", "select-option");
    setSelectedFromDate(null);
    setSelectedToDate(null);
    setClearStateList(!clearStateList);
  };

  useEffect(() => {
    getcustomerstoredreport();
  }, [currentPage, limitvalue, sortvalue]);

  useEffect(() => {
    mentoringsessionpaymentreportchart();
  }, [clearStateGraph]);

  useEffect(() => {
    getcustomerstoredreport();
  }, [clearStateList]);

  useEffect(() => {
    getUserName();
    getAllUserType();
    getcustomerstoredreport();
    mentoringsessionpaymentreportchart();
    getmentordata();
    getResourceDropdown();
  }, []);
  useEffect(() => {
    getchartdata();
  }, [mentoringPaymentreportchart]);

  const getchartdata = () => {
    mentoringPaymentreportchart?.map((val) => {
      chartdata.push(val.total_booked_count);
      chartdata1.push(parseFloat(val.total_booked_amount));
    });
    setshowchart(chartdata);
    setshowchart1(chartdata1);
  };

  useEffect(() => {
    getChildName();
  }, [userId]);

  useEffect(() => {
    getcustomerstoredreport();
  }, [limitvalue, sortvalue]);

  const columns = [
    {
      name: "#",
      selector: (row) => "",
      grow: 1,
      minWidth: "50px",
      style: { minWidth: "50px" },
      borderRadius: "4px",
      cell: (row, index) => (
        <>
          <div className="user-card mt-2 mb-2">
            {" "}
            {currentPage == 1
              ? index + 1
              : (currentPage - 1) * (limitvalue != "" ? parseInt(limitvalue) : 10) + (index + 1)}{" "}
          </div>
        </>
      ),
    },
    {
      name: "Customer Details",
      selector: (row) => row.purchased_user.firstName,
      grow: 3,
      cell: (row, index) => (
        <>
          <div className="user-card mt-2 mb-2">
            <div className="user-info">
              <span className="tb-lead">
                {row.purchased_user?.firstName} {row.purchased_user?.lastName}
              </span>
              <span className="sub-text">{row.purchased_user?.email}</span>
              {/* <span className="sub-text">{row.purchased_user?.phone}</span> */}
            </div>
          </div>
          ,
        </>
      ),
    },
    {
      name: "Booking Date",
      selector: (row) => row.booked_date,
      grow: 2,
      cell: (row, index) => (
        <>
          <div className="user-card mt-2 mb-2">
            {row.booked_date ? moment(row.booked_date).format("MM-DD-YYYY") : "--"}{" "}
          </div>
        </>
      ),
    },

    {
      name: "Total Booked Sessions",
      selector: (row) => row.purchased_user.firstName,
      grow: 2,
      sortable: true,
      cell: (row) => (
        <div className="user-card mt-2 mb-2">
          <div className="user-info">
            <span className="tb-lead"> {row.total_booked_count ? row.total_booked_count : "--"} </span>
          </div>
        </div>
      ),
    },
    {
      name: "Booking Amount($)",
      grow: 2,
      sortable: true,
      cell: (row) => (
        <div className="user-card mt-2 mb-2"> {row.total_booked_amount ? row.total_booked_amount : "0"} </div>
      ),
    },
    {
      name: "Booking Details",
      grow: 2,
      sortable: true,
      cell: (row) => (
        <div className="user-card mt-2 mb-2">
          {" "}
          <span>
            <button
              className="btn btn-md"
              onClick={() => {
                viewBookedDetails(row);
              }}
            >
              <em className="icon ni ni-eye"></em>
            </button>
          </span>{" "}
        </div>
      ),
    },
  ];
  const columnsshow = [
    {
      name: "#",
      selector: (row) => "",
      grow: 0,
      minWidth: "50px",
      style: { minWidth: "50px" },
      borderRadius: "4px",
      cell: (row, index) => (
        <>
          <div className="user-card mt-2 mb-2">
            {" "}
            {currentPage == 1
              ? index + 1
              : (currentPage - 1) * (limitvalue != "" ? parseInt(limitvalue) : 10) + (index + 1)}{" "}
          </div>
        </>
      ),
    },
    {
      name: "Customer Details",
      selector: (row) => row.purchased_user.firstName,
      grow: 3,
      cell: (row, index) => (
        <>
          <div className="user-card mt-2 mb-2">
            <div className="user-info">
              <span className="tb-lead">
                {row.purchased_user?.firstName} {row.purchased_user?.lastName}
              </span>
              <span className="sub-text">{row.purchased_user?.email}</span>
              <span className="sub-text">{row.purchased_user?.phone}</span>
            </div>
          </div>
          ,
        </>
      ),
    },
    {
      name: "Booking Date",
      selector: (row) => row.booked_date,
      grow: 2,
      cell: (row, index) => (
        <>
          <div className="user-card mt-2 mb-2">
            {row.booked_date ? moment(row.booked_date).format("MM-DD-YYYY") : "--"}{" "}
          </div>
        </>
      ),
    },

    {
      name: "Mentor's Name",
      selector: (row) => row.sold_by_user,
      grow: 2,
      show: false,
      cell: (row, index) => (
        <>
          <div className="user-card mt-2 mb-2">
          <span className="tb-lead">
            {row?.sold_by_user ? row?.sold_by_user.firstName + " " + row?.sold_by_user.lastName : "--"}{" "}
            </span>
          </div>
        </>
      ),
    },
    {
      name: "Total Booked Sessions",
      selector: (row) => row.purchased_user.firstName,
      grow: 2,
      sortable: true,
      cell: (row) => (
        <div className="user-card mt-2 mb-2">
          <div className="user-info">
            <span className="tb-lead"> {row.total_booked_count ? row.total_booked_count : "--"} </span>
          </div>
        </div>
      ),
    },
    {
      name: "Booking Amount",
      grow: 2,
      sortable: true,
      cell: (row) => (
        <div className="user-card mt-2 mb-2"> {row.total_booked_amount ? row.total_booked_amount : "--"} </div>
      ),
    },
    {
      name: "Booking Details",
      grow: 2,
      sortable: true,
      cell: (row) => (
        <div className="user-card mt-2 mb-2">
          {" "}
          <span>
            <button
              className="btn btn-md"
              onClick={() => {
                viewBookedDetails(row);
              }}
            >
              <em className="icon ni ni-eye"></em>
            </button>
          </span>{" "}
        </div>
      ),
    },
  ];
  
  const [vendorname, setVendorname] = useState(true);
  const [vendornames, setVendornames] = useState(true);
  const newRef = useRef(null);
  const newRef1 = useRef(null);
  const selectRefGraphvendor = useRef(null);
  const selectRefListvendor = useRef(null);

  const handleVendors = () => {
    if (selectRefListvendor.current) {
      setVendornames(!vendornames);
      selectRefListvendor.current.focus();
    }
  };
  const handleVendor = () => {
    if (selectRefGraphvendor.current) {
      setVendorname(!vendorname);
      selectRefGraphvendor.current.focus();
    }
  };

  const handleOutSideClick = (e) => {
    if (newRef.current && !newRef.current.contains(e.target)) {
      setDatePickerShow(false);
    }
  };
  const handleOutSideClick1 = (e) => {
    if (newRef1.current && !newRef1.current.contains(e.target)) {
      setDatePickerShow1(false);
    }
  };
  useEffect(() => {
    document.body.classList.add("mentoring-session-payment-report");
    document.addEventListener("mousedown", handleOutSideClick);
    document.addEventListener("mousedown", handleOutSideClick1);
    return () => {
      document.body.classList.add("mentoring-session-payment-report");
      document.removeEventListener("mousedown", handleOutSideClick);
      document.removeEventListener("mousedown", handleOutSideClick1);
    };
  }, []);



  return (
    <React.Fragment>
      <Head title="Scheduled Sessions Payment Report"></Head>
      <Content>
        <h3 className="nk-block-title page-title mb5 pb-4"> Scheduled Sessions Payment Report</h3>
        <>
          <Card className="card-bordered">
            <Modal className="mentoring-session-payment-report" isOpen={isOpen} toggle={openModal}>
              <ModalHeader
                toggle={openModal}
                close={
                  <button className="close" onClick={openModal}>
                    <Icon name="cross" />
                  </button>
                }
              >
                Scheduled Session Booking Details
              </ModalHeader>
              <Card className="card-bordered">
                <div className="card-inner">
                  <BlockHeadContent>
                    <BlockHead size="lg">
                      <div className="customerDetailsTable">
                      <table width="100%" style={{border:"2px solid black"}}>
  <tr className="border-bottom" style={{border:"2px solid black"}}>
    <th width="60px">#</th>
    <th width="200px">Booking Time</th>
    <th width="150px">Duration</th>
    <th width="170px">Mentor's Name</th>
    <th width="150px">Resource Title</th>
    <th width="150px">Student Name</th>
    <th width="150px">Total Amount</th>
    <th width="200px">Session Date/Time</th>
    <th width="200px">Session Status</th>
    <th width="200px">Payment Status</th>
  </tr>
  {customerbookdata &&
    customerbookdata.map((val, i) => {
      const sessionCount = val?.tbl_user_mentoring_session_datetimes.length || 1;
      return (
        <>
          <tr className="border-bottom" style={{border:"2px solid black"}}>
            <td rowSpan={sessionCount}>{i + 1}</td>
            <td rowSpan={sessionCount}>{moment(val?.created_at).format("hh:mm a")}</td>
            <td rowSpan={sessionCount}>{val?.duration} Minutes</td>
            <td rowSpan={sessionCount}>{val?.sold_by_user?.firstName} {val?.sold_by_user?.lastName}</td>
            <td rowSpan={sessionCount}>{val?.tbl_user_resource.resource_title}</td>
            <td rowSpan={sessionCount}>{val?.tbl_child?.firstName ? val?.tbl_child.firstName + " " + val?.tbl_child.lastName : "--"}</td>
            <td rowSpan={sessionCount}>
              {val?.total_amount
                ? new Intl.NumberFormat("en-US", {
                    style: "currency",
                    currency: "USD",
                  }).format(val.total_amount)
                : "$0.00"}
            </td>
            <td>{moment(val?.tbl_user_mentoring_session_datetimes[0].session_date).format("MM-DD-YYYY")} {val?.tbl_user_mentoring_session_datetimes[0].session_time}</td>
            <td>
             
                {val?.tbl_user_mentoring_session_datetimes[0].is_cancelled === "yes" ? "Cancelled" : moment(val?.tbl_user_mentoring_session_datetimes[0].session_date).format("MM-DD-YYYY") >= date ? "Upcoming" : "Attended"}
                {val?.tbl_user_mentoring_session_datetimes[0].is_cancelled === "yes" && val?.tbl_user_mentoring_session_datetimes[0].cancel_by == 1 ? " by Vendor" : val?.tbl_user_mentoring_session_datetimes[0].is_cancelled === "yes" && val?.tbl_user_mentoring_session_datetimes[0].cancel_by == 2 ? " by Customer" : ""}
             
            </td>
            <td>
             
                {val?.tbl_user_mentoring_session_datetimes[0]?.cancel_by === "3" ? (val?.tbl_user_mentoring_session_datetimes[0]?.status === "Cancelled" ? "Full Refund" : val?.tbl_user_mentoring_session_datetimes[0]?.status === "Partial" ? "Partial Refund" : val?.tbl_user_mentoring_session_datetimes[0]?.status) : val?.tbl_user_mentoring_session_datetimes[0]?.stripe_refund_id ? "Full Refund" : val?.tbl_user_mentoring_session_datetimes[0]?.status === "Partial" ? "Partial Refund" : val?.tbl_user_mentoring_session_datetimes[0]?.status}
            
            </td>
          </tr>
          {val?.tbl_user_mentoring_session_datetimes.slice(1).map((session, j) => (
            <tr className="border-bottom" style={{border:"2px solid black"}} key={j}>
              <td>{moment(session.session_date).format("MM-DD-YYYY")} {session.session_time}</td>
              <td>
               
                  {session.is_cancelled === "yes" ? "Cancelled" : moment(session.session_date).format("MM-DD-YYYY") >= date ? "Upcoming" : "Attended"}
                  {session.is_cancelled === "yes" && session.cancel_by == 1 ? " by Vendor" : session.is_cancelled === "yes" && session.cancel_by == 2 ? " by Customer" : ""}
             
              </td>
              <td>
             
                  {session?.cancel_by === "3" ? (session?.status === "Cancelled" ? "Full Refund" : session?.status === "Partial" ? "Partial Refund" : session?.status) : session?.stripe_refund_id ? "Full Refund" : session?.status === "Partial" ? "Partial Refund" : session?.status}
             
              </td>
            </tr>
          ))}
        </>
      );
    })}
</table>

                      </div>
                    </BlockHead>
                  </BlockHeadContent>
                </div>
              </Card>
              <ModalFooter className=""></ModalFooter>
            </Modal>
            <div className="card-inners relativeClass">
              <div className="card-inners relativeClass">
                <BlockHeadContent>
                  <BlockHead size="lg">
                    <div className="p-4">
                      <div className="filtersidebutton">
                        <h3 className="graphheading  mb-0 mt-1">Graph View</h3>
                        <button className="btn btn-md btn-primary" type="submit" onClick={clearFilterGraph}>
                          Clear Filters
                        </button>
                      </div>

                      <div className="row">
                        <div className="col-lg-4">
                          <div className="form-control border-0 px-0">
                            <label>
                              <strong>Select Year</strong>
                            </label>

                            <div className="form-control-wrap">
                              <select className="form-select" value={selectyear} onChange={handleyear}>
                                {years.map((year, index) => {
                                  return (
                                    <option key={`year${index}`} value={year}>
                                      {year}
                                    </option>
                                  );
                                })}
                              </select>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-4">
                          <div className="form-control border-0 px-0">
                            <label>
                              <strong>Customer Name</strong>
                            </label>

                            <div className="form-control-wrap paymetsession">
                              <i
                                className="fa fa-chevron-down chevron-downcustome"
                                onClick={displaySelectUserGraph}
                              ></i>
                              <Select
                                className="form-select"
                                defaultvalue={userId}
                                options={userNameData}
                                ref={selectUserRefGraph}
                                openMenuOnFocus={displayvalueSelectUserGraph}
                                onChange={HandleUserName}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-4">
                          <label>
                            <strong>Student Name</strong>
                          </label>
                          <div className="form-control border-0 p-0">
                            <div className="form-control-wrap paymetsession">
                              <i
                                className="fa fa-chevron-down chevron-downcustome"
                                onClick={displaySelectChildGraph}
                              ></i>
                              <Select
                                className="form-select"
                                defaultvalue={childName1}
                                options={childNameData1}
                                ref={selectChildRefGraph}
                                openMenuOnFocus={displayvalueSelectChildGraph}
                                onChange={HandleChildName1}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-4">
                          <div className="form-control border-0 px-0">
                            <label>
                              <strong>Vendor Name</strong>
                            </label>
                            <div className="form-control-wrap paymetsession">
                              <i
                                className="fa fa-chevron-down chevron-downcustome chevron-downcustomerList"
                                onClick={handleVendor}
                              ></i>
                            
                              <Select
                                className="form-select form-selectparent"
                                autosize={true}
                                onChange={handlementorname}
                                defaultValue={mentorid}
                                options={[
                                  { value: "", label: "All" },
                                  ...(mentorsolddata?.map((val) => ({
                                    value: val.mentor_id,
                                    label: val.sold_by_user?.firstName + " " + val.sold_by_user?.lastName,
                                  })) || []),
                                ]}
                                openMenuOnFocus={vendorname}
                                ref={selectRefGraphvendor}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-4">
                          <div className="form-control border-0 px-0">
                            <label>
                              <strong>Resource</strong>
                            </label>

                            <div className="form-control-wrap paymetsession">
                              <i
                                className="fa fa-chevron-down chevron-downcustome"
                                onClick={displaySelectResourceGraph}
                              ></i>
                              <Select
                                className="form-select"
                                autosize={true}
                                defaultValue={selectedResourse}
                                ref={selectResourceRefGraph}
                                openMenuOnFocus={displayvalueSelectResourceGraph}
                                // value={selectedResourse}
                                onChange={(e) => setselectedResourse(e)}
                                onMenuScrollToBottom={handleMenuScroll}
                                options={[
                                  { value: "", label: "All Resource" },
                                  ...resourceDropdown?.map((v) => ({
                                    value: v?.tbl_user_resource.id,
                                    label: v?.tbl_user_resource.resource_title,
                                  })),
                                ]}
                              />
                            </div>
                          </div>
                        </div>

                        <div className="col-lg-3">
                          <div className="form-control border-0 px-0">
                            <label className="w-100 mb-0">&nbsp;</label>
                            <button className="btn btn-primary" type="submit" onClick={handlechart}>
                              {" "}
                              View{" "}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>

                    {mentoringPaymentreportchart && mentoringPaymentreportchart?.length > 0 ? (
                      <div>
                        <div className="p-5">
                          <div className="row">
                            <div className="col-lg-6">
                              <div className="relativeClass">
                                {loader ? (
                                  <div className="overflowloader" id="preload">
                                    <div className="circle-square">
                                      <div className="red" style={{ background: "#AAC0CE" }}></div>
                                      <div className="blue" style={{ background: "#265472" }}></div>
                                      <div className="green" style={{ background: "#F19357" }}></div>
                                      <div className="yellow" style={{ background: "#F0B285" }}></div>
                                    </div>
                                  </div>
                                ) : (
                                  <HighchartsReact
                                    highcharts={Highcharts}
                                    options={{
                                      chart: {
                                        type: "line",
                                      },
                                      title: {
                                        text: "Monthly Scheduled Sessions Payment Chart",
                                        style: { color: "#003C71", fontSize: "25px" },
                                      },

                                      xAxis: {
                                        categories: [
                                          "Jan",
                                          "Feb",
                                          "Mar",
                                          "Apr",
                                          "May",
                                          "Jun",
                                          "Jul",
                                          "Aug",
                                          "Sep",
                                          "Oct",
                                          "Nov",
                                          "Dec",
                                        ],
                                        labels: {
                                          style: {
                                            color: "#364a63",
                                          },
                                        },
                                      },
                                      plotOptions: {
                                        line: {
                                          dataLabels: {
                                            enabled: true,
                                            formatter: function () {
                                              return this.y != 0
                                                ? new Intl.NumberFormat("en-US", {
                                                    style: "currency",
                                                    currency: "USD",
                                                  }).format(this.y)
                                                : "$0.00";
                                            },
                                          },
                                          enableMouseTracking: true,
                                        },
                                      },
                                      credits: {
                                        enabled: false,
                                      },

                                      yAxis: {
                                        title: {
                                          text: "Scheduled Sessions",
                                          style: {
                                            color: "#364a63",
                                          },
                                        },
                                        labels: {
                                          style: {
                                            color: "#364a63",
                                          },
                                        },
                                      },
                                      series: [
                                       
                                        {
                                          name: "Scheduled Session Booked Amount",
                                          data: showchart1,
                                          color: "#364a63",
                                        },
                                      ],
                                    }}
                                  />
                                )}
                              </div>
                            </div>

                            <div className="col-lg-6">
                              <div className="relativeClass">
                                {loader ? (
                                  <div className="overflowloader" id="preload">
                                    <div className="circle-square">
                                      <div className="red" style={{ background: "#AAC0CE" }}></div>
                                      <div className="blue" style={{ background: "#265472" }}></div>
                                      <div className="green" style={{ background: "#F19357" }}></div>
                                      <div className="yellow" style={{ background: "#F0B285" }}></div>
                                    </div>
                                  </div>
                                ) : (
                                  <HighchartsReact
                                    highcharts={Highcharts}
                                    options={{
                                      chart: {
                                        type: "line",
                                      },
                                      title: {
                                        text: "Monthly Scheduled Sessions Count Chart",
                                        style: { color: "#003C71", fontSize: "25px" },
                                      },

                                      xAxis: {
                                        categories: [
                                          "Jan",
                                          "Feb",
                                          "Mar",
                                          "Apr",
                                          "May",
                                          "Jun",
                                          "Jul",
                                          "Aug",
                                          "Sep",
                                          "Oct",
                                          "Nov",
                                          "Dec",
                                        ],
                                        labels: {
                                          style: {
                                            color: "#364a63",
                                          },
                                        },
                                      },
                                      plotOptions: {
                                        line: {
                                          dataLabels: {
                                            enabled: true,
                                            formatter: function () {
                                              return this.y != 0 ? this.y : "";
                                            },
                                          },
                                          enableMouseTracking: true,
                                        },
                                      },
                                      credits: {
                                        enabled: false,
                                      },

                                      yAxis: {
                                        title: {
                                          text: "Scheduled Sessions",
                                          style: {
                                            color: "#364a63",
                                          },
                                        },
                                        labels: {
                                          style: {
                                            color: "#364a63",
                                          },
                                        },
                                      },
                                      series: [
                                        {
                                          name: "Month",
                                          data: showchart,
                                          color: "rgb(38, 84, 114)",
                                        },
                                        
                                      ],
                                    }}
                                  />
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <p className="p-4">No Data found</p>
                    )}

                    <div className="p-4">
                      <div className="d-flex justify-content-between mb-2">
                        <div className="filtersidebutton">
                          <h3 className="graphheading mb-0 mt-1">List View</h3>
                          <button className="btn btn-md btn-primary" type="submit" onClick={clearFilterList}>
                            Clear Filters
                          </button>
                        </div>
                        <div className="row">
                          <div className="col-lg-12 d-flex  justify-content-end">
                            <select
                              value={sortvalue}
                              className="form-select"
                              style={{ width: "190px" }}
                              onChange={HandleSort}
                            >
                              <option value="">Sort By</option>
                              <option value="ASCDATE">Date Ascending</option>
                              <option value="DESCDATE">Date Descending</option>
                            </select>

                            <div>
                              <label className="mt-1 ">&nbsp;&nbsp;Show&nbsp;</label>
                            </div>
                            <div>
                              <select
                                value={limitvalue}
                                className="form-select"
                                style={{ width: "85px" }}
                                onChange={HandleLimitValue}
                              >
                                {/* <option value="5">5</option> */}
                                <option value="10">10</option>
                                <option value="20">20</option>
                                <option value="30">30</option>
                                <option value="50">50</option>
                                <option value="100">100</option>
                              </select>
                            </div>
                            <div>
                              &nbsp; &nbsp;
                              <button className="btn btn-primary " onClick={ExportEcelFile}>
                                <em className="icon ni ni-download-cloud"></em>
                                <span>Export</span>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row pb-2">
                        <div className="col-lg-4">
                          <div className="form-control border-0 px-0">
                            <label>
                              <strong>Customer Name</strong>
                            </label>

                            <div className="form-control-wrap paymetsession">
                              <i className="fa fa-chevron-down chevron-downcustome" onClick={displaySelectUserList}></i>
                              <Select
                                className="form-select"
                                defaultvalue={userId}
                                options={userNameData}
                                ref={selectUserRefList}
                                openMenuOnFocus={displayvalueSelectUserList}
                                onChange={HandleUserName}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-4">
                          <div className="form-control border-0 px-0">
                            <label>
                              <strong>Student Name</strong>
                            </label>

                            <div className="form-control-wrap">
                              {/* <i className="fa fa-chevron-down"></i> */}
                              <select className="form-select pl-2" onChange={HandleChildName} value={childId}>
                                <option value="">Select Student Name</option>
                                {childNameData.map((val) => {
                                  return <option value={val.id}>{val.firstName + " " + val.lastName}</option>;
                                })}
                              </select>
                            </div>
                          </div>
                          {/* <span className="error">{toDateError}</span> */}
                        </div>
                        <div className="col-lg-4">
                          <div className="form-control border-0 px-0">
                            <label>
                              <strong>Vendor Name</strong>
                            </label>
                            <div className="form-control-wrap paymetsession">
                              <i
                                className="fa fa-chevron-down chevron-downcustome chevron-downcustomerList"
                                onClick={handleVendors}
                              ></i>

                              <Select
                                className="form-select form-selectparent"
                                autosize={true}
                                onChange={handlementorname}
                                defaultValue={mentorid}
                                options={[
                                  { value: "", label: "All" },
                                  ...(mentorsolddata?.map((val) => ({
                                    value: val.mentor_id,
                                    label: val.sold_by_user?.firstName + " " + val.sold_by_user?.lastName,
                                  })) || []),
                                ]}
                                openMenuOnFocus={vendornames}
                                ref={selectRefListvendor}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-4">
                          <div className="form-control border-0 px-0">
                            <label>
                              <strong>Resource</strong>
                            </label>

                            <div className="form-control-wrap paymetsession">
                              <i
                                className="fa fa-chevron-down chevron-downcustome"
                                onClick={displaySelectResourceList}
                              ></i>
                              <Select
                                className="form-select"
                                autosize={true}
                                defaultValue={selectedResourse}
                                ref={selectResourceRefList}
                                openMenuOnFocus={displayvalueSelectResourceList}
                                // value={selectedResourse}
                                onChange={(e) => setselectedResourse(e)}
                                onMenuScrollToBottom={handleMenuScroll}
                                // options={
                                //   resourceDropdown?.map(v => ({ value: v?.tbl_user_resource.id, label: v?.tbl_user_resource.resource_title
                                //   }))}
                                options={[
                                  { value: "", label: "All Resource" },
                                  ...(resourceDropdown?.map((v) => ({
                                    value: v?.tbl_user_resource.id,
                                    label: v?.tbl_user_resource.resource_title,
                                  })) || []),
                                ]}
                              />
                            </div>
                          </div>
                        </div>

                        <div className="col-lg-4">
                          <div className="form-control border-0 px-0">
                            <label>
                              <strong>Start Date</strong>
                            </label>
                        
                            <div className="form-control-wrap" ref={newRef}>
                              <div className="form-icon form-icon-left">
                                <span onClick={() => handleclickdatepickericon()}>
                                  <Icon name="calendar"></Icon>
                                </span>
                              </div>
                              <DatePicker
                                placeholderText="MM/DD/YYYY"
                                selected={selectedfromdate}
                                onChange={HandleFromDate}
                                onFocus={() => setDatePickerShow(!datepickershow)}
                                className="form-control date-picker"
                                open={datepickershow}
                              />
                            </div>
                          </div>
                          <span className="error">{formDateError}</span>
                        </div>
                        <div className="col-lg-4">
                          <div className="form-control border-0 px-0">
                            <label>
                              <strong>End Date</strong>
                            </label>
                        
                            <div className="form-control-wrap" ref={newRef1}>
                              <div className="form-icon form-icon-left">
                                <span onClick={() => handleclickdatepickericon1()}>
                                  <Icon name="calendar"></Icon>
                                </span>
                              </div>
                              <DatePicker
                                placeholderText="MM/DD/YYYY"
                                selected={selectedtodate}
                                onChange={HandleToDate}
                                onFocus={() => setDatePickerShow1(!datepickershow1)}
                                className="form-control date-picker"
                                open={datepickershow1}
                              />
                            </div>
                          </div>
                          <span className="error">{toDateError}</span>
                        </div>
                      </div>

                      <div className="d-flex flex-row" style={{ gap: "10px" }}>
                        <div className="outerTypeBox mb-3">
                          <button className="btn btn-primary" type="submit" onClick={handlesearch}>
                            {" "}
                            Search{" "}
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="relativeClass newregisterGrouptable">
                      {loader1 ? (
                        <div className="overflowloader" id="preload">
                          <div className="circle-square">
                            <div className="red" style={{ background: "#AAC0CE" }}></div>
                            <div className="blue" style={{ background: "#265472" }}></div>
                            <div className="green" style={{ background: "#F19357" }}></div>
                            <div className="yellow" style={{ background: "#F0B285" }}></div>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}

                      {customerstoredreport && customerstoredreport?.length > 0 ? (
                        <div>
                          {customerstoredreport.some((obj) => Object.keys(obj).includes("sold_by_user")) ? (
                            <div className="color">
                              <ReactDataTable
                                data={customerstoredreport}
                                columns={columnsshow}
                                className="nk-tb-list"
                              />
                            </div>
                          ) : (
                            <div className="color">
                              <ReactDataTable data={customerstoredreport} columns={columns} className="nk-tb-list" />
                            </div>
                          )}
                        </div>
                      ) : (
                        <p className="p-4" style={{ minHeight: "100px" }}>
                          No Data Found
                        </p>
                      )}
                    </div>

                    <div className="p-4">
                      {customerstoredreport?.length > 0 ? (
                        <div>
                          <ReactPaginate
                            previousLabel={"<<"}
                            nextLabel={">>"}
                            pageCount={count}
                            pageRangeDisplayed={5}
                            marginPagesDisplayed={2}
                            onPageChange={handlePageChange}
                            containerClassName={"pagination"}
                            pageClassName={"page-item"}
                            activeClassName={"active"}
                            pageLinkClassName={"page-link"}
                            previousClassName={"page-item"}
                            previousLinkClassName={"page-link"}
                            nextClassName={"page-item"}
                            nextLinkClassName={"page-link"}
                            breakClassName={"page-item"}
                            breakLinkClassName={"page-link"}
                            forcePage={currentPage - 1}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </BlockHead>
                </BlockHeadContent>
              </div>
            </div>
          </Card>
        </>
      </Content>
    </React.Fragment>
  );
}
export default MentoringSessionPaymentReport;
