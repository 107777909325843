import React, { useState,useRef } from "react";
import Head from "../../../layout/head/Head";
import Content from "../../../layout/content/Content";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import classnames from "classnames";
import { useEffect } from "react";
import { URLS } from "../../../constants/urls";
import { BlockHead, BlockHeadContent } from "../../../components/Component";
import { DropdownItem, UncontrolledDropdown, DropdownMenu, DropdownToggle, CardTitle } from "reactstrap";
import {
  Block,
  BlockTitle,
  BlockBetween,
  BlockDes,
  Icon,
  Row,
  Col,
  Button,
  DataTableHead,
  DataTableBody,
  DataTableRow,
  DataTableItem,
  PaginationComponent,
  ReactDataTable,
  UserAvatar,
  PreviewCard,
} from "../../../components/Component";
import { Link } from "react-router-dom";
import { Card, Badge } from "reactstrap";
import { useHistory } from "react-router-dom";
const ExcelJS = require('exceljs');
import { makePostRequest, makeGetRequest } from "../../../service/api";

import ReactPaginate from "react-paginate";
import { AreaChart, Area, BarChart, Bar, ResponsiveContainer, CartesianGrid, XAxis, YAxis, Tooltip, Legend, Label, LineChart, Line } from "recharts";

import Select from "react-select";
import moment from "moment";
import DatePicker from "react-datepicker";
import Highcharts, { chart } from "highcharts";

import HighchartsReact from 'highcharts-react-official';
// import exporting from "highcharts/modules/exporting";
import { ToastContainer, toast } from "react-toastify";
import { Modal, ModalFooter, ModalHeader } from "reactstrap";
import { findUpper, capital } from "../../../utils/Utils";


// exporting(Highcharts);


function CustomerStoredReport() {

  let history = useHistory();
  const [activeTab, setActiveTab] = useState("1");
  const [activeIconTab, setActiveIconTab] = useState("5");
  const [activeAltTab, setActiveAltTab] = useState("9");
  //   const [verticalTab, setVerticalTab] = useState("1");
  const [verticalIconTab, setVerticalIconTab] = useState("1");
  const [customerstoredreport, setCustomerStoredreport] = useState([]);
  const [customerstoredreportchart, setCustomerStoredreportchart] = useState([]);
  const [loader, setLoader] = useState(false);
  const [loader1, setLoader1] = useState(false);
  const [selectyear, setSelectyear] = useState(new Date().getFullYear());
  const [displayvalue, setDisplayValue] = useState("1");
  const [childId, setChildId] = useState("");
  const [userName, setUserName] = useState("");
  const [userNameList, setUserNameList] = useState("");

  const [childName, setChildName] = useState("");

  const [childName1, setChildName1] = useState("");
  const [childId1, setChildId1] = useState("");

  const [userNameData, setUserNameData] = useState([])
  const [childNameData, setChildNameData] = useState([])
  const [childNameData1, setChildNameData1] = useState([])
  const [userId, setUserId] = useState("");
  const [userIdList, setUserIdList] = useState("");

  const [selectedfromdate, setSelectedFromDate] = useState(null);
  const [selectedtodate, setSelectedToDate] = useState(null);
  const [savedtypeGraph, setSavedTypeGraph] = useState("");
  const [savedtype, setSavedType] = useState("");
  const [resourcetype, setResourceType] = useState("");
  const [resourcetypes, setResourceTypeList] = useState("");
  const [resourcemode, setResourceMode] = useState("");
  const [resourcemodes, setResourceModeGraph] = useState("");

  const [getAllResourceNew, setgetAllResourceNew] = useState([]);
  const [limitvalue, setlimitvalue] = useState("")
  const [sortvalue, setSortValue] = useState("")
  const [showchart, setshowchart] = useState([])
  const [datepickershow, setDatePickerShow] = useState(false);
  const [datepickershow1, setDatePickerShow1] = useState(false);
  const [toDateError, settoDateError] = useState("");
  const [formDateError, setformDateError] = useState("");
  const [customerSavedData, setCustomerSavedData] = useState([])
   const [displayvalueSelect, setDisplayValueSelect] = useState(true);
  const [displayvalueSelectGraph, setDisplayValueSelectGraph] = useState(true);
  const [clearStateGraph, setClearStateGraph] = useState(false);
  const [clearStateList, setClearStateList] = useState(false);
  
  const [isOpen, setIsOpen] = useState(false);
  const colorThemes = ["primary", "purple", "danger", "warning", "success", "pink", "secondary", "blue"]
  const year = 2023;
  const curryear = new Date().getFullYear();
  let years = Array.from(new Array(curryear - 2023 + 2), (val, index) => index + year);
  let chartdata = []


  const handleyear = (e) => {
    setSelectyear(e.target.value);
  }

  const HandleFromDate = (e) => {
    setSelectedFromDate(e);
    setDatePickerShow(!datepickershow);
    setformDateError("");
  }


  const HandleToDate = (e) => {
    setSelectedToDate(e);
    setDatePickerShow1(!datepickershow1);
    settoDateError("");
  }
  const HandleUserName = (e) => {
    setUserId(e.value);
    setUserName(e.label);
    setChildId("")

  }
  const HandleUserNameList = (e) => {
    setUserIdList(e.value);
    setUserNameList(e.label);
    setChildId("")

  }
  function handleclickdatepickericon() {


    setDatePickerShow(!datepickershow);

  }
  function handleclickdatepickericon1() {


    setDatePickerShow1(!datepickershow1);


  }
  const HandleChildName = (e) => {
    setChildId(e.target.value);
    // setChildName(e.label);
  }
  const HandleChildName1 = (e) => {
    //setChildId1(e.value);
    setChildName1(e.label);
	setChildId1(e.target.value);
  }
  const handlesearch = () => {
    if (selectedfromdate != null && selectedtodate == null) {
      settoDateError("please select valid end date")
    }
    else if (selectedtodate != null && selectedfromdate == null) {
      setformDateError("please select valid start date ")
    }
    else if (selectedfromdate > selectedtodate) {
      toast.error("end date should be greater than start date")
    }
    else {

      getcustomerstoredreport();
      // getcustomersstoredreportchart();
      // setUserId("")
     // setChildId("")
      setCurrentPage(1)
    }
  }

  const handlechart = () => {
    getcustomersstoredreportchart();
    // setUserId("")
    setChildId("")
  }
  const HandleLimitValue = (e) => {

    setlimitvalue(e.target.value)
    setCurrentPage(1)
  }
  const HandleSort = (e) => {
    setSortValue(e.target.value);
    setCurrentPage(1)
  }
  const HandleSaveTypeGraph = (e) => {
    setSavedTypeGraph(e.target.value);
  }
  const HandleSaveType = (e) => {
    setSavedType(e.target.value);
  }
  const HandleResourceType = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    setResourceType(value);

  }
  const HandleResourceTypeList = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    setResourceTypeList(value);

  }
  const handleResourceModeGraph = (e) => {
    setResourceModeGraph(e.target.value);
  }

  const handleResourceMode = (e) => {
    setResourceMode(e.target.value);
  }

  const openModal = () => {
    setIsOpen(!isOpen);
  };

  const clearFilterGraph = () => {
    clearFilterGraphFun();
  }

  const clearFilterGraphFun = () => {
    setUserId("");
    setUserIdList("");
    setChildId("");
    setChildId1("");
    selectRefGraph.current.select.setValue("", "select-option");
   setSelectyear(new Date().getFullYear());
    setSavedType("");
    setSavedTypeGraph("");
    setResourceType("");
    // setResourceMode("");
    setResourceModeGraph("");

    setClearStateGraph(!clearStateGraph);
    
  }
  const clearFilterList = () => {
    clearFilterListFun();
  }

  const clearFilterListFun = () => {
    setUserIdList("");
    setUserId("");
    setChildId1("");
    setChildId("");
    setlimitvalue("");
    setSortValue("");
    setResourceTypeList("");
    setCurrentPage(1);
    selectRef.current.select.setValue("", "select-option");
    setResourceMode("")
    setSavedType("")
    setSelectedFromDate(null);
    setSelectedToDate(null);
    setClearStateList(!clearStateList);
  }
  const viewSavedDetails = (row) => {

    let data = {
      "saved_date": row.saved_date,
      "user_id": row.tbl_user.id,
      child_id: childId ? childId : "",
      saved_type: savedtype ? savedtype : "",
      resource_type: resourcetype ? resourcetype : "",
    }
    console.log("Saved details payload", data)
    makePostRequest(URLS.GET_SAVING_DETAILS, null, data, null)
      .then((res) => {
        if (res.code == 200) {
        console.log("saved details", res)
          setCustomerSavedData(res.data)
          setIsOpen(!isOpen);
        }
        else {
          toast.error(res.message)
        }


      })




  }
  const ExportEcelFile = () => {

    setLoader(true);

    let data = {
      start_date: selectedfromdate ? moment(selectedfromdate).format("YYYY-MM-DD") : "",
      end_date: selectedtodate ? moment(selectedtodate).format("YYYY-MM-DD") : "",
      user_id: userId ? userId : "",
      child_id: childId ? childId : "",
      saved_type: savedtype ? savedtype : "",
      resource_type: resourcetype ? resourcetype : "",
      resource_mode: resourcemode ? resourcemode : "",
      "page_number": currentPage ? currentPage : 1,
      "item_per_page": limitvalue ? parseInt(limitvalue) : 10,
      sort_by: sortvalue ? sortvalue : "",
      export_data: "yes",

    };
    console.log(data,"exportdata")
    makePostRequest(URLS.GET_CUSTOMER_STORED_REPORTSLIST, null, data, null)
      .then((res) => {
        if (res.code == 200) {
          //console.log("saved export", res?.data?.list_data)
          const workbook = new ExcelJS.Workbook();
          const sheet = workbook.addWorksheet("SavedData");
          sheet.properties.defaultRowHeight = 30;



          sheet.getRow(1).font = {
            size: 10,
            bold: true,
          }

          sheet.columns = [
            {
              header: "S.No",
              key: 'S_No',
              width: 5
            },
            {
              header: "Save Date",
              key: 'REGISTER_DATE',
              width: 15
            },
            {
              header: "Customer Name",
              key: 'USER_NAME',
              width: 30
            },

            {
              header: "Email",
              key: 'OWNEREMAIL',
              width: 30
            },
            {
              header: "Phone",
              key: 'OWNWERPHONE',
              width: 30
            },
            {
              header: "Total Saved",
              key: 'totalsaved',
              width: 20
            },


          ];
          res?.data?.list_data?.map((val, i) => {
            sheet.addRow({
              S_No: (i + 1),
              REGISTER_DATE: val.saved_date,
              USER_NAME: val?.tbl_user.firstName,
              OWNEREMAIL: val?.tbl_user.email,
              OWNWERPHONE: val?.tbl_user.phone,
              totalsaved: val.total_saved,


            })

          })

          workbook.xlsx.writeBuffer().then(data => {
            const blob = new Blob([data], {
              type: "application/vnd.openxmlformats-officedocument.spreadsheet.sheet",
            })
            const url = window.URL.createObjectURL(blob);
            const anchor = document.createElement('a');
            anchor.href = url;
            anchor.download = 'SavedReport.xlsx';
            anchor.click();
            window.URL.revokeObjectURL(url)
          })
          setLoader(false);
          //console.log(res.data, "res g")

        }
      })
      .catch((error) => {
        console.log(error);
      });

  }

  const getAllUserType = () => {
    setLoader(true);
    makeGetRequest(URLS.GET_ALL_RESOURCE_TYPE, {}, null)
      .then(res => {
        if (res.code == 200) {
          setgetAllResourceNew(res.data);
          setLoader(false);
        }
      })
      .catch((error) => {
        console.log(error);
      })

  }

  const getUserName = () => {
    makePostRequest(URLS.GET_ALL_ONLY_CUSTOMER, null, {}, null)
      .then((res) => {
       // console.log(res)
        if (res.code == 200) {
          let option = [];
          option.push({ value: "", label: "All Users" })
          res.data.length > 0 && res.data.map((val) => {
            option.push({ value: val.id, label: val.firstName + " " + val.lastName })
          })
          setUserNameData(option);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

 
  const getChildName = () => {

    let data = {
      user_id: userId ? userId : ""
    }
   // console.log("child payload", data)
    makePostRequest(URLS.GETALL_CHILD, null, data, null)
      .then((res) => {
       //console.log("child datattttttt", res)
        if (res.code == 200) {
         
          setChildNameData(res.data)
        
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };


  const getChildNameGraph = () => {

    let data = {
      user_id: userIdList ? userIdList : ""
    }
   // console.log("child payload", data)
    makePostRequest(URLS.GETALL_CHILD, null, data, null)
      .then((res) => {
   
        if (res.code == 200) {
          setChildNameData1(res.data);
       
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };


  const getcustomersstoredreportchart = () => {
    setLoader(true);
    let data = {
      year: selectyear,
      user_id: userId ? userId : "",
      child_id: childId1 ? childId1 : "",
      saved_type: savedtypeGraph ? savedtypeGraph : "",
      resource_type: resourcetype ? resourcetype : "",
      resource_mode: resourcemodes ? resourcemodes : "",

    };
    //console.log("data chart", data)
    makePostRequest(URLS.GET_CUSTOMER_STORED_REPORTSCHART, null, data, null)
      .then((res) => {
        // setLoader(true)
        if (res.code === 200) {
         // console.log(res, "Stored chart data");
          setCustomerStoredreportchart(res?.data?.graph_data);
          setLoader(false);

        } else {
          setLoader(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  /*=================================== customer save report list api==========================*/

  const [currentPage, setCurrentPage] = useState(1);
  const [count, setCount] = useState();

  const getcustomerstoredreport = () => {
    setLoader1(true);
    let data = {
      start_date: selectedfromdate ? moment(selectedfromdate).format("YYYY-MM-DD") : "",
      end_date: selectedtodate ? moment(selectedtodate).format("YYYY-MM-DD") : "",
      user_id: userId ? userId : "",
      child_id: childId ? childId : "",
      saved_type: savedtype ? savedtype : "",
       resource_type: resourcetypes ? resourcetypes : "",
      resource_mode: resourcemode ? resourcemode : "",
      "page_number": currentPage ? currentPage : 1,
      "item_per_page": limitvalue ? parseInt(limitvalue) : 10,
      sort_by: sortvalue ? sortvalue : ""

    };
    console.log(data, "Stored data");
    makePostRequest(URLS.GET_CUSTOMER_STORED_REPORTSLIST, null, data, null)
      .then((res) => {
        // setLoader(true)
        if (res.code === 200) {
         console.log(res, "Stored data");
          setCustomerStoredreport(res?.data?.list_data);
          setCount(Math.ceil(res.data.total_count / parseInt(limitvalue ? limitvalue : 10)));
          setLoader1(false);

        } else {
          setLoader1(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const handlePageChange = (selectedPage) => {
    setCurrentPage(selectedPage.selected + 1)
  };
  const fetchData = () => {
    let data = {
      start_date: selectedfromdate ? moment(selectedfromdate).format("YYYY-MM-DD") : "",
      end_date: selectedtodate ? moment(selectedtodate).format("YYYY-MM-DD") : "",
      user_id: userId ? userId : "",
      child_id: childId ? childId : "",
      saved_type: savedtype ? savedtype : "",
      resource_type: resourcetype ? resourcetype : "",
      resource_mode: resourcemode ? resourcemode : "",
      "page_number": currentPage ? currentPage : 1,
      "item_per_page": limitvalue ? parseInt(limitvalue) : 10,
      sort_by: sortvalue ? sortvalue : ""

    };

    makePostRequest(URLS.GET_CUSTOMER_STORED_REPORTSLIST, null, data, null)
      .then((res) => {
        if (res.code == 200) {
          setCustomerStoredreport(res?.data?.list_data);
          setCount(Math.ceil(res.data.total_count / parseInt(limitvalue ? limitvalue : 10)));
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    fetchData()
  }, [currentPage])




  useEffect(() => {
    getUserName();
    getAllUserType();
    getcustomerstoredreport();
    getcustomersstoredreportchart();
  }, []);
  useEffect(() => {

    getchartdata();



  }, [customerstoredreportchart])

  const getchartdata = () => {
    customerstoredreportchart?.map((val) => {
      chartdata.push(val.total_saved)

    })
    setshowchart(chartdata)
  }

  // useEffect(() => {
  //   getcustomersstoredreportchart();
  // }, [selectyear]);

  useEffect(() => {
    
    getChildNameGraph();
  }, [userIdList]);

  useEffect(() => {
    getChildName();
  }, [userId]);

  useEffect(() => {
    getcustomerstoredreport();
  }, [limitvalue, sortvalue])

  useEffect(() => {
    getcustomersstoredreportchart();
  }, [clearStateGraph]);

 useEffect(() => {
  getcustomerstoredreport();
  }, [clearStateList]);


  const columns = [
    {
      name: "#",
      selector: (row) => row.id,
      grow: 1,
      minWidth: "50px",
      style: { minWidth: "50px" },
      borderRadius: "4px",
      cell: (row, index) => (
        <>
          <div className="user-card mt-2 mb-2"> {currentPage==1?(index + 1):((currentPage-1)*(limitvalue!=""?parseInt(limitvalue):10))+(index + 1)} </div>
        </>
      ),
    },
	{
      name: "Customer Details",
      grow: 4,
      sortable: true,
      cell: (row) => (
        <div className="user-card mt-2 mb-2">
          {/* <UserAvatar theme={colorThemes[Math.floor(Math.random() * colorThemes.length)]} text={findUpper(capital(row.tbl_user.firstName + " " + row.tbl_user.lastName))} ></UserAvatar> */}
          <div className="user-info">
            <span className="tb-lead"> {row.tbl_user.firstName ? row.tbl_user.firstName : "--"} {" "} {row.tbl_user.lastName ? row.tbl_user.lastName : ""} </span>
            <span className="sub-text">{row.tbl_user.email ? row.tbl_user.email : ""}</span>
          </div>
        </div>
      ),
    },
    {
      name: "Saved Date",
      selector: (row) => row.saved_date,
      grow: 2,
    
      cell: (row, index) => (
        <>
          <div className="user-card mt-2 mb-2">{row.saved_date ? moment(row.saved_date).format("MM-DD-YYYY") : "--"} </div>
        </>
      ),
    },
   
    {
      name: "Total Saved",
      grow: 2,
     
      sortable: true,
      cell: (row) => (
        <div className="user-card mt-2 mb-2"> {row.total_saved ? row.total_saved : "--"} </div>
      ),
    },
    {
      name: "Saved Details",

      grow: 2,

      sortable: true,
      cell: (row) => (
        <div className="user-card mt-2 mb-2">  <span><button
          className="btn btn-md"
          onClick={() => {
            viewSavedDetails(row);
          }}
        >
          <em className="icon ni ni-eye"></em>
        </button>
        </span> </div>
      ),
    },

  ]
  const columnsshow = [
    {
      name: "#",
      selector: (row) => row.id,
      grow: 0,
      minWidth: "50px",
      style: { minWidth: "50px" },
      borderRadius: "4px",
      cell: (row, index) => (
        <>
          <div className="user-card mt-2 mb-2"> {currentPage==1?(index + 1):((currentPage-1)*(limitvalue!=""?parseInt(limitvalue):10))+(index + 1)} </div>
        </>
      ),
    },
	{
      name: "Customer Details",
      grow: 4,
      sortable: true,
      cell: (row) => (
        <div className="user-card mt-2 mb-2">
          {/* <UserAvatar theme={colorThemes[Math.floor(Math.random() * colorThemes.length)]} text={findUpper(capital(row.tbl_user.firstName + " " + row.tbl_user.lastName))} ></UserAvatar> */}
          <div className="user-info">
            <span className="tb-lead"> {row.tbl_user.firstName ? row.tbl_user.firstName : "--"} {" "} {row.tbl_user.lastName ? row.tbl_user.lastName : ""} </span>
            <span className="sub-text">{row.tbl_user.email ? row.tbl_user.email : ""}</span>
          </div>
        </div>
      ),
    },
    {
      name: "Saved Date",
      selector: (row) => row.saved_date,
      grow: 2,
    
      cell: (row, index) => (
        <>
          <div className="user-card mt-2 mb-2">{row.saved_date ? moment(row.saved_date).format("MM-DD-YYYY") : "--"} </div>
        </>
      ),
    },
   
    {
      name: "Total Saved",
      grow: 2,
     
      sortable: true,
      cell: (row) => (
        <div className="user-card mt-2 mb-2"> {row.total_saved ? row.total_saved : "--"} </div>
      ),
    },
    {
      name: "Resource Type",
      grow: 2,
     
      sortable: true,
      cell: (row) => (
        <div className="user-card mt-2 mb-2"> {row?.tbl_user_resource.tbl_resource_type_master?.name ? row?.tbl_user_resource.tbl_resource_type_master?.name : "--"} </div>
      ),
    },
    {
      name: "Saved Details",

      grow: 2,

      sortable: true,
      cell: (row) => (
        <div className="user-card mt-2 mb-2">  <span><button
          className="btn btn-md"
          onClick={() => {
            viewSavedDetails(row);
          }}
        >
          <em className="icon ni ni-eye"></em>
        </button>
        </span> </div>
      ),
    },

  ]
  
  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };
  const toggleIconTab = (icontab) => {
    if (activeIconTab !== icontab) setActiveIconTab(icontab);
  };
  const toggleAltTab = (alttab) => {
    if (activeAltTab !== alttab) setActiveAltTab(alttab);
  };
    const newRef = useRef(null);
	 const newRef1 = useRef(null);
	  const selectRef = useRef(null);
  const selectRefGraph = useRef(null);
  
   const displaySelect = (e)=> {
    if (selectRef.current )
    {
     setDisplayValueSelect(!displayvalueSelect);
    selectRef.current.focus();
    }
  }
  
  const displaySelectGraph = (e)=> {
    if (selectRefGraph.current )
    {
    setDisplayValueSelectGraph(!displayvalueSelectGraph);
    selectRefGraph.current.focus();
    }
  }
  
  
  const handleOutSideClick = (e)=> {
	 if(newRef.current && !newRef.current.contains(e.target)){
         setDatePickerShow(false);
       		 
	 }		 
  }
   const handleOutSideClick1 = (e)=> {
	 if(newRef1.current && !newRef1.current.contains(e.target)){
         setDatePickerShow1(false);		 
	 }		 
  }
  useEffect(()=>{
	 document.addEventListener("mousedown", handleOutSideClick );
	 document.addEventListener("mousedown", handleOutSideClick1 );
      return ()=> {
       document.removeEventListener("mousedown", handleOutSideClick );
	   document.removeEventListener("mousedown", handleOutSideClick1 );
	  }	   
  });
  
  return (

    <React.Fragment>


      <Head title="Customer List"></Head>
      <Content>

        <h3 className="nk-block-title page-title mb5 pb-4"> Customer Saves Report</h3>

        {/* {loader ? (
           <div className="overflowloader" id="preload">
           <div className="circle-square" style={{marginTop:"250px"}} >
          <div className="red" style={{background:"#AAC0CE"}}></div>
          <div className="blue" style={{background:"#265472"}}></div>
          <div className="green" style={{background:"#F19357"}}></div>
          <div className="yellow" style={{background:"#F0B285"}}></div>
          </div>
           </div>
          ) : ( */}
        <>
          <Card className="card-bordered">
            <Modal isOpen={isOpen} toggle={openModal}>
              <ModalHeader
                toggle={openModal}
                close={
                  <button className="close" onClick={openModal}>
                    <Icon name="cross" />
                  </button>
                }
              >
                Resource Saved Details
              </ModalHeader>
              <Card className="card-bordered">
                <div className="save-details">
                  <div className="card-inner">
                    <BlockHeadContent>
                      <BlockHead size="lg">
                        <div className="customerDetailsTable">
                          <table style={{width: "100%"}}>
                            <tr className="border-bottom">
                              <th>#</th>
                              <th>Time of Save </th>
                              <th>Resource Title</th>
                              <th style={{width: "100px"}}>Child Name</th>

                            </tr>
                            {customerSavedData && customerSavedData?.map((val, i) => {
                              return (
                                <tr className="border-bottom">
                                  <td>{i + 1}</td>
                                  <td>{moment(val?.created_at).format("hh:mm a")}</td>
                                  <td>{val?.tbl_user_resource.resource_title}</td>
                                  <td>{val?.tbl_child?.firstName ? val?.tbl_child.firstName + " " + val?.tbl_child.lastName : "--"}</td>


                                </tr>
                              )

                            })}

                          </table>
                        </div>
                      </BlockHead>
                    </BlockHeadContent>
                  </div>
                </div>
              </Card>
              <ModalFooter className=""></ModalFooter>
            </Modal>
            <div className="card-inners relativeClass">
              <div className="card-inners relativeClass">
                <BlockHeadContent>
                  <BlockHead size="lg">
                  <div className="p-4">
                     <div className="filtersidebutton">
                      <h3 className="graphheading  mb-0 mt-1">Graph View</h3>
					  <button className="btn btn-md btn-primary" type="submit" onClick={clearFilterGraph} >Clear Filters</button>
                    </div>
                    {/* <div class="d-flex justify-content-end g-2">
                  <div class="dataTables_length" id="DataTables_Table_0_length">*/}
                    <div className="row">
                      <div className="col-lg-3">
                        <div className="form-select border-0 px-0">
                          <label>
                            <strong>Select Year</strong>
                          </label>

                          <div className="form-control-wrap">
                            <select className="form-select" value={selectyear} onChange={handleyear} >
                              {
                                years.map((year, index) => {
                                  return <option key={`year${index}`} value={year}>{year}</option>
                                })
                              }
                            </select>

                          </div>
                        </div>
                      </div>
                      <div className="col-lg-3">
                        <div className="form-control border-0 px-0">
                          <label>
                            <strong>Customer Name</strong>
                          </label>

                          {/* <input type="date" name ="formdate"  className="form-control" value={selectedfromdate} onChange={HandleFromDate} /> */}
                          <div className="form-control-wrap paymetsession">
                             <i className="fa fa-chevron-down chevron-downcustome"  onClick={displaySelectGraph}></i>
                            <Select
                            className="form-select"
                              defaultvalue={userId}
                              options={userNameData}
                               openMenuOnFocus={displayvalueSelectGraph}
                              onChange={HandleUserNameList}
							  ref={selectRefGraph}
                            />
                          </div>
                        </div>
                        {/* <span className="error">{toDateError}</span> */}
                      </div>
                      <div className="col-lg-3">
                        <div className="form-control border-0 px-0">
                          <label>
                            <strong>Student Name</strong>
                          </label>

                         
						 { /*   <div className="form-control-wrap paymetsession">

                            <Select
                            className="form-select"
                              defaultvalue={childName1}
                              options={childNameData1}

                              onChange={HandleChildName1}
                            />
		</div> */ }
						  <select  className="form-select pl-2" onChange={HandleChildName1} value={childId1} >
							<option value="">Select Student Name</option>
							{
							   
							  childNameData1.map((val)=>{
								return(
								  <option value={val.id}>{val.firstName + " " + val.lastName}</option>
								)
							  })
							}
							 
							</select>
						  
						  
                        </div>
                       
                      </div>
                      <div className="col-lg-3">
                        <div className="form-control border-0 px-0">
                          <label>
                            <strong>Saved Type </strong>
                          </label>

                          {/* <input type="date" name ="formdate"  className="form-control" value={selectedfromdate} onChange={HandleFromDate} /> */}
                          <div className="form-control-wrap">

                            <select className="form-select px-2" value={savedtypeGraph} onChange={HandleSaveTypeGraph} >

                              <option value="">Select</option>
                              <option value="self">Self Created</option>
                              <option value="other">Others</option>

                            </select>
                          </div>
                        </div>
                        {/* <span className="error">{toDateError}</span> */}
                      </div>
                      <div className="col-lg-3">
                        <div className="form-control border-0 px-0">


                          {/* <input type="date" name ="formdate"  className="form-control" value={selectedfromdate} onChange={HandleFromDate} /> */}
                          <div className="form-control-wrap paymetsession resourcetype">

                            <label><strong>Resource Type</strong></label>
                            <select className="form-select" name="resourceType" onChange={HandleResourceType} value={resourcetype} >
                              <option value=""> Select Resource Type </option>
                              {
                                getAllResourceNew.map((val, index) => {
                                  return (<>
                                    <option value={val.id} key={index + 1}>{val.name}</option>

                                  </>)
                                })

                              }
                            </select>
                          </div>
                        </div>
                        {/* <span className="error">{toDateError}</span> */}
                      </div>
                      <div className="col-lg-3">
                        <div className="form-control border-0 px-0">
                          <label>
                            <strong>Resource Mode</strong>
                          </label>

                          {/* <input type="date" name ="formdate"  className="form-control" value={selectedfromdate} onChange={HandleFromDate} /> */}
                          <div className="form-control-wrap">

                            <select className="form-select px-2" value={resourcemodes} onChange={handleResourceModeGraph} >

                              <option value="">Select</option>
                              <option value="save">Save</option>
                              <option value="serve">Serve</option>
                            </select>



                          </div>
                        </div>
                      </div>

                      {/* <div className="row">
                            </div> */}
                      <div className="col-lg-3">
                        <div className="form-control border-0 px-0">
						<label className="w-100 mb-0">&nbsp;</label>
                          <button className="btn btn-primary" type="submit" onClick={handlechart}>
                            {" "}
                            View{" "}
                          </button>

                          {/* <button className="btn btn-md btn-primary mt-2" type="submit" onClick={clearfilter}>Clearfilter</button> */}

                        </div>
                        {/* <span className="error">{toDateError}</span> */}
                      </div>

                    </div>
                    </div>

                    {customerstoredreportchart && customerstoredreportchart?.length > 0 ? (
                      <div>
                        <div className="p-5">
                          <div className="row">

                          </div>
                     
                          <div className="relativeClass">
                            {loader ? (
                              <div className="overflowloader" id="preload">
                                <div className="circle-square"  >
                                  <div className="red" style={{ background: "#AAC0CE" }}></div>
                                  <div className="blue" style={{ background: "#265472" }}></div>
                                  <div className="green" style={{ background: "#F19357" }}></div>
                                  <div className="yellow" style={{ background: "#F0B285" }}></div>
                                </div>
                              </div>
                            ) : (
                              <HighchartsReact
                                highcharts={Highcharts}
                                options={{
                                  chart: {
                                    type: 'line'
                                  },
                                  title: {
                                    text: 'Monthly Saves Chart',
                                    style: { "color": "#003C71", "fontSize": "25px" }
                                  },

                                  xAxis: {
                                    categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
                                    labels: {
                                      style: {
                                        color: '#364a63'
                                      }
                                    }
                                  },
                                  plotOptions: {
                                    line: {
                                      dataLabels: {
                                        enabled: true,
                                        formatter: function () {
                                          return (this.y != 0) ? this.y : "";
                                        }
                                      },
                                      enableMouseTracking: true
                                    }
                                  },
                                  credits: {
                                    enabled: false
                                  },

                                  yAxis: {
                                    title: {
                                      text: 'No. of Saves',
                                      style: {
                                        color: '#364a63'
                                      }
                                    },
                                    labels: {
                                      style: {
                                        color: '#364a63'
                                      }
                                    }


                                  },
                                  series: [{
                                    name: "Month",
                                    data: showchart,
                                    color: "rgb(38, 84, 114)"


                                  }]
                                }}
                              />
                            )}
                          </div>
                        </div>
                      </div>
                    ) : <p className="p-4">No Data found</p>}

                     <div className="p-4"> 
                    <div className="d-flex justify-content-between mb-2">
                      <div className="filtersidebutton">
                          <h3 className="graphheading mb-0 mt-1">List View</h3>
						  <button className="btn btn-md btn-primary" type="submit" onClick={clearFilterList} >Clear Filters</button>
                        </div>
                      <div className="row">
                        <div className="col-lg-12 d-flex  justify-content-end">

                          <select value={sortvalue} className="form-select" style={{ width: "190px" }} onChange={HandleSort} >
                            <option value="">Sort By</option>
                            <option value="ASCDATE">Date Ascending</option>
                            <option value="DESCDATE">Date Descending</option>
                            <option value="ASCCOUNT">Total Save Ascending</option>
                            <option value="DESCCOUNT">Total Save Descending</option>



                          </select>

                          <div><label className="mt-1 ">&nbsp;&nbsp;Show&nbsp;</label></div>
                          <div><select value={limitvalue} className="form-select" style={{ width: "85px" }} onChange={HandleLimitValue}>
                            {/* <option value="5">5</option> */}
                            <option value="10">10</option>
                            <option value="20">20</option>
                            <option value="30">30</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                          </select>
                          </div>
                          <div>
                            &nbsp; &nbsp;
                            <button className="btn btn-primary " onClick={ExportEcelFile}>
                              <em className="icon ni ni-download-cloud"></em><span>Export</span>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row pb-2">
                      <div className="col-lg-3">
                        <div className="form-control border-0 px-0">
                          <label>
                            <strong>Customer Name</strong>
                          </label>

                          {/* <input type="date" name ="formdate"  className="form-control" value={selectedfromdate} onChange={HandleFromDate} /> */}
                          <div className="form-control-wrap paymetsession spacereport">
                             <i className="fa fa-chevron-down chevron-downcustome" onClick={displaySelect}></i> 
                            <Select
                            className="form-select"
                              defaultvalue={userIdList}
                              options={userNameData}
                              openMenuOnFocus={displayvalueSelect}
                              onChange={HandleUserName}
							  ref={selectRef}
                            />
                          </div>
                        </div>
                        {/* <span className="error">{toDateError}</span> */}
                      </div>
                      <div className="col-lg-3">
                        <div className="form-control border-0 px-0">
                          <label>
                            <strong>Student Name</strong>
                          </label>

                         
                          <div className="form-control-wrap">

                            {/* <Select
                            className="form-select"
                              defaultvalue={childName}
                              options={childNameData}

                              onChange={HandleChildName}
                            /> */}

<select  className="form-select pl-2" onChange={HandleChildName} value={childId} >
<option value="">Select Student Name</option>
{
   
  childNameData.map((val)=>{
    return(
      <option value={val.id}>{val.firstName + " " + val.lastName}</option>
    )
  })
}
 
</select>

                          </div>
                        </div>
                      
                      </div>
                      <div className="col-lg-3">
                        <div className="form-control border-0 px-0">
                          <label>
                            <strong>Saved Type</strong>
                          </label>

                          {/* <input type="date" name ="formdate"  className="form-control" value={selectedfromdate} onChange={HandleFromDate} /> */}
                          <div className="form-control-wrap">

                            <select className="form-select pl-2" value={savedtype} onChange={HandleSaveType} >

                              <option value="">Select</option>
                              <option value="self">Self Created</option>
                              <option value="other">Others</option>

                            </select>
                          </div>
                        </div>
                        {/* <span className="error">{toDateError}</span> */}
                      </div>
                      <div className="col-lg-3">
                        <div className="form-control border-0 px-0">


                          {/* <input type="date" name ="formdate"  className="form-control" value={selectedfromdate} onChange={HandleFromDate} /> */}
                          <div className="form-control-wrap paymetsession resourcetype">

                            <label><strong>Resource Type</strong></label>
                            <select className="form-select" name="resourceTypes" onChange={HandleResourceTypeList} value={resourcetypes} >
                              <option value=""> Select Resource Type</option>
                              {
                                getAllResourceNew.map((val, index) => {
                                  return (<>
                                    <option value={val.id} key={index + 1}>{val.name}</option>

                                  </>)
                                })

                              }
                            </select>
                          </div>
                        </div>
                        {/* <span className="error">{toDateError}</span> */}
                      </div>
                      <div className="col-lg-3">
                        <div className="form-control border-0 px-0">
                          <label>
                            <strong>Resource Mode</strong>
                          </label>

                          {/* <input type="date" name ="formdate"  className="form-control" value={selectedfromdate} onChange={HandleFromDate} /> */}
                          <div className="form-control-wrap">

                          <select className="form-select pl-2" value={resourcemode} onChange={handleResourceMode} >

                              <option value="">Select</option>
                              <option value="save">Save</option>
                              <option value="serve">Serve</option>
                            </select>




                          </div>
                        </div>
                        {/* <span className="error">{toDateError}</span> */}
                      </div>
                      <div className="col-lg-3">
                        <div className="form-control border-0 px-0 ">
                          <label>
                            <strong>Start Date</strong>
                          </label>
                          {/* <input type="date" name="formdate" className="form-control" onChange={HandleFromDate} /> */}

                          <div className="form-control-wrap datesaved"  ref={newRef}>
                            <div className="form-icon form-icon-left">
                              <span onClick={() => handleclickdatepickericon()}>
                                <Icon name="calendar"></Icon>
                              </span>
                            </div>
                            <DatePicker
                              placeholderText="MM/DD/YYYY"
                              selected={selectedfromdate}
                              onChange={HandleFromDate}
                              onFocus={() => setDatePickerShow(!datepickershow)}
                              className="form-control date-picker"
                              open={datepickershow}
                            />
                          </div>
                        </div>
                        <span className="error">{formDateError}</span>
                      </div>
                      <div className="col-lg-3">
                        <div className="form-control border-0 px-0">
                          <label>
                            <strong>End Date</strong>
                          </label>
                          {/* <input type="date" name="formdate" className="form-control" onChange={HandleFromDate} /> */}

                          <div className="form-control-wrap datesaved"  ref={newRef1}>
                            <div className="form-icon form-icon-left">
                              <span onClick={() => handleclickdatepickericon1()}>
                                <Icon name="calendar"></Icon>
                              </span>
                            </div>
                            <DatePicker
                              placeholderText="MM/DD/YYYY"
                              selected={selectedtodate}
                              onChange={HandleToDate}
                              onFocus={() => setDatePickerShow1(!datepickershow1)}
                              className="form-control date-picker"
                              open={datepickershow1}
                            />
                          </div>
                        </div>
                        <span className="error">{toDateError}</span>
                      </div>
					  
					   <div className="col-lg-3">
             
					  <div className="form-control border-0 px-0">
					  <label className="w-100 mb-0">&nbsp;</label>
                        <button className="btn btn-primary" type="submit" onClick={handlesearch}>
                          {" "}
                          Search{" "} 
                        </button>
                      </div>
                    </div>

                    </div>
                      

                      </div>
                
                    
                    <div className="relativeClass newregisterGrouptable">
                      {loader1 ? (
                        <div className="overflowloader" id="preload">
                          <div className="circle-square" >
                            <div className="red" style={{ background: "#AAC0CE" }}></div>
                            <div className="blue" style={{ background: "#265472" }}></div>
                            <div className="green" style={{ background: "#F19357" }}></div>
                            <div className="yellow" style={{ background: "#F0B285" }}></div>
                          </div>
                        </div>
                      ) : ("")}

                      {customerstoredreport && customerstoredreport?.length > 0 ? (
                        <div>

                        {customerstoredreport?.some(obj=>Object.keys(obj.tbl_user_resource).includes("tbl_resource_type_master"))? 
                          (
                          <div className="color">
                            <ReactDataTable
                              data={customerstoredreport}
                              columns={columnsshow}
                              className="nk-tb-list"
                            />
                          </div>)
                          :
                          (
                          <div className="color">
                            <ReactDataTable
                              data={customerstoredreport}
                              columns={columns}
                              className="nk-tb-list"
                            />
                            </div>)
                          }
                        </div>
                      ) : <p className="p-4" style={{ minHeight: "100px" }}>No Data Found</p>}
                    </div>
                    <div className="p-4">  
                    {customerstoredreport?.length > 0 ?
                      <div>
                        <ReactPaginate
                          previousLabel={"<<"}
                          nextLabel={">>"}
                          pageCount={count}
                          pageRangeDisplayed={5}
                          marginPagesDisplayed={2}
                          onPageChange={handlePageChange}
                          containerClassName={'pagination'}
                          pageClassName={"page-item"}
                          activeClassName={'active'}
                          pageLinkClassName={"page-link"}
                          previousClassName={"page-item"}
                          previousLinkClassName={"page-link"}
                          nextClassName={"page-item"}
                          nextLinkClassName={"page-link"}
                          breakClassName={"page-item"}
                          breakLinkClassName={"page-link"}
                          forcePage={currentPage - 1}
                        />
                      </div>
                      : ""}
                       </div>
                  </BlockHead>
                </BlockHeadContent>
              </div>

            </div>
          </Card>
        </>
        {/* )} */}
      </Content>
    </React.Fragment>
  );
}
export default CustomerStoredReport;
