import React, { useState, useRef } from "react";
import Head from "../../../layout/head/Head";
import Content from "../../../layout/content/Content";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import { useEffect } from "react";
import { URLS } from "../../../constants/urls";
import { BlockHead, BlockHeadContent } from "../../../components/Component";
import { DropdownItem, UncontrolledDropdown, DropdownMenu, DropdownToggle, CardTitle } from "reactstrap";
import {
  Block,
  BlockTitle,
  BlockBetween,
  BlockDes,
  Icon,
  Row,
  Col,
  Button,
  DataTableHead,
  DataTableBody,
  DataTableRow,
  DataTableItem,
  PaginationComponent,
  ReactDataTable,
  UserAvatar,
  PreviewCard,
} from "../../../components/Component";
import { Link } from "react-router-dom";
import { Card, Badge } from "reactstrap";
import { useHistory } from "react-router-dom";

import { makePostRequest, makeGetRequest } from "../../../service/api";
import ReactPaginate from "react-paginate";
import { AreaChart, Area, BarChart, Bar, ResponsiveContainer, CartesianGrid, XAxis, YAxis, Tooltip, Legend, Label, LineChart, Line, LabelList } from "recharts";
import moment from "moment";
import DatePicker from "react-datepicker";
import Select from "react-select";
import { parseBusinessHours } from "@fullcalendar/core";
import Highcharts, { chart } from "highcharts";
import { findUpper, capital } from "../../../utils/Utils";
import { Modal, ModalFooter, ModalHeader } from "reactstrap";
import HighchartsReact from 'highcharts-react-official';
// import exporting from "highcharts/modules/exporting";
import { ToastContainer, toast } from "react-toastify";

// exporting(Highcharts);

const ExcelJS = require('exceljs');

function GroupClickReport() {

  let history = useHistory();
  const [isOpen, setIsOpen] = useState(false);
  const [activeTab, setActiveTab] = useState("1");
  const [activeIconTab, setActiveIconTab] = useState("5");
  const [activeAltTab, setActiveAltTab] = useState("9");
  //   const [verticalTab, setVerticalTab] = useState("1");
  const [verticalIconTab, setVerticalIconTab] = useState("1");
  const [groupClickData, setGroupClickData] = useState([]);
  const [groupClickGraphData, setGroupClickGraphData] = useState([]);
  const [loader, setLoader] = useState(false);
  const [loader1, setLoader1] = useState(false);
  const [selectyear, setSelectyear] = useState(new Date().getFullYear());
  const [displayvalue, setDisplayValue] = useState("1");
  const [startdate, setStartDate] = useState("2023-01-01");
  const [enddate, setEndDate] = useState(new Date());
  const [selectedfromdate, setSelectedFromDate] = useState(null);
  const [selectedtodate, setSelectedToDate] = useState(null);
  const [user, setUser] = useState("");
  const [limitvalue, setlimitvalue] = useState("")
  const [sortvalue, setSortValue] = useState("")
  const [showchart, setshowchart] = useState([])
  const [resourceTypeText, setResourceTypeText] = useState("");
  const [datepickershow, setDatePickerShow] = useState(false);
  const [datepickershow1, setDatePickerShow1] = useState(false);
  const [toDateError, settoDateError] = useState("");
  const [formDateError, setformDateError] = useState("");
  const [customerSpaceData, setCustomerSpaceData] = useState([])
  const [totalCount,setTotalCount]=useState("");
  const [zipCode,setZipCode]=useState("");
  const [groupreportModal, setGroupreportModal] = useState([])

  const year = 2023;
  const curryear = new Date().getFullYear();
  let years = Array.from(new Array(curryear - 2023 + 2), (val, index) => index + year);
  const colorThemes = ["primary", "purple", "danger", "warning", "success", "pink", "secondary", "blue"]
  let chartdata = []
  let SpaceData = []


  useEffect(() => {
    document.body.classList.add("group-click");
    return () => {
        document.body.classList.remove("group-click");
    };
}, []);

  const handleyear = (e) => {
    setSelectyear(e.target.value);
  }


const handleZipCode =(e)=>{
    setZipCode(e.target.value)
}

  const HandleFromDate = (e) => {
    setSelectedFromDate(e);
    setDatePickerShow(!datepickershow);
    setformDateError("");
  }
  //console.log(selectedfromdate, "selectedfromdate")

  const HandleToDate = (e) => {
    setSelectedToDate(e);
    setDatePickerShow1(!datepickershow1);
    settoDateError("");

  }

  const handlechart = (e) => {
    getgroupClickGraphData();
    // setNameUser("");
  }
  const HandleLimitValue = (e) => {

    setlimitvalue(e.target.value)
    setCurrentPage(1)
  }
  const HandleSort = (e) => {
    setSortValue(e.target.value);
    setCurrentPage(1)
  }
  function handleclickdatepickericon() {
    setDatePickerShow(!datepickershow);
  }
  function handleclickdatepickericon1() {
    setDatePickerShow1(!datepickershow1);
  }
  // const openModal = () => {
  //   setIsOpen(!isOpen);
  // };


  const ExportEcelFile = () => {
    setLoader(true);
    let data = {
        start_date: selectedfromdate ? moment(selectedfromdate).format("YYYY-MM-DD") : "",
      end_date: selectedtodate ? moment(selectedtodate).add(1, 'd').format("YYYY-MM-DD") : "",
       export_data: "no",
       limit:limitvalue ? parseInt(limitvalue) : 10,
      page: currentPage ? currentPage : 1,
     resource_id:resourceValue ? resourceValue :"",
    group_id:groupName ? groupName :"",
    zip_code:zipCode ? zipCode :"",
     
    };

    makePostRequest(URLS.GROUP_DATA_LIST, null, data, null)
      .then((res) => {
        if (res.code == 200) {
          //console.log("Resource export", res.data.data)
          const workbook = new ExcelJS.Workbook();
          const sheet = workbook.addWorksheet("GroupClickdata");
          sheet.properties.defaultRowHeight = 30;

          sheet.getRow(1).font = {
            size: 10,
            bold: true,
          }

          sheet.columns = [
            {
              header: "S.No",
              key: 'S_No',
              width: 5
            },

            // {
            //   header: "Customer Name",
            //   key: 'USER_NAME',
            //   width: 30
            // },
            {
              header: "Date Click",
              key: 'saveddate',
              width: 30
            },

            // {
            //   header: "Email",
            //   key: 'OWNEREMAIL',
            //   width: 30
            // },
            // {
            //   header: "Phone",
            //   key: 'OWNWERPHONE',
            //   width: 30
            // },

            {
              header: "Resource Name",
              key: 'Resource_Name',
              width: 20
            },
            {
                header: "Group Name",
                key: 'group_name',
                width: 20
              },
              {
                header: "ZipCode",
                key: 'zipcode',
                width: 20
              },
              {
                header: "Click Count",
                key: 'click',
                width: 20
              },


          ];
          res.data.data?.map((val, i) => {
            sheet.addRow({
              S_No: (i + 1),
              saveddate: val?.created_at ? moment(val?.created_at).format("MM-DD-YYYY") : "--",
             // USER_NAME: val?.tbl_user?.firstName + " " + val.tbl_user?.lastName,
             // OWNEREMAIL: val?.tbl_user?.email,
             // OWNWERPHONE: val?.tbl_user?.phone,
              Resource_Name: val?.tbl_user_resource?.resource_title,
              group_name: val.tbl_group?.group_name,
              zipcode:val?.tbl_user?.zipcode,
              click:val?.total_group_click_count,


            })
          })
          workbook.xlsx.writeBuffer().then(data => {
            const blob = new Blob([data], {
              type: "application/vnd.openxmlformats-officedocument.spreadsheet.sheet",
            })
            const url = window.URL.createObjectURL(blob);
            const anchor = document.createElement('a');
            anchor.href = url;
            anchor.download = 'GroupClickReport.xlsx';
            anchor.click();
            window.URL.revokeObjectURL(url)
          })
          setLoader(false);
         // console.log(res.data, "res g")

        }
      })
      .catch((error) => {
        console.log(error);
      });

  }

  const [clearStateGraph, setClearStateGraph] = useState(false);
  const [clearStateList, setClearStateList] = useState(false);
  /*========================= Resource Type Api Calling ============================*/
const [groupType,setGroupType]=useState("");
const [resourceTypeGraph,setResourceTypeGraph]=useState("");

const [resourceTypedata,setResourceTypeData]=useState([]);
const [resourceValue,setResourceValue]=useState("");
const [resourceValueGraph,setResourceValueGraph]=useState("");

const [resourceUserName,setResourceUserName]=useState("");
const [userNameData,setUserNameData]=useState([]);
const [resourceUserNameGraph,setResourceUserNameGraph]=useState("");

const [groupName,setGroupName]=useState("");
const [groupData,setGroupData]=useState([]);
const [groupNameGraph,setGroupNameGraph]=useState("");

// console.log(resourceUserName,"user",groupName,"group",groupType,"groupType",resourceValue,"resource")
/*=======================Get Resource Api=============================*/

    const getAllResourceType = () => {
      let data=
        {
          "item_per_page":20,
           "page_number":1, 
           "group_id":groupName,
      }
     console.log(data,"dataresource")
    // setLoader(true);
    makePostRequest(URLS.GET_GROUP_RESOURCE,null, data, null)
      .then(res => {
        console.log(res,"resourcedata");
        if (res.code == 200) {
          setResourceTypeData(res.data.data);
          // setLoader(false);
        }
      })
      .catch((error) => {
        console.log(error);
      })

  }

  useEffect(()=>{
    getAllResourceType();
     },[groupName])



     const getAllResourceTypeGraph = () => {
        let data=
          {
            "item_per_page":20,
             "page_number":1, 
             "group_id":groupNameGraph,
        }
       console.log(data,"dataresourcegraph")
      // setLoader(true);
      makePostRequest(URLS.GET_GROUP_RESOURCE,null, data, null)
        .then(res => {
          console.log(res,"resourcedataGraph");
          if (res.code == 200) {
            setResourceTypeData(res.data.data);
            // setLoader(false);
          }
        })
        .catch((error) => {
          console.log(error);
        })
  
    }
  
    useEffect(()=>{
      getAllResourceTypeGraph();
       },[groupNameGraph])
     

  /*=======================Get User Api=============================*/

  const getAllUser = () => {
    let data=
      {
        // "item_per_page":20,
        // "page_number":1,
        resource_type_status:"individual",
    }
   
  setLoader(true);
  makePostRequest(URLS.GETALL_GROUP_OR_USER,null, data, null)
    .then(res => {
      console.log(res,"userdata");
      if (res.code == 200) {
        setUserNameData(res.data.data);
        setLoader(false);
      }
    })
    .catch((error) => {
      console.log(error);
    })

}



/*=======================Get Group Api=============================*/

const getAllGroup = () => {
  let data=
    {
      "item_per_page":20,
      "page_number":1,
      
  }
 
setLoader(true);
makePostRequest(URLS.GET_GROUPCLICK_LIST,null, data, null)
  .then(res => {
    console.log(res,"Groupdata");
    if (res.code == 200) {
      setGroupData(res.data.data);
      setLoader(false);
    }
  })
  .catch((error) => {
    console.log(error);
  })

}

// const handleMenuScroll = (event) => {
//   const { target } = event;
//   if (target.scrollTop === target.scrollHeight - target.clientHeight) {
//     viewGroupClickDetailss();
//   }
//   console.log(event,"scroll")
// };

// const [dropdownPage, setDropdownPage] = useState(1)

const viewGroupClickDetailss = (row) => {

  let data = {
    "resource_id":row.tbl_user_resource.id,
    "start_date":row.created_at,
    "end_date":row.created_at,
    "zip_code": zipCode ? row.tbl_user.zipcode :"",
    "page":1,
    "limit":30

  }
 console.log("group details", data)
  makePostRequest(URLS.GET_GROUP_DETAIL, null, data, null)
    .then((res) => {
      if (res.code == 200) {
        console.log("GroupDetails ", res)
        // setGroupreportModal((prevOptions) => [...prevOptions, ...res?.data?.data])
        setGroupreportModal(res?.data?.data)
        //  setDropdownPage(dropdownPage + 1);
        setIsOpen(!isOpen);
      }
      else {
        toast.error(res.message)
      }


    })
}


// console.log(groupType,"gtype")
const handleResourceGroupTypeList =(e)=>{
  setGroupType(e.target.value)

//   console.log(e.target.value,"value")

  if(e.target.value==="group"){
    setResourceUserName("")
    setResourceValue("")
  }

  if(e.target.value==="individual"){
    setGroupName("")
    setResourceValue("")
  }
  if (e.target.value === "") {
    setGroupName("");
    setResourceUserName("");
    setResourceValue("");
    
  }
 
  
}
const handleResourceTypeGraph=(e)=>{
  setResourceTypeGraph(e.target.value)
  if(e.target.value==="group"){
    setResourceUserNameGraph("")
    setResourceValueGraph("")
  }

  if(e.target.value==="individual"){
    setGroupNameGraph("")
    setResourceValueGraph("")
  }
  if (e.target.value === "") {
    setResourceUserNameGraph("")
    setResourceValueGraph("")
    setGroupNameGraph("")
    
  }
}


  const handleResourceTypeList=(e)=>{
    setResourceValue(e.target.value)
  }

  const handleResourceTypeListGraph=(e)=>{
    setResourceValueGraph(e.target.value)
  }
  const handleResourceUserListGraph=(e)=>{
    setResourceUserNameGraph(e.target.value)
  }
  const handleResourceGroupListGraph=(e)=>{
    setGroupNameGraph(e.target.value)
	
  }

  const handleResourceUserList=(e)=>{
    setResourceUserName(e.target.value)
  }

  const handleResourceGroupList=(e)=>{
    setGroupName(e.target.value)
  }


useEffect(()=>{
  getAllResourceType();
  getAllUser();
  getAllGroup();
},[])



//   const getAllUserType = () => {
//     setLoader(true);
//     makeGetRequest(URLS.GET_ALL_RESOURCE_TYPE, {}, null)
//       .then(res => {
//         if (res.code == 200) {
//           setgetAllResourceNew(res.data);
//           setLoader(false);
//         }
//       })
//       .catch((error) => {
//         console.log(error);
//       })

//   }

  const openModal = () => {
    setIsOpen(!isOpen);
  };

  
  const handlesearch = (e) => {
    
    if (selectedfromdate != null && selectedtodate == null) {
      settoDateError("please select valid end date")
    }
    else if (selectedtodate != null && selectedfromdate == null) {
      setformDateError("please select valid start date ")
    }
    else if (selectedfromdate > selectedtodate) {
      toast.error("end date should be greater than start date")
    }
    else {

      getGroupClickListDataFunction();
      setCurrentPage(1)
    }
  }

  const getgroupClickGraphData = () => {
    setLoader(true);
    let data = {
      year: selectyear,
      group_id:groupNameGraph ? groupNameGraph :"",
      resource_id:resourceValueGraph ? resourceValueGraph :"",

    };
    console.log("data chart:::", data)
    makePostRequest(URLS.GROUP_DATA_GRAPH, null, data, null)
      .then((res) => {
        // setLoader(true)
        if (res.code === 200) {
          console.log(res, "GroupClick graph res");
          setGroupClickGraphData(res?.data?.graph_data);
          setLoader(false);

        } else {
          setLoader(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }


  /*==============================Get Group click data ==============================*/

  const [currentPage, setCurrentPage] = useState(1);
  const [count, setCount] = useState();

  const getGroupClickListDataFunction = () => {

    setLoader1(true);
    let data = {
      start_date: selectedfromdate ? moment(selectedfromdate).format("YYYY-MM-DD") : "",
      end_date: selectedtodate ? moment(selectedtodate).add(1, 'd').format("YYYY-MM-DD") : "",
       export_data: "no",
       limit:limitvalue ? parseInt(limitvalue) : 10,
      page: currentPage ? currentPage : 1,
     resource_id:resourceValue ? resourceValue :"",
    group_id:groupName ? groupName :"",
    zip_code:zipCode ? zipCode :"",
   
    };
    console.log(data, "data group click")
    makePostRequest(URLS.GROUP_DATA_LIST, null, data, null)
      .then((res) => {
        // setLoader(true)
        if (res.code === 200) {
          console.log(res, "GroupClick  data list");
          setGroupClickData(res?.data.data);
          setCount(Math.ceil(res.data.count / parseInt(limitvalue ? limitvalue : 10)))
          setLoader1(false);
          setTotalDataAdd(res.data.count)

        } else {
          setLoader1(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
    // setNameUser("");
  }

  const [totalDataAdd, setTotalDataAdd] = useState("")
  const getGroupTotalData = () => {

    let data = {
      start_date: selectedfromdate ? moment(selectedfromdate).format("YYYY-MM-DD") : "",
      end_date: selectedtodate ? moment(selectedtodate).add(1, 'd').format("YYYY-MM-DD") : "",
       export_data: "yes",
       limit:limitvalue ? parseInt(limitvalue) : 10,
      page: currentPage ? currentPage : 1,
     resource_id:resourceValue ? resourceValue :"",
    group_id:groupName ? groupName :"",
    zip_code:zipCode ? zipCode :"",
     
    };
    //console.log(data, "data")
    makePostRequest(URLS.GROUP_DATA_LIST, null, data, null)
      .then((res) => {
        // setLoader(true)
        if (res.code === 200) {
      
          // setTotalDataAdd(res.data.count)


        } else {
          setLoader1(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });

  }
  console.log(totalDataAdd,"count")

  useEffect(() => {
    getGroupTotalData();
  }, [])

// let sum=0;

// totalDataAdd.forEach((val)=>{
//    sum +=val?.total_group_click_count
//    ; 
//   })



  const handlePageChange = (selectedPage) => {
    setCurrentPage(selectedPage.selected + 1)
  };
  // const fetchData = () => {
  //   let data = {
  //       start_date: selectedfromdate ? moment(selectedfromdate).format("YYYY-MM-DD") : "",
  //       end_date: selectedtodate ? moment(selectedtodate).add(1, 'd').format("YYYY-MM-DD") : "",
  //        export_data: "no",
  //        limit:limitvalue ? parseInt(limitvalue) : 10,
  //       page: currentPage ? currentPage : 1,
  //      resource_id:resourceValue ? resourceValue :"",
  //     group_id:groupName ? groupName :"",
  //     zip_code:zipCode ? zipCode :"",
  //   }
  //   makePostRequest(URLS.GROUP_DATA_LIST, null, data, null)
  //     .then((res) => {
  //       if (res.code == 200) {
  //         setGroupClickData(res?.data.data);
  //         setCount(Math.ceil(res.data.count / parseInt(limitvalue ? limitvalue : 10)))
  //       }
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // };
   const clearFilterGraph = () => {
    clearFilterGraphFun();
  }

  const clearFilterGraphFun = () => {
    setGroupNameGraph("");
	setSelectyear("");
    setClearStateGraph(!clearStateGraph);
    
  }
   const clearFilterList = () => {
    clearFilterListFun();
  }
  const clearFilterListFun = () => {

    selectRef?.current?.select?.setValue("", "select-option");
    setSelectedFromDate("");
    setSelectedToDate("");
	    setZipCode("");
      setResourceValue("")
      setGroupName("")
    setClearStateList(!clearStateList);
    getGroupClickListDataFunction();
  }

  useEffect(() => {
    getGroupClickListDataFunction();
  }, [currentPage, limitvalue, sortvalue])


  useEffect(() => {
    getGroupClickListDataFunction();
    getgroupClickGraphData();
  }, []);


  useEffect(() => {
   getgroupClickGraphData();
  }, [clearStateGraph]);

 useEffect(() => {
  getGroupClickListDataFunction();
  getGroupTotalData();
  }, [clearStateList]);

  useEffect(() => {
    getchartdata();
  }, [groupClickGraphData])

  const getchartdata = () => {
    groupClickGraphData?.map((val) => {
      chartdata.push(val?.tbl_group_resource_click)

    })
    setshowchart(chartdata)
  }


  const columns = [
    {
      name: "#",
      selector: (row) => row.id,
      grow: 1,
      minWidth: "50px",
      style: { minWidth: "50px" },
      borderRadius: "4px",
      cell: (row, index) => (
        <>
          <div className="user-card mt-2 mb-2"> {currentPage==1?(index + 1):((currentPage-1)*(limitvalue!=""?parseInt(limitvalue):10))+(index + 1)} </div>
        </>
      ),
    },
    {
        name: "Click Date",
        selector: (row) => "",
        grow: 4,
  
        cell: (row, index) => (
          <>
            <div className="user-card mt-2 mb-2">
            <span className="tb-lead">
              {row?.created_at ? moment(row?.created_at).format("MM-DD-YYYY") : "--"}
              </span>
            </div>
          </>
        ),
      },
      {
        name: "Resource Name",
        selector: (row) => row?.month,
        grow: 4,
  
        cell: (row, index) => (
          <>
            <div className="user-card mt-2 mb-2">
              <div className="user-info">
                <span className="sub-text">
                  {row?.tbl_user_resource?.resource_title ? capital(row?.tbl_user_resource?.resource_title.trim()) : "--"}
                 &nbsp; <Link to={`/view-resource/${row?.tbl_user_resource?.id}`}>  <button className="dropdown-item btn btn-md" >
              <em className="icon ni ni-eye"></em>
            </button>
            </Link>
                </span>
                
           
                     
            

              </div>
            </div>
          </>
        ),
      },
  
      {
        name: "Group Name",
        selector: (row) => row?.month,
        grow: 4,
        cell: (row, index) => (
          <>
            <div className="user-card mt-2 mb-2">
              <div className="user-info">    
   <span className="sub-text">
                  { row?.tbl_group ? capital(row?.tbl_group?.group_name.trim() ) : "--"}
                  &nbsp;<Link to={`/view-groupdetail/${row?.tbl_group?.id}`}>  <button className="dropdown-item btn btn-md" >
              <em className="icon ni ni-eye"></em>
            </button>
            </Link>
                </span>
              </div>
            </div>
          </>
        ),
      },
  //     {
  //       name: "ZipCode",
  //       selector: (row) => "",
  //       grow: 4,
  //       cell: (row, index) => (
  //         <>
  //           <div className="user-card mt-2 mb-2">
  //             <div className="user-info">
  //  <span className="tb-lead">
  //                 {row?.tbl_user ? (row?.tbl_user?.zipcode) : "--"}
  //               </span>
  //             </div>
  //           </div>
  //         </>
  //       ),
  //     },
      
      {
        name: "Click Count",
        selector: (row) => row?.tbl_user,
        grow:4,
        cell: (row, index) => (
          <>
           
            <div className="user-card user-cardCenter mt-2 mb-2">
              <div className="user-info">
               
                <span className="tb-lead">
                  {row?.total_group_click_count? (row?.total_group_click_count ) : "--"}
                </span>
               
               
              </div>
            </div>
          </>
        ),
      },

      {
        name: "View Details",
  
        grow: 2,
        center: true,
        sortable: true,
        cell: (row) => (
          <div className="user-card mt-2 mb-2">  <span><button
            className="btn btn-md"
            onClick={() => {
              viewGroupClickDetailss(row);
            }}
          >
            <em className="icon ni ni-eye"></em>
          </button>
          </span> </div>
        ),
      },

   
   
  ]
  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };
  const toggleIconTab = (icontab) => {
    if (activeIconTab !== icontab) setActiveIconTab(icontab);
  };
  const toggleAltTab = (alttab) => {
    if (activeAltTab !== alttab) setActiveAltTab(alttab);
  };
    const newRef = useRef(null);
	const newRef1 = useRef(null);
	const selectRef = useRef(null);
  const handleOutSideClick = (e)=> {
	 if(newRef.current && !newRef.current.contains(e.target)){
         setDatePickerShow(false);
          
	 }		 
  }
  const handleOutSideClick1 = (e)=> {
	 if(newRef1.current && !newRef1.current.contains(e.target)){
            setDatePickerShow1(false);		 
	 }		 
  }
  useEffect(()=>{
	 document.addEventListener("mousedown", handleOutSideClick );
	 document.addEventListener("mousedown", handleOutSideClick1 );
      return ()=> {
       document.removeEventListener("mousedown", handleOutSideClick );
	    document.removeEventListener("mousedown", handleOutSideClick1 );
	  }	   
  });
  return (

    <React.Fragment>


      <Head title="Group Click Report"></Head>
      <Content>

        <h3 className="nk-block-title page-title mb5 pb-4">Group Click Report</h3>
        <>
          <Card className="card-bordered">
          <Modal isOpen={isOpen} toggle={openModal}>
              <ModalHeader
                toggle={openModal}
                close={
                  <button className="close" onClick={openModal}>
                    <Icon name="cross" />
                  </button>
                }
              >
              Click Details({groupreportModal && groupreportModal.length> 0 && groupreportModal[0]?.tbl_user_resource?.resource_title})
              </ModalHeader>
              <Card className="card-bordered">
                <div className="card-inner">
                  <BlockHeadContent>
                    <BlockHead size="lg">
                      <div className="customerDetailsTable"  > {/* onScroll={handleMenuScroll} */}
                        <table width="100%" >
                          <tr className="border-bottom">
                          <th>#</th>
                            <th>Click Date/Time </th>
                            
                            <th>Customer Name</th>
                           
                            <th>Zip Code</th>
                            {/* <th>Group Name</th> */}
                            <th>Address</th>

                          </tr>
                          {groupreportModal && groupreportModal.map((val, i) => {
                            return (
                              <tr className="border-bottom">
                                <td>{i + 1}</td>
                                <td>{moment(val?.created_at).format("MM-DD-YYYY hh:mm a")}</td>
                              
                          <td>{capital(val?.tbl_user?.firstName.trim())}{" "}{capital(val?.tbl_user?.lastName.trim())}</td>
                        
                          <td>{val?.tbl_user.zipcode}</td> 

                          {/* <td>{val?.tbl_group?.group_name}</td>  */}
                          <td>{val?.tbl_user?.address}</td>


                              </tr>
                            )

                          })}

                        </table>
                      </div>
                    </BlockHead>
                  </BlockHeadContent>
                </div>
              </Card>
              <ModalFooter className=""></ModalFooter>
            </Modal>
            <div className="card-inners relativeClass">






              <div className="card-inners relativeClass">
                <BlockHeadContent>
                  <BlockHead size="lg">
                  <div className="p-4">
                   <div className="filtersidebutton">
                      <h3 className="graphheading  mb-0 mt-1">Graph View</h3>
					  <button className="btn btn-md btn-primary" type="submit" onClick={clearFilterGraph}>Clear Filters</button>
                    </div>
                   
                    <div className="row">
                      <div className="col-lg-3">
                        <div className="form-control border-0 px-0">
                          <label>
                            <strong>Select Year</strong>
                          </label>

                          <div className="form-control-wrap">
                            <select className="form-select" value={selectyear} onChange={handleyear} >
                              {
                                years.map((year, index) => {
                                  return <option key={`year${index}`} value={year}>{year}</option>
                                })
                              }
                            </select>

                          </div>
                        </div>
                      </div>
                     
                      {/* <div className="col-lg-3">
                        <div className="form-control border-0 px-0" style={{ marginBottom: "10px" }}>
                          <label><strong>Group</strong></label>
                          <select className="form-select" name="resourceType" onChange={handleResourceTypeGraph} value={resourceTypeGraph} >
                            <option value=""> Select... </option>
                            <option value="group"> Group </option>
                            <option value="individual"> Individual </option>
                           
                          </select>
                        </div>
                      </div> */}

                      {/* {resourceTypeGraph=="individual" ? 
                      <div className="col-lg-3">
                        <div className="form-control border-0 px-0" style={{ marginBottom: "10px" }}>
                          <label><strong>User Name</strong></label>
                          <select className="form-select" name="resourceType" onChange={handleResourceUserListGraph} value={resourceUserNameGraph} >
                            <option value=""> All User </option>
                            {
                              userNameData.map((val, index) => {
                                return (<>
                                  <option value={val?.tbl_user_resource?.tbl_user?.id} key={index + 1}>{val?.tbl_user_resource?.tbl_user?.firstName +" "+ val?.tbl_user_resource?.tbl_user?.lastName}</option>

                                </>)
                              })

                            }
                          </select>
                        </div>
                      </div> 
:""} */}


                      

                      <div className="col-lg-3">
                        <div className="form-control border-0 px-0" style={{ marginBottom: "10px" }}>
                          <label><strong>Group Name</strong></label>
                          <select className="form-select" name="resourceType" onChange={handleResourceGroupListGraph} value={groupNameGraph} >
                            <option value=""> All Group </option>
                            {
                              groupData.map((val, index) => {
                                return (<>
                                  <option value={val?.tbl_group?.id} key={index + 1}>{capital(val?.tbl_group?.group_name.trim())}</option>

                                </>)
                              })

                            }
                          </select>
                        </div>
                      </div> 
                      

{ groupNameGraph ? 
<div className="col-lg-3">
                        <div className="form-control border-0 px-0" style={{ marginBottom: "10px" }}>
                          <label><strong>Resource Title</strong></label>
                          <select className="form-select" name="resourceType" onChange={handleResourceTypeListGraph} value={resourceValueGraph} >
                            <option value="">All Resource </option>
                            {
                              resourceTypedata.map((val, index) => {
                                return (<>
                                  <option value={val?.tbl_user_resource?.id} key={index + 1}>{capital(val?.tbl_user_resource?.resource_title.trim())}</option>

                                </>)
                              })

                            }
                          </select>
                        </div>
                      </div>
                      :""}
                      
                      <div className="col-lg-3">
                        <div className="form-control border-0 px-0">
						<label className="w-100 mb-0">&nbsp;</label>
                          <button className="btn btn-primary" type="submit" onClick={handlechart}>
                            {" "}
                            View{" "}
                          </button>

                        </div>
                      </div>

                     </div>
                    </div>


                    <div className="p-5">
                      <div className="row">
                        <div className="col-lg-6">

                        </div>

                      </div>
                      <div className="relativeClass">
                        {loader ? (
                          <div className="overflowloader" id="preload">
                            <div className="circle-square" >
                              <div className="red" style={{ background: "#AAC0CE" }}></div>
                              <div className="blue" style={{ background: "#265472" }}></div>
                              <div className="green" style={{ background: "#F19357" }}></div>
                              <div className="yellow" style={{ background: "#F0B285" }}></div>
                            </div>
                          </div>
                        ) : (
                          <HighchartsReact
                            highcharts={Highcharts}
                            options={{
                              chart: {
                                type: 'line'
                              },
                              title: {
                                text: 'Group Click Data Chart',
                                style: { "color": "#003C71", "fontSize": "25px" }
                              },

                              xAxis: {
                                categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
                                labels: {
                                  style: {
                                    color: '#364a63'
                                  }
                                }
                              },
                              plotOptions: {
                                line: {
                                  dataLabels: {
                                    enabled: true,
                                    formatter: function () {
                                      return (this.y != 0) ? this.y : "";
                                    }
                                  },
                                  enableMouseTracking: true
                                }
                              },
                              credits: {
                                enabled: false
                              },

                              yAxis: {
                                title: {
                                  text: 'Group click data',
                                  style: {
                                    color: '#364a63'
                                  }
                                },
                                labels: {
                                  style: {
                                    color: '#364a63'
                                  }
                                }


                              },
                              series: [{
                                name: "Month",
                                data: showchart,
                                color: "rgb(38, 84, 114)"


                              }]
                            }}
                          />
                        )}
                      </div>
                    </div>
                    <div className="p-4">
                    <div className="d-flex justify-content-between mb-2">
                     <div className="filtersidebutton">
                          <h3 className="graphheading mb-0 mt-1">List View</h3>
						  <button className="btn btn-md btn-primary" type="submit" onClick={clearFilterList}>Clear Filters</button>
                        </div>
                      <div className="row">
                        <div className="col-lg-12 d-flex  justify-content-end">

                          <div><label className="mt-1 ">&nbsp;&nbsp;Show&nbsp;</label></div>
                          <div><select value={limitvalue} className="form-select" style={{ width: "85px" }} onChange={HandleLimitValue}>
                            <option value="10">10</option>
                            <option value="20">20</option>
                            <option value="30">30</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                          </select>
                          </div>
                          <div>
                            &nbsp; &nbsp;
                            <button className="btn btn-primary " onClick={ExportEcelFile}>
                              <em className="icon ni ni-download-cloud"></em><span>Export</span>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row pb-2">
                      <div className="col-lg-3">
                        <div className="form-control border-0 px-0">
                          <label>
                            <strong>Start Date</strong>
                          </label>
                          {/* <input type="date" name="formdate" className="form-control" onChange={HandleFromDate} /> */}

                          <div className="form-control-wrap" ref={newRef}>
                            <div className="form-icon form-icon-left">
                              <span onClick={() => handleclickdatepickericon()}>
                                <Icon name="calendar"></Icon>
                              </span>
                            </div>
                            <DatePicker
                              placeholderText="MM/DD/YYYY"
                              selected={selectedfromdate}
                              onChange={HandleFromDate}
                              onFocus={() => setDatePickerShow(!datepickershow)}
                              className="form-control date-picker"
                              open={datepickershow}
                            />
                          </div>
                        </div>
                        <span className="error">{formDateError}</span>
                      </div>
                      <div className="col-lg-3">
                        <div className="form-control border-0 px-0">
                          <label>
                            <strong>End Date</strong>
                          </label>
                          {/* <input type="date" name="formdate" className="form-control" onChange={HandleFromDate} /> */}

                          <div className="form-control-wrap" ref={newRef1}>
                            <div className="form-icon form-icon-left">
                              <span onClick={() => handleclickdatepickericon1()}>
                                <Icon name="calendar"></Icon>
                              </span>
                            </div>
                            <DatePicker
                              placeholderText="MM/DD/YYYY"
                              selected={selectedtodate}
                              onChange={HandleToDate}
                              onFocus={() => setDatePickerShow1(!datepickershow1)}
                              className="form-control date-picker"
                              open={datepickershow1}
                            />
                          </div>
                        </div>
                        <span className="error">{toDateError}</span>
                      </div>
                       

                       {/* <div className="col-lg-3">
                        <div className="form-control border-0 px-0" style={{ marginBottom: "10px" }}>
                          <label><strong>Group</strong></label>
                          <select className="form-select" name="resourceType" onChange={handleResourceGroupTypeList} value={groupType} >
                            <option value=""> Select... </option>
                            
                                  <option value="group">Group</option>
                                  <option value="individual">Individual</option>

                          </select>
                        </div>
                      </div> */}


                      {/* <div className="col-lg-3">
                        <div className="form-control border-0 px-0" style={{ marginBottom: "10px" }}>
                          <label><strong>User Name</strong></label>
                          <select className="form-select" name="resourceType" onChange={handleResourceUserList} value={resourceUserName} >
                            <option value=""> All User </option>
                            {
                              userNameData.map((val, index) => {
                                return (<>
                                  <option value={val?.tbl_user_resource?.tbl_user?.id} key={index + 1}>{val?.tbl_user_resource?.tbl_user?.firstName +" "+ val?.tbl_user_resource?.tbl_user?.lastName}</option>

                                </>)
                              })

                            }
                          </select>
                        </div>
                      </div>  */}

<div className="col-md-3">
                        <label className="lavelinfo">
                          <strong>Zip Code</strong>
                        </label>
                        <div className="form-control-wrap">

                          <input placeholder="Zipcode" type="text" className="form-control pl-2" value={zipCode} onChange={handleZipCode} />
                        </div>
                      </div>

 
                      <div className="col-lg-3">
                        <div className="form-control border-0 px-0" style={{ marginBottom: "10px" }}>
                          <label><strong>Group Name</strong></label>
                          <select className="form-select" name="resourceType" onChange={handleResourceGroupList} value={groupName} >
                            <option value=""> All Group </option>
                            {
                              groupData.map((val, index) => {
                                return (<>
                                  <option value={val?.tbl_group?.id} key={index + 1}>{capital(val?.tbl_group?.group_name.trim())}</option>

                                </>)
                              })

                            }
                          </select>
                        </div>
                      </div> 

                      

{groupName ? 

<div className="col-lg-3">
   <div className="form-control border-0 px-0" style={{ marginBottom: "10px" }}>
     <label><strong>Resource Title</strong></label>
     <select className="form-select" name="resourceType" onChange={handleResourceTypeList} value={resourceValue} >
       <option value=""> All Resource </option>
       {
         resourceTypedata.map((val, index) => {
           return (<>
             <option value={val?.tbl_user_resource?.id} key={index + 1}>{capital(val?.tbl_user_resource?.resource_title.trim())}</option>

           </>)
         })

       }
     </select>
   </div>
 </div>  
:""}

                    </div>
                    <div className="d-flex flex-row" style={{ gap: "10px" }}>
                      {/* <div className="col-lg-2 d-flex "> */}
                      <div className="outerTypeBox mb-3">
                        <button className="btn btn-primary" type="submit" onClick={handlesearch}>
                          {" "}
                          Search{" "}
                        </button>

    
                      </div>
                    </div>
                    </div>
                    <div className="relativeClass newregisterGrouptable">
                      {loader1 ? (
                        <div className="overflowloader" id="preload">
                          <div className="circle-square">
                            <div className="red" style={{ background: "#AAC0CE" }}></div>
                            <div className="blue" style={{ background: "#265472" }}></div>
                            <div className="green" style={{ background: "#F19357" }}></div>
                            <div className="yellow" style={{ background: "#F0B285" }}></div>
                          </div>
                        </div>
                      ) : ("")}

<div className="totalRight"><span style={{paddingRight:"20px"}}><strong>Total Count:{totalDataAdd}</strong></span></div>
                      {groupClickData?.length == 0 ? <><p className="p-4" style={{ minHeight: "100px" }}>No Data Found</p></> : (
                        <div className="color">
                          <ReactDataTable
                            data={groupClickData}
                            columns={columns}
                            className="nk-tb-list"

                          />
                        </div>
                      )}


                    </div>
                    <div className="p-4">
                    {groupClickData?.length > 0 ?
                      <div>
                        <ReactPaginate
                          previousLabel={"<<"}
                          nextLabel={">>"}
                          pageCount={count}
                          pageRangeDisplayed={5}
                          marginPagesDisplayed={2}
                          onPageChange={handlePageChange}
                          containerClassName={'pagination'}
                          pageClassName={"page-item"}
                          activeClassName={'active'}
                          pageLinkClassName={"page-link"}
                          previousClassName={"page-item"}
                          previousLinkClassName={"page-link"}
                          nextClassName={"page-item"}
                          nextLinkClassName={"page-link"}
                          breakClassName={"page-item"}
                          breakLinkClassName={"page-link"}
                          forcePage={currentPage - 1}
                        />
                      </div>
                      : ""}
                      </div>
                  </BlockHead>
                </BlockHeadContent>
              </div>

            </div>
          </Card>
        </>
        {/* )} */}
      </Content>
    </React.Fragment>
  );
}
export default GroupClickReport;
