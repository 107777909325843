import React, { useState, useRef } from "react";
import Head from "../../../layout/head/Head";
import Content from "../../../layout/content/Content";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import classnames from "classnames";
import { useEffect } from "react";
import { URLS } from "../../../constants/urls";
import { BlockHead, BlockHeadContent } from "../../../components/Component";
import { DropdownItem, UncontrolledDropdown, DropdownMenu, DropdownToggle, CardTitle } from "reactstrap";
import {
  Block,
  BlockTitle,
  BlockBetween,
  BlockDes,
  Icon,
  Row,
  Col,
  Button,
  DataTableHead,
  DataTableBody,
  DataTableRow,
  DataTableItem,
  PaginationComponent,
  ReactDataTable,
  UserAvatar,
  PreviewCard,
} from "../../../components/Component";
import { Link } from "react-router-dom";
import { Card, Badge } from "reactstrap";
import { useHistory } from "react-router-dom";

import { makePostRequest, makeGetRequest } from "../../../service/api";
import ReactPaginate from "react-paginate";
import { AreaChart, Area, BarChart, Bar, ResponsiveContainer, CartesianGrid, XAxis, YAxis, Tooltip, Legend, Label, LineChart, Line, LabelList } from "recharts";
import moment from "moment";
import DatePicker from "react-datepicker";
import Select from "react-select";
import { parseBusinessHours } from "@fullcalendar/core";
import Highcharts, { chart } from "highcharts";
import { findUpper, capital } from "../../../utils/Utils";
import { Modal, ModalFooter, ModalHeader } from "reactstrap";
import HighchartsReact from 'highcharts-react-official';
// import exporting from "highcharts/modules/exporting";
import { ToastContainer, toast } from "react-toastify";

// exporting(Highcharts);

const ExcelJS = require('exceljs');

function ResourceGetMoreInfo() {

  let history = useHistory();
  const [activeTab, setActiveTab] = useState("1");
  const [activeIconTab, setActiveIconTab] = useState("5");
  const [activeAltTab, setActiveAltTab] = useState("9");
  //   const [verticalTab, setVerticalTab] = useState("1");
  const [verticalIconTab, setVerticalIconTab] = useState("1");
  const [resourceAddMoreInfoData, setResourceAddMoreInfoData] = useState([]);
  const [resourceAddmoreInfoChart, setResourceAddmoreInfoChart] = useState([]);
  const [loader, setLoader] = useState(false);
  const [loader1, setLoader1] = useState(false);
  const [selectyear, setSelectyear] = useState(new Date().getFullYear());
  const [displayvalue, setDisplayValue] = useState("1");
  const [startdate, setStartDate] = useState("2023-01-01");
  const [enddate, setEndDate] = useState(new Date());
  const [selectedfromdate, setSelectedFromDate] = useState(null);
  const [selectedtodate, setSelectedToDate] = useState(null);
  const [user, setUser] = useState("");
//   const [userNameData, setUserNameData] = useState([])
//   const [userName, setNameUser] = useState("")
  const [limitvalue, setlimitvalue] = useState("")
  const [sortvalue, setSortValue] = useState("")
  const [showchart, setshowchart] = useState([])
//   const [getAllResourceNew, setgetAllResourceNew] = useState([]);
//   const [resourceType, setResourceType] = useState("");
  const [resourceTypeText, setResourceTypeText] = useState("");
  const [datepickershow, setDatePickerShow] = useState(false);
  const [datepickershow1, setDatePickerShow1] = useState(false);

  const [toDateError, settoDateError] = useState("");
  const [formDateError, setformDateError] = useState("");
  const [customerSpaceData, setCustomerSpaceData] = useState([])
  const [isOpen, setIsOpen] = useState(false);
  const [totalCount,setTotalCount]=useState("");
  const [zipCode,setZipCode]=useState("");

  const year = 2023;
  const curryear = new Date().getFullYear();
  let years = Array.from(new Array(curryear - 2023 + 2), (val, index) => index + year);
  const colorThemes = ["primary", "purple", "danger", "warning", "success", "pink", "secondary", "blue"]
  let chartdata = []
  let SpaceData = []


  const handleyear = (e) => {
    setSelectyear(e.target.value);
  }

//   const HandleUserName = (e) => {
//     setNameUser(e.value);
//   }

  const HandleFromDate = (e) => {
    setSelectedFromDate(e);
    setDatePickerShow(!datepickershow);
    setformDateError("");
  }
  //console.log(selectedfromdate, "selectedfromdate")

  const HandleToDate = (e) => {
    setSelectedToDate(e);
    setDatePickerShow1(!datepickershow1);
    settoDateError("");

  }

  const handlechart = (e) => {
    getresourceAddmoreInfoChart();
    // setNameUser("");
  }
  const HandleLimitValue = (e) => {

    setlimitvalue(e.target.value)
    setCurrentPage(1)
  }
  const HandleSort = (e) => {
    setSortValue(e.target.value);
    setCurrentPage(1)
  }
  function handleclickdatepickericon() {
    setDatePickerShow(!datepickershow);
  }
  function handleclickdatepickericon1() {
    setDatePickerShow1(!datepickershow1);
  }
  const handleZipCode =(e)=>{
    setZipCode(e.target.value)
}
//   const handleResourceType = (e) => {
//     let name = e.target.name;
//     let value = e.target.value;
//     if (name === "resourceType") {
//       let index = e.nativeEvent.target.selectedIndex;
//       let label = e.nativeEvent.target[index].text;
//       setResourceTypeText(label);
//       setResourceType(value);
//       //  console.log("name",label,"value",value)
//     } else if (name === "curriculamContentType") {
//       setCurriculamContentType(value);
//     } else if (name === "experienceType") {
//       setExperienceType(value);
//     } else if (name === "facilityType") {
//       setFacilityType(value);
//     }
//     else if (name === "fundingType") {
//       setFundingType(value);
//     }
//     else if (name === "organizationType") {
//       setOrganizationType(value);
//     }
//     else if (name === "studentSupportType") {
//       setStudentSupportType(value);
//     }
//     else if (name === "teacherParentSupportType") {
//       setTeacherParentSupportType(value);
//     }
//     else if (name === "timeFrameTypeCariculam") {
//       setTimeFrameTypeCariculam(value);
//     } else if (name === "timeFrameTypeExperience") {
//       setTimeFrameTypeExperience(value);
//     }
//     else {
//       setState({ ...state, [name]: value });
//     }
//   }

  const ExportEcelFile = () => {
    setLoader(true);
    let data = {
      start_date: selectedfromdate ? moment(selectedfromdate).format("YYYY-MM-DD") : "",
      end_date: selectedtodate ? moment(selectedtodate).add(1, 'd').format("YYYY-MM-DD") : "",
       export_data: "yes",
       limit:limitvalue ? parseInt(limitvalue) : 10,
      page: currentPage ? currentPage : 1,
       resource_user_id:resourceUserName ? resourceUserName :"",
    //   resource_Type: resourceType ? resourceType : "",
    //   page_number: currentPage ? currentPage : 1,
    //   item_per_page: limitvalue ? parseInt(limitvalue) : 10,
    //   sort_by: sortvalue ? sortvalue : ""
     resource_id:resourceValue ? resourceValue :"",
    resource_type_status:groupType ? groupType :"",
    group_id:groupName ? groupName :"",
    // zipcode:zipCode ? zipCode :"",
    };

    makePostRequest(URLS.GETRESOURCEMOREINFOLIST, null, data, null)
      .then((res) => {
        if (res.code == 200) {
          //console.log("Resource export", res.data.data)
          const workbook = new ExcelJS.Workbook();
          const sheet = workbook.addWorksheet("SpaceData");
          sheet.properties.defaultRowHeight = 30;



          sheet.getRow(1).font = {
            size: 10,
            bold: true,
          }

          sheet.columns = [
            {
              header: "S.No",
              key: 'S_No',
              width: 5
            },

            {
              header: "Customer Name",
              key: 'USER_NAME',
              width: 30
            },
            {
              header: "Date Registered",
              key: 'saveddate',
              width: 30
            },

            {
              header: "Email",
              key: 'OWNEREMAIL',
              width: 30
            },
            {
              header: "Phone",
              key: 'OWNWERPHONE',
              width: 30
            },

            {
              header: "Resource Name",
              key: 'Resource_Name',
              width: 20
            },


          ];
          res.data.data?.map((val, i) => {
            sheet.addRow({
              S_No: (i + 1),
              saveddate: val?.created_at ? moment(val?.created_at).format("MM-DD-YYYY") : "--",
              USER_NAME: val?.tbl_user?.firstName + " " + val.tbl_user?.lastName,
              OWNEREMAIL: val?.tbl_user?.email,
              OWNWERPHONE: val?.tbl_user?.phone,
              Resource_Name: val?.tbl_user_resource?.resource_title,
            })
          })
          workbook.xlsx.writeBuffer().then(data => {
            const blob = new Blob([data], {
              type: "application/vnd.openxmlformats-officedocument.spreadsheet.sheet",
            })
            const url = window.URL.createObjectURL(blob);
            const anchor = document.createElement('a');
            anchor.href = url;
            anchor.download = 'ResourceGetMoreInfo.xlsx';
            anchor.click();
            window.URL.revokeObjectURL(url)
          })
          setLoader(false);
         // console.log(res.data, "res g")

        }
      })
      .catch((error) => {
        console.log(error);
      });

  }


  /*========================= Resource Type Api Calling ============================*/
const [groupType,setGroupType]=useState("");
const [resourceTypeGraph,setResourceTypeGraph]=useState("");

const [resourceTypedata,setResourceTypeData]=useState([]);
const [resourceValue,setResourceValue]=useState("");
const [resourceValueGraph,setResourceValueGraph]=useState("");

const [resourceUserName,setResourceUserName]=useState("");
const [userNameData,setUserNameData]=useState([]);
const [resourceUserNameGraph,setResourceUserNameGraph]=useState("");

const [groupName,setGroupName]=useState("");
const [groupData,setGroupData]=useState([]);
const [groupNameGraph,setGroupNameGraph]=useState("");

//console.log(resourceUserName,"user",groupName,"group",groupType,"groupType",resourceValue,"resource")
/*=======================Get Resource Api=============================*/

    const getAllResourceType = () => {
      let data=
        {
          "item_per_page":20,
           "page_number":1,
           "resource_type_status":groupType,
           "user_id":resourceUserName,
           "group_resource_id":groupName,
      }
     //console.log(data,"enrolldata")
    // setLoader(true);
    makePostRequest(URLS.GETALLRESOURCE_MORE_INFO_FILTER,null, data, null)
      .then(res => {
        //console.log(res,"resourcedata");
        if (res.code == 200) {
          setResourceTypeData(res.data.data);
          // setLoader(false);
        }
      })
      .catch((error) => {
        console.log(error);
      })

  }

  useEffect(()=>{
    getAllResourceType();
     },[groupType,resourceUserName,groupName])
    //  useEffect(()=>{
    //   getResourceAddMoreInfoReport();
    // },[resourceUserName,groupName])

  /*=======================Get User Api=============================*/

  const getAllUser = () => {
    let data=
      {
        // "item_per_page":20,
        // "page_number":1,
        resource_type_status:"individual",
    }
   
  setLoader(true);
  makePostRequest(URLS.GETALL_GROUP_OR_USER,null, data, null)
    .then(res => {
      //console.log(res,"userdata");
      if (res.code == 200) {
        setUserNameData(res.data.data);
        setLoader(false);
      }
    })
    .catch((error) => {
      console.log(error);
    })

}



/*=======================Get Group Api=============================*/

const getAllGroup = () => {
  let data=
    {
      // "item_per_page":20,
      // "page_number":1,
       resource_type_status:"group",
  }
 
setLoader(true);
makePostRequest(URLS.GETALL_GROUP_OR_USER,null, data, null)
  .then(res => {
    //console.log(res,"Groupdata");
    if (res.code == 200) {
      setGroupData(res.data.data);
      setLoader(false);
    }
  })
  .catch((error) => {
    console.log(error);
  })

}
//console.log(groupType,"gtype")
const handleResourceGroupTypeList =(e)=>{
  setGroupType(e.target.value)

  //console.log(e.target.value,"value")

  if(e.target.value==="group"){
    setResourceUserName("")
    setResourceValue("")
  }

  if(e.target.value==="individual"){
    setGroupName("")
    setResourceValue("")
  }
  if (e.target.value === "") {
    setGroupName("");
    setResourceUserName("");
    setResourceValue("");
    
  }
 
  
}
const handleResourceTypeGraph=(e)=>{
  setResourceTypeGraph(e.target.value)
  if(e.target.value==="group"){
    setResourceUserNameGraph("")
    setResourceValueGraph("")
  }

  if(e.target.value==="individual"){
    setGroupNameGraph("")
    setResourceValueGraph("")
  }
  if (e.target.value === "") {
    setResourceUserNameGraph("")
    setResourceValueGraph("")
    setGroupNameGraph("")
    
  }
}


  const handleResourceTypeList=(e)=>{
    setResourceValue(e.target.value)
  }

  const handleResourceTypeListGraph=(e)=>{
    setResourceValueGraph(e.target.value)
  }
  const handleResourceUserListGraph=(e)=>{
    setResourceUserNameGraph(e.target.value)
    setResourceValueGraph("")
  }
  const handleResourceGroupListGraph=(e)=>{
    setGroupNameGraph(e.target.value)
    setResourceValueGraph("")


  }

  const handleResourceUserList=(e)=>{
    setResourceUserName(e.target.value)

  }

  const handleResourceGroupList=(e)=>{
    setGroupName(e.target.value)
    setResourceValue("")
  }


useEffect(()=>{
  getAllResourceType();
  getAllUser();
  getAllGroup();
},[])



//   const getAllUserType = () => {
//     setLoader(true);
//     makeGetRequest(URLS.GET_ALL_RESOURCE_TYPE, {}, null)
//       .then(res => {
//         if (res.code == 200) {
//           setgetAllResourceNew(res.data);
//           setLoader(false);
//         }
//       })
//       .catch((error) => {
//         console.log(error);
//       })

//   }

  const openModal = () => {
    setIsOpen(!isOpen);
  };

  
  const handlesearch = (e) => {
    // setLoader(true);

    // let data = {
    //   start_date: selectedfromdate ? moment(selectedfromdate).format("YYYY-MM-DD") : "2023-01-01",
    //   end_date: selectedtodate ? moment(selectedtodate).add(1, 'd').format("YYYY-MM-DD") : moment(new Date()).format("YYYY-MM-DD"),
    //   user_id: userName ? userName : "",
    //   page_number:currentPage?currentPage:1,
    //   item_per_page:limitvalue?parseInt(limitvalue):10,

    // };

    // console.log(data, "data")
    // makePostRequest(URLS.GET_CUSTOMER_LOGGEDIN_REPORTSLIST, null, data, null)
    //   .then((res) => {
    //     // setLoader(true)
    //     if (res.code === 200) {
    //       console.log(res.data.user_data, "login data");
    //        setCustomerloginreport(res?.data?.user_data);
    //       setLoader(false);

    //     } else {
    //       setLoader(false);
    //     }
    //   })
    //   .catch((error) => {
    //     console.log(error);
    //   });
    // // getcustomerloginreportchart();
    // setNameUser("");
    if (selectedfromdate != null && selectedtodate == null) {
      settoDateError("please select valid end date")
    }
    else if (selectedtodate != null && selectedfromdate == null) {
      setformDateError("please select valid start date ")
    }
    else if (selectedfromdate > selectedtodate) {
      toast.error("end date should be greater than start date")
    }
    else {

      getResourceAddMoreInfoReport();
      setCurrentPage(1)
    }
  }


  //user type get api GET_USER_TYPE

//   const getUserName = () => {
//     makePostRequest(URLS.GET_ALL_ONLY_CUSTOMER, null, {}, null)
//       .then((res) => {
//        // console.log(res)
//         if (res.code == 200) {
//           let option = [];
//           option.push({ value: "", label: "All Users" })
//           res.data.length > 0 && res.data.map((val) => {
//             option.push({ value: val.id, label: val.firstName + " " + val.lastName })
//           })
//           setUserNameData(option);
//         }
//       })
//       .catch((error) => {
//         console.log(error);
//       });
//   };

  const getresourceAddmoreInfoChart = () => {
    setLoader(true);
    let data = {
      year: selectyear,
      group_id:"",
      resource_type_status:resourceTypeGraph ? resourceTypeGraph :"",
      resource_user_id:resourceUserNameGraph ? resourceUserNameGraph :"",
      resource_id:resourceValueGraph ? resourceValueGraph :"",
    };
    console.log("data chart:::", data)
    makePostRequest(URLS.GETRESOURCEMOREINFOGRAPH, null, data, null)
      .then((res) => {
        // setLoader(true)
        if (res.code === 200) {
          console.log(res, "ResourceAddmoreinfo chart res");
          setResourceAddmoreInfoChart(res?.data?.graph_data);
          setLoader(false);

        } else {
          setLoader(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const [clearStateGraph, setClearStateGraph] = useState(false);
  const [clearStateList, setClearStateList] = useState(false);
  /*==============================Resource Add More Info List Api ==============================*/

  const [currentPage, setCurrentPage] = useState(1);
  const [count, setCount] = useState();

  const getResourceAddMoreInfoReport = () => {
    setLoader1(true);
    let data = {
      start_date: selectedfromdate ? moment(selectedfromdate).format("YYYY-MM-DD") : "",
      end_date: selectedtodate ? moment(selectedtodate).add(1, 'd').format("YYYY-MM-DD") : "",
       export_data: "no",
       limit:limitvalue ? parseInt(limitvalue) : 10,
      page: currentPage ? currentPage : 1,
       resource_user_id:resourceUserName ? resourceUserName :"",
    //   resource_Type: resourceType ? resourceType : "",
    //   page_number: currentPage ? currentPage : 1,
    //   item_per_page: limitvalue ? parseInt(limitvalue) : 10,
    //   sort_by: sortvalue ? sortvalue : ""
     resource_id:resourceValue ? resourceValue :"",
    resource_type_status:groupType ? groupType :"",
    group_id:groupName ? groupName :"",
    // zipcode:zipCode ? zipCode :"",
   
    };
    //console.log(data, "AddResourcedata")
    makePostRequest(URLS.GETRESOURCEMOREINFOLIST, null, data, null)
      .then((res) => {
        // setLoader(true)
        if (res.code === 200) {
          //console.log(res, "ResourceAddmore  data list");
          setResourceAddMoreInfoData(res?.data.data);
          setCount(Math.ceil(res.data.count / parseInt(limitvalue ? limitvalue : 10)))
          setLoader1(false);
          setTotalCount(res.data.count)

        } else {
          setLoader1(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
    // setNameUser("");
  }

  const handlePageChange = (selectedPage) => {
    setCurrentPage(selectedPage.selected + 1)
  };
  // const fetchData = () => {
  //   let data = {
  //     start_date: selectedfromdate ? moment(selectedfromdate).format("YYYY-MM-DD") : "",
  //     end_date: selectedtodate ? moment(selectedtodate).add(1, 'd').format("YYYY-MM-DD") : "",
  //      export_data: "no",
  //      limit:limitvalue ? parseInt(limitvalue) : 10,
  //     page: currentPage ? currentPage : 1,
  //      resource_user_id:resourceUserName ? resourceUserName :"",
  //   //   resource_Type: resourceType ? resourceType : "",
  //   //   page_number: currentPage ? currentPage : 1,
  //   //   item_per_page: limitvalue ? parseInt(limitvalue) : 10,
  //   //   sort_by: sortvalue ? sortvalue : ""
  //    resource_id:resourceValue ? resourceValue :"",
  //   resource_type_status:groupType ? groupType :"",
  //   group_id:groupName ? groupName :"",
  //   zipcode:zipCode ? zipCode :"",
  //   }
  //   makePostRequest(URLS.GETRESOURCEMOREINFOLIST, null, data, null)
  //     .then((res) => {
  //       if (res.code == 200) {
  //         setResourceAddMoreInfoData(res?.data.data);
  //         setCount(Math.ceil(res.data.count / parseInt(limitvalue ? limitvalue : 10)))
  //       }
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // };
  
    const clearFilterGraph = () => {
    clearFilterGraphFun();
    
  }

  const clearFilterGraphFun = () => {
	setSelectyear(new Date().getFullYear());
	setResourceTypeGraph("");
	setResourceUserNameGraph("");
  setResourceValueGraph("");
  setGroupNameGraph("");
    setClearStateGraph(!clearStateGraph);
    
  }
  
    const clearFilterList = () => {
    clearFilterListFun();
  }

  const clearFilterListFun = () => {
    setlimitvalue("");
    setSelectedFromDate(null);
    setSelectedToDate(null);
	  setZipCode("");
    setGroupType("");
    setResourceUserName("");
    setGroupName("");
    setResourceValue("");
    setCurrentPage(1);
    setClearStateList(!clearStateList);
  }
  
  
  

  useEffect(() => {
   // fetchData()
   getResourceAddMoreInfoReport();
  }, [currentPage, limitvalue, sortvalue])


  useEffect(() => {
    getResourceAddMoreInfoReport();
    getresourceAddmoreInfoChart();
  }, []);


   useEffect(() => {
     getresourceAddmoreInfoChart();
   }, [clearStateGraph])
   
   useEffect(() => {
     getResourceAddMoreInfoReport();
   }, [clearStateList])

  useEffect(() => {
    getchartdata();
  }, [resourceAddmoreInfoChart])

  const getchartdata = () => {
    resourceAddmoreInfoChart?.map((val) => {
      chartdata.push(val?.total_more_info_count)

    })
    setshowchart(chartdata)
  }


  const columns = [
    {
      name: "#",
      selector: (row) => row.id,
      grow: 1,
      minWidth: "50px",
      style: { minWidth: "50px" },
      borderRadius: "4px",
      cell: (row, index) => (
        <>
          <div className="user-card mt-2 mb-2"> {currentPage==1?(index + 1):((currentPage-1)*(limitvalue!=""?parseInt(limitvalue):10))+(index + 1)} </div>
        </>
      ),
    },
    {
        name: "Date Registered",
        selector: (row) => "",
        grow: 4,
  
        cell: (row, index) => (
          <>
            <div className="user-card mt-2 mb-2">
            <span className="tb-lead">
              {row?.created_at ? moment(row?.created_at).format("MM-DD-YYYY") : "--"}
              </span>
            </div>
          </>
        ),
      },
      {
        name: "Resource Name",
        selector: (row) => row?.month,
        grow: 4,
  
        cell: (row, index) => (
          <>
            <div className="user-card mt-2 mb-2">
              <div className="user-info">
                <span className="sub-text">
                  {row?.tbl_user_resource?.resource_title ? row?.tbl_user_resource?.resource_title : "--"}
                </span>
                {/* {row?.tbl_user_resource?.resource_type_status=="group" ? 
   <span className="tb-lead">
                  {row?.tbl_user_resource?.tbl_group ? (row?.tbl_user_resource?.tbl_group?.group_name ) : "--"}
                </span>
               :""} */}

{/* {row?.tbl_user_resource?.resource_type_status=="individual" ? 
   <span className="tb-lead">
                  {row?.tbl_user ? (row?.tbl_user_resource?.tbl_user?.firstName + " " + row?.tbl_user_resource?.tbl_user?.lastName) : "--"}
                </span>
               :""}  */}

              </div>
            </div>
          </>
        ),
      },
  
      {
        name: "Resource Owner",
        selector: (row) => "",
        grow: 4,
  
        cell: (row, index) => (
          <>
            <div className="user-card mt-2 mb-2">
              <div className="user-info">
   <span className="tb-lead">
                  {row?.tbl_user ? (row?.tbl_user_resource?.tbl_user?.firstName + " " + row?.tbl_user_resource?.tbl_user?.lastName) : "--"}
                </span>
           
                <span className="sub-text">
                  {row?.tbl_user ? (row?.tbl_user_resource?.tbl_user?.email ) : ""}
                </span>
              </div>
            </div>
          </>
        ),
      },
      {
        name: "Group Name",
        selector: (row) => row?.month,
        grow: 4,
  
        cell: (row, index) => (
          <>
            <div className="user-card mt-2 mb-2">
              <div className="user-info">
               
              
   <span className="tb-lead">
                  { row?.tbl_user_resource?.tbl_group ? (row?.tbl_user_resource?.tbl_group?.group_name ) : "--"}
                </span>
              



              </div>
            </div>
          </>
        ),
      },

    {
      name: "Customer Details",
      selector: (row) => row?.tbl_user,
      grow:4,
      cell: (row, index) => (
        <>
         
          <div className="user-card mt-2 mb-2">
            <div className="user-info">
             
              <span className="tb-lead">
                {row?.tbl_user ? (row?.tbl_user?.firstName + " " + row?.tbl_user?.lastName) : "--"}
              </span>
              <span className="sub-text">
                {row?.tbl_user ?(row?.tbl_user?.email):""}
              </span>
              {/* <span className="sub-text">
                {row?.tbl_user ?(row?.tbl_user?.phone):""}
              </span> */}
             
            </div>
          </div>
        </>
      ),
    },
    {
      name: "Zip Code",
      selector: (row) => row?.tbl_user,
      grow:4,
      cell: (row, index) => (
        <>
         
          <div className="user-card mt-2 mb-2">
            <div className="user-info">
             
              <span className="tb-lead">
                {row?.tbl_user ? (row?.tbl_user.zipcode) : "--"}
              </span>
             
            </div>
          </div>
        </>
      ),
    },
   
  ]
  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };
  const toggleIconTab = (icontab) => {
    if (activeIconTab !== icontab) setActiveIconTab(icontab);
  };
  const toggleAltTab = (alttab) => {
    if (activeAltTab !== alttab) setActiveAltTab(alttab);
  };
  
    const newRef = useRef(null);
	const newRef1 = useRef(null);
  const handleOutSideClick = (e)=> {
	 if(newRef.current && !newRef.current.contains(e.target)){
         setDatePickerShow(false);
          
	 }		 
  }
  const handleOutSideClick1 = (e)=> {
	 if(newRef1.current && !newRef1.current.contains(e.target)){
            setDatePickerShow1(false);		 
	 }		 
  }
  useEffect(()=>{
	 document.addEventListener("mousedown", handleOutSideClick );
	 document.addEventListener("mousedown", handleOutSideClick1 );
      return ()=> {
       document.removeEventListener("mousedown", handleOutSideClick );
	    document.removeEventListener("mousedown", handleOutSideClick1 );
	  }	   
  });
  
  return (

    <React.Fragment>


      <Head title="Resource Get More Info Report"></Head>
      <Content>

        <h3 className="nk-block-title page-title mb5 pb-4">Resource Get More Info Report</h3>


        {/* {loader ? (
          <div className="overflowloader" id="preload">
            <div className="circle-square" style={{ marginTop: "250px" }} >
              <div className="red" style={{ background: "#AAC0CE" }}></div>
              <div className="blue" style={{ background: "#265472" }}></div>
              <div className="green" style={{ background: "#F19357" }}></div>
              <div className="yellow" style={{ background: "#F0B285" }}></div>
            </div>
          </div>
        ) : ( */}
        <>
          <Card className="card-bordered">
            <Modal isOpen={isOpen} toggle={openModal}>
              <ModalHeader
                toggle={openModal}
                close={
                  <button className="close" onClick={openModal}>
                    <Icon name="cross" />
                  </button>
                }
              >
                Space Used Details
              </ModalHeader>
              <Card className="card-bordered">
                <div className="space-used-details">
                  <div className="card-inner">
                    <BlockHeadContent>
                      <BlockHead size="lg">
                        <div className="customerDetailsTable">
                          <table >
                            <tr className="border-bottom">
                            <th>#</th>
                              <th width="50%">Resource Title </th>
                              <th width="100%" align="right">Mode</th>


                              <th width="100%" align="right">Space Used(MB)</th>
                            </tr>
                            {customerSpaceData && customerSpaceData.map((val, i) => {
                              return (
                                <tr className="border-bottom">
                                  <td>{i + 1}</td>
                                  <td>{val?.resource_title}</td>
                                  <td>{val?.resource_mode}</td>
                                  <td>{(val?.space_used_by_resource / 1024).toFixed(2)}</td>


                                </tr>
                              )

                            })}

                          </table>
                        </div>
                      </BlockHead>
                    </BlockHeadContent>
                  </div>
                </div>
              </Card>
              <ModalFooter className=""></ModalFooter>
            </Modal>
            <div className="card-inners relativeClass">






              <div className="card-inners relativeClass">
                <BlockHeadContent>
                  <BlockHead size="lg">
                  <div className="p-4">
                    <div className="filtersidebutton">
                      <h3 className="graphheading  mb-0 mt-1">Graph View</h3>
					  <button className="btn btn-md btn-primary" type="submit" onClick={clearFilterGraph}>Clear Filters</button>
                    </div>
                    {/* <div class="d-flex justify-content-end g-2">
                  <div class="dataTables_length" id="DataTables_Table_0_length">*/}
                    <div className="row">
                      <div className="col-lg-3">
                        <div className="form-control border-0 px-0">
                          <label>
                            <strong>Select Year</strong>
                          </label>

                          <div className="form-control-wrap">
                            <select className="form-select" value={selectyear} onChange={handleyear} >
                              {
                                years.map((year, index) => {
                                  return <option key={`year${index}`} value={year}>{year}</option>
                                })
                              }
                            </select>

                          </div>
                        </div>
                      </div>
                      {/* <div className="col-lg-3">
                        <div className="form-control border-0 px-0">
                          <label>
                            <strong>User Name</strong>
                          </label>

                        
                          <div className="form-control-wrap">
<i className="fa fa-chevron-down"></i>
                            <Select
                              defaultvalue={userName}
                              options={userNameData}

                              onChange={HandleUserName}
                            />
                          </div>
                        </div>
                      </div> */}

 

                      <div className="col-lg-3">
                        <div className="form-control border-0 px-0" style={{ marginBottom: "10px" }}>
                          <label><strong> Individual/Group</strong></label>
                          <select className="form-select" name="resourceType" onChange={handleResourceTypeGraph} value={resourceTypeGraph} >
                            <option value=""> Select... </option>
                            <option value="group"> Group </option>
                            <option value="individual"> Individual </option>
                           
                          </select>
                        </div>
                      </div>
                      {resourceTypeGraph=="individual" ? 
                      <div className="col-lg-3">
                        <div className="form-control border-0 px-0" style={{ marginBottom: "10px" }}>
                          <label><strong>Resource Owner</strong></label>
                          <select className="form-select" name="resourceType" onChange={handleResourceUserListGraph} value={resourceUserNameGraph} >
                            <option value="">All </option>
                            {
                              userNameData.map((val, index) => {
                                return (<>
                                  <option value={val?.tbl_user_resource?.tbl_user?.id} key={index + 1}>{val?.tbl_user_resource?.tbl_user?.firstName +" "+ val?.tbl_user_resource?.tbl_user?.lastName}</option>

                                </>)
                              })

                            }
                          </select>
                        </div>
                      </div> 
:""}

{resourceTypeGraph=="group" ? 
                      <div className="col-lg-3">
                        <div className="form-control border-0 px-0" style={{ marginBottom: "10px" }}>
                          <label><strong>Group Name</strong></label>
                          <select className="form-select" name="resourceType" onChange={handleResourceGroupListGraph} value={groupNameGraph} >
                            <option value=""> All Group </option>
                            {
                              groupData.map((val, index) => {
                                return (<>
                                  <option value={val?.tbl_user_resource?.tbl_group?.id} key={index + 1}>{val?.tbl_user_resource?.tbl_group?.group_name}</option>

                                </>)
                              })

                            }
                          </select>
                        </div>
                      </div> 
                      :""}

{resourceUserNameGraph  && resourceTypeGraph || groupNameGraph && resourceTypeGraph  ? 
<div className="col-lg-3">
                        <div className="form-control border-0 px-0" style={{ marginBottom: "10px" }}>
                          <label><strong>Resource Title</strong></label>
                          <select className="form-select" name="resourceType" onChange={handleResourceTypeListGraph} value={resourceValueGraph} >
                            <option value="">All Resource </option>
                            {
                              resourceTypedata.map((val, index) => {
                                return (<>
                                  <option value={val?.tbl_user_resource?.id} key={index + 1}>{val?.tbl_user_resource?.resource_title}</option>

                                </>)
                              })

                            }
                          </select>
                        </div>
                      </div>
                      :""}
                      
                      <div className="col-lg-3">
                        <div className="form-control border-0 px-0">
						<label className="w-100 mb-0">&nbsp;</label>
                          <button className="btn btn-primary" type="submit" onClick={handlechart}>
                            {" "}
                            View{" "}
                          </button>

                        </div>
                      </div>

                     </div>
                    </div>


                    <div className="p-5">
                      <div className="row">
                        <div className="col-lg-6">

                        </div>

                      </div>
                      <div className="relativeClass">
                        {loader ? (
                          <div className="overflowloader" id="preload">
                            <div className="circle-square" >
                              <div className="red" style={{ background: "#AAC0CE" }}></div>
                              <div className="blue" style={{ background: "#265472" }}></div>
                              <div className="green" style={{ background: "#F19357" }}></div>
                              <div className="yellow" style={{ background: "#F0B285" }}></div>
                            </div>
                          </div>
                        ) : (
                          <HighchartsReact
                            highcharts={Highcharts}
                            options={{
                              chart: {
                                type: 'line'
                              },
                              title: {
                                text: 'Resource Get More Info Chart',
                                style: { "color": "#003C71", "fontSize": "25px" }
                              },

                              xAxis: {
                                categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
                                labels: {
                                  style: {
                                    color: '#364a63'
                                  }
                                }
                              },
                              plotOptions: {
                                line: {
                                  dataLabels: {
                                    enabled: true,
                                    formatter: function () {
                                      return (this.y != 0) ? this.y : "";
                                    }
                                  },
                                  enableMouseTracking: true
                                }
                              },
                              credits: {
                                enabled: false
                              },

                              yAxis: {
                                title: {
                                  text: 'No. of Request',
                                  style: {
                                    color: '#364a63'
                                  }
                                },
                                labels: {
                                  style: {
                                    color: '#364a63'
                                  }
                                }


                              },
                              series: [{
                                name: "Month",
                                data: showchart,
                                color: "rgb(38, 84, 114)"


                              }]
                            }}
                          />
                        )}
                      </div>
                    </div>
                    <div className="p-4">
                    <div className="d-flex justify-content-between mb-2">
                     <div className="filtersidebutton">
                          <h3 className="graphheading mb-0 mt-1">List View</h3>
						  <button className="btn btn-md btn-primary" type="submit" onClick={clearFilterList}>Clear Filters</button>
                        </div>
                      <div className="row">
                        <div className="col-lg-12 d-flex  justify-content-end">

                          {/* <select value={sortvalue} className="form-select" style={{ width: "190px" }} onChange={HandleSort} >
                            <option value="">Sort By</option>
                            <option value="ASCDATE">Date Ascending</option>
                            <option value="DESCDATE">Date Descending</option>
                            <option value="ASCCOUNT">Space used  Ascending</option>
                            <option value="DESCCOUNT">Space used  Descending</option>
                          </select> */}

                          <div><label className="mt-1 ">&nbsp;&nbsp;Show&nbsp;</label></div>
                          <div><select value={limitvalue} className="form-select" style={{ width: "85px" }} onChange={HandleLimitValue}>
                            <option value="10">10</option>
                            <option value="20">20</option>
                            <option value="30">30</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                          </select>
                          </div>
                          <div>
                            &nbsp; &nbsp;
                            <button className="btn btn-primary " onClick={ExportEcelFile}>
                              <em className="icon ni ni-download-cloud"></em><span>Export</span>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row pb-2">

                      {/* <div className="col-lg-3">
                        <div className="form-control border-0 px-0">
                          <label>
                            <strong>User Name</strong>
                          </label>
                          <div className="form-control-wrap">
<i className="fa fa-chevron-down"></i>
                            <Select
                              defaultvalue={userName}
                              options={userNameData}
                              onChange={HandleUserName}
                            />
                          </div>
                        </div>
                      
                      </div> */}

                      <div className="col-lg-3">
                        <div className="form-control border-0 px-0">
                          <label>
                            <strong>Start Date</strong>
                          </label>
                          {/* <input type="date" name="formdate" className="form-control" onChange={HandleFromDate} /> */}

                          <div className="form-control-wrap" ref={newRef}>
                            <div className="form-icon form-icon-left">
                              <span onClick={() => handleclickdatepickericon()}>
                                <Icon name="calendar"></Icon>
                              </span>
                            </div>
                            <DatePicker
                              placeholderText="MM/DD/YYYY"
                              selected={selectedfromdate}
                              onChange={HandleFromDate}
                              onFocus={() => setDatePickerShow(!datepickershow)}
                              className="form-control date-picker"
                              open={datepickershow}
                            />
                          </div>
                        </div>
                        <span className="error">{formDateError}</span>
                      </div>
                      <div className="col-lg-3">
                        <div className="form-control border-0 px-0">
                          <label>
                            <strong>End Date</strong>
                          </label>
                          {/* <input type="date" name="formdate" className="form-control" onChange={HandleFromDate} /> */}

                          <div className="form-control-wrap" ref={newRef1}>
                            <div className="form-icon form-icon-left">
                              <span onClick={() => handleclickdatepickericon1()}>
                                <Icon name="calendar"></Icon>
                              </span>
                            </div>
                            <DatePicker
                              placeholderText="MM/DD/YYYY"
                              selected={selectedtodate}
                              onChange={HandleToDate}
                              onFocus={() => setDatePickerShow1(!datepickershow1)}
                              className="form-control date-picker"
                              open={datepickershow1}
                            />
                          </div>
                        </div>
                        <span className="error">{toDateError}</span>
                      </div>

                      {/* <div className="col-md-3">
                        <label className="lavelinfo">
                          <strong>Zip Code</strong>
                        </label>
                        <div className="form-control-wrap">

                          <input placeholder="Zipcode" type="text" className="form-control pl-2" value={zipCode} onChange={handleZipCode} />
                        </div>
                      </div> */}

                       {/* <div className="col-lg-3">
                        <div className="form-control border-0 px-0" style={{ marginBottom: "10px" }}>
                          <label><strong>Resource Title</strong></label>
                          <select className="form-select" name="resourceType" onChange={handleResourceTypeList} value={resourceValue} >
                            <option value=""> Select...</option>
                            {
                              resourceTypedata.map((val, index) => {
                                return (<>
                                  <option value={val?.tbl_user_resource?.id} key={index + 1}>{val?.tbl_user_resource?.resource_title}</option>

                                </>)
                              })

                            }
                          </select>
                        </div>
                      </div>  */}

                       <div className="col-lg-3">
                        <div className="form-control border-0 px-0" style={{ marginBottom: "10px" }}>
                          <label><strong>Individual/Group</strong></label>
                          <select className="form-select" name="resourceType" onChange={handleResourceGroupTypeList} value={groupType} >
                            <option value=""> Select... </option>
                            
                                  <option value="group">Group</option>
                                  <option value="individual">Individual</option>

                          </select>
                        </div>
                      </div>

{groupType== "individual" ? 
                      <div className="col-lg-3">
                        <div className="form-control border-0 px-0" style={{ marginBottom: "10px" }}>
                          <label><strong>Resource Owner</strong></label>
                          <select className="form-select" name="resourceType" onChange={handleResourceUserList} value={resourceUserName} >
                            <option value="">All</option>
                            {
                              userNameData.map((val, index) => {
                                return (<>
                                  <option value={val?.tbl_user_resource?.tbl_user?.id} key={index + 1}>{val?.tbl_user_resource?.tbl_user?.firstName +" "+ val?.tbl_user_resource?.tbl_user?.lastName}</option>

                                </>)
                              })

                            }
                          </select>
                        </div>
                      </div> 
:""}

{groupType=="group" ? 
                      <div className="col-lg-3">
                        <div className="form-control border-0 px-0" style={{ marginBottom: "10px" }}>
                          <label><strong>Group Name</strong></label>
                          <select className="form-select" name="resourceType" onChange={handleResourceGroupList} value={groupName} >
                            <option value=""> All Group </option>
                            {
                              groupData.map((val, index) => {
                                return (<>
                                  <option value={val?.tbl_user_resource?.tbl_group?.id} key={index + 1}>{val?.tbl_user_resource?.tbl_group?.group_name}</option>

                                </>)
                              })

                            }
                          </select>
                        </div>
                      </div> 

                      :""}

{groupName && groupType  || resourceUserName && groupType ? 

<div className="col-lg-3">
   <div className="form-control border-0 px-0" style={{ marginBottom: "10px" }}>
     <label><strong>Resource Title</strong></label>
     <select className="form-select" name="resourceType" onChange={handleResourceTypeList} value={resourceValue} >
       <option value=""> All Resource </option>
       {
         resourceTypedata.map((val, index) => {
           return (<>
             <option value={val?.tbl_user_resource?.id} key={index + 1}>{val?.tbl_user_resource?.resource_title}</option>

           </>)
         })

       }
     </select>
   </div>
 </div>  
:""}

                    </div>
                    <div className="d-flex flex-row" style={{ gap: "10px" }}>
                      {/* <div className="col-lg-2 d-flex "> */}
                      <div className="outerTypeBox mb-3">
                        <button className="btn btn-primary" type="submit" onClick={handlesearch}>
                          {" "}
                          Search{" "}
                        </button>

                        {/* <button className="btn btn-md btn-primary mt-2" type="submit" onClick={clearfilter}>Clearfilter</button> */}
                        {/* </div> */}
                      </div>
                      {/* <div className="col-lg-2 d-flex "> */}
                      {/* <div className="outerTypeBox mb-3">
                          <button className="btn btn-primary" onClick={ExportEcelFile}>
                            <em className="icon ni ni-download-cloud"></em><span>Export</span>
                          </button>
                        </div> */}
                      {/* </div> */}
                    </div>
                    </div>
                    <div className="relativeClass newregisterGrouptable">
                      {loader1 ? (
                        <div className="overflowloader" id="preload">
                          <div className="circle-square">
                            <div className="red" style={{ background: "#AAC0CE" }}></div>
                            <div className="blue" style={{ background: "#265472" }}></div>
                            <div className="green" style={{ background: "#F19357" }}></div>
                            <div className="yellow" style={{ background: "#F0B285" }}></div>
                          </div>
                        </div>
                      ) : ("")}

<div className="totalRight"><span style={{paddingRight:"20px"}}><strong>Total Count:{totalCount}</strong></span></div>
                      {resourceAddMoreInfoData?.length == 0 ? <><p className="p-4" style={{ minHeight: "100px" }}>No Data Found</p></> : (
                        <div className="color">
                          <ReactDataTable
                            data={resourceAddMoreInfoData}
                            columns={columns}
                            className="nk-tb-list"

                          />
                        </div>
                      )}


                    </div>
                    <div className="p-4">
                    {resourceAddMoreInfoData?.length > 0 ?
                      <div>
                        <ReactPaginate
                          previousLabel={"<<"}
                          nextLabel={">>"}
                          pageCount={count}
                          pageRangeDisplayed={5}
                          marginPagesDisplayed={2}
                          onPageChange={handlePageChange}
                          containerClassName={'pagination'}
                          pageClassName={"page-item"}
                          activeClassName={'active'}
                          pageLinkClassName={"page-link"}
                          previousClassName={"page-item"}
                          previousLinkClassName={"page-link"}
                          nextClassName={"page-item"}
                          nextLinkClassName={"page-link"}
                          breakClassName={"page-item"}
                          breakLinkClassName={"page-link"}
                          forcePage={currentPage - 1}
                        />
                      </div>
                      : ""}
                      </div>
                  </BlockHead>
                </BlockHeadContent>
              </div>

            </div>
          </Card>
        </>
        {/* )} */}
      </Content>
    </React.Fragment>
  );
}
export default ResourceGetMoreInfo;
