import React, { useState, useRef } from "react";
import Head from "../../../layout/head/Head";
import Content from "../../../layout/content/Content";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import { useEffect } from "react";
import { URLS } from "../../../constants/urls";
import { BlockHead, BlockHeadContent } from "../../../components/Component";
import { DropdownItem, UncontrolledDropdown, DropdownMenu, DropdownToggle, CardTitle } from "reactstrap";
import {
  Block,
  BlockTitle,
  BlockBetween,
  BlockDes,
  Icon,
  Row,
  Col,
  Button,
  DataTableHead,
  DataTableBody,
  DataTableRow,
  DataTableItem,
  PaginationComponent,
  ReactDataTable,
  UserAvatar,
  PreviewCard,
} from "../../../components/Component";
import { Link } from "react-router-dom";
import { Card, Badge } from "reactstrap";
import { useHistory } from "react-router-dom";

import { makePostRequest, makeGetRequest } from "../../../service/api";
import ReactPaginate from "react-paginate";
import {
  AreaChart,
  Area,
  BarChart,
  Bar,
  ResponsiveContainer,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  Label,
  LineChart,
  Line,
  LabelList,
} from "recharts";
import moment from "moment";
import DatePicker from "react-datepicker";
import Select from "react-select";
import { parseBusinessHours } from "@fullcalendar/core";
import Highcharts, { chart } from "highcharts";
import { findUpper, capital } from "../../../utils/Utils";
import { Modal, ModalFooter, ModalHeader } from "reactstrap";
import HighchartsReact from "highcharts-react-official";
// import exporting from "highcharts/modules/exporting";
import { ToastContainer, toast } from "react-toastify";

// exporting(Highcharts);

const ExcelJS = require("exceljs");

function GroupResourceClickReport() {
  let history = useHistory();
  const [isOpen, setIsOpen] = useState(false);
  const [activeTab, setActiveTab] = useState("1");
  const [activeIconTab, setActiveIconTab] = useState("5");
  const [activeAltTab, setActiveAltTab] = useState("9");
  //   const [verticalTab, setVerticalTab] = useState("1");
  const [verticalIconTab, setVerticalIconTab] = useState("1");
  const [groupClickData, setGroupClickData] = useState([]);
  const [groupClickGraphData, setGroupClickGraphData] = useState([]);
  const [loader, setLoader] = useState(false);
  const [loader1, setLoader1] = useState(false);
  const [selectyear, setSelectyear] = useState(new Date().getFullYear());
  const [displayvalue, setDisplayValue] = useState("1");
  const [startdate, setStartDate] = useState("2023-01-01");
  const [enddate, setEndDate] = useState(new Date());
  const [selectedfromdate, setSelectedFromDate] = useState(null);
  const [selectedtodate, setSelectedToDate] = useState(null);
  const [user, setUser] = useState("");
  const [limitvalue, setlimitvalue] = useState("");
  const [sortvalue, setSortValue] = useState("");
  const [showchart, setshowchart] = useState([]);
  const [resourceTypeText, setResourceTypeText] = useState("");
  const [datepickershow, setDatePickerShow] = useState(false);
  const [datepickershow1, setDatePickerShow1] = useState(false);
  const [toDateError, settoDateError] = useState("");
  const [formDateError, setformDateError] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [groupreportModal, setGroupreportModal] = useState([]);
  const [totalDataAdd, setTotalDataAdd] = useState("");
  const [customerData, setCustomerData] = useState([]);
  const [dropdownPage, setDropdownPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [count, setCount] = useState();
  const [displayvalueSelectUser, setDisplayValueSelectUser] = useState(true);
  const [displayvalueSelectGroup, setDisplayValueSelectGroup] = useState(true);
  const [clearState, setClearState] = useState(false);
  // const passKey = { passGroupStatus: "Group" };

  useEffect(() => {
    document.body.classList.add("group-click");
    return () => {
      document.body.classList.remove("group-click");
    };
  }, []);

  const handleZipCode = (e) => {
    setZipCode(e.target.value);
  };

  const HandleFromDate = (e) => {
    setSelectedFromDate(e);
    setDatePickerShow(!datepickershow);
    setformDateError("");
  };

  const HandleToDate = (e) => {
    setSelectedToDate(e);
    setDatePickerShow1(!datepickershow1);
    settoDateError("");
  };

  const HandleLimitValue = (e) => {
    setlimitvalue(e.target.value);
    setCurrentPage(1);
  };
  // const HandleSort = (e) => {
  //   setSortValue(e.target.value);
  //   setCurrentPage(1);
  // };
  function handleclickdatepickericon() {
    setDatePickerShow(!datepickershow);
  }
  function handleclickdatepickericon1() {
    setDatePickerShow1(!datepickershow1);
  }

  const ExportEcelFile = () => {
    setLoader(true);
    let data = {
      start_date: selectedfromdate ? moment(selectedfromdate).format("YYYY-MM-DD") : "",
      end_date: selectedtodate ? moment(selectedtodate).add(1, "d").format("YYYY-MM-DD") : "",
      export_data: "yes",
      limit: limitvalue ? parseInt(limitvalue) : 10,
      page: currentPage ? currentPage : 1,
      group_id: groupName ? groupName : "",
      user_id: customerName ? customerName : "",
      zip_code: zipCode ? zipCode : "",
    };

    makePostRequest(URLS.GROUP_RESOURCE_CLICK, null, data, null)
      .then((res) => {
        if (res.code == 200) {
          // console.log("Resource export", res.data.data);
          const workbook = new ExcelJS.Workbook();
          const sheet = workbook.addWorksheet("GroupResourceClickdata");
          sheet.properties.defaultRowHeight = 30;

          sheet.getRow(1).font = {
            size: 10,
            bold: true,
          };

          sheet.columns = [
            {
              header: "S.No",
              key: "S_No",
              width: 5,
            },
            {
              header: "Date Click",
              key: "saveddate",
              width: 30,
            },

            {
              header: "Customer Name",
              key: "Customer_Name",
              width: 20,
            },
            // {
            //   header: "Group Name",
            //   key: "group_name",
            //   width: 20,
            // },
            {
              header: "ZipCode",
              key: "zipcode",
              width: 20,
            },
            {
              header: "Click Count",
              key: "click",
              width: 20,
            },
          ];
          res.data.data?.map((val, i) => {
            sheet.addRow({
              S_No: i + 1,
              saveddate: val?.created_at ? moment(val?.created_at).format("MM-DD-YYYY") : "--",
              Customer_Name: val?.tbl_user.firstName ? val?.tbl_user.firstName + " " + val?.tbl_user?.lastName : "--",
              zipcode: val?.tbl_user?.zipcode,
              click: val?.total_group_click_count,
            });
          });
          workbook.xlsx.writeBuffer().then((data) => {
            const blob = new Blob([data], {
              type: "application/vnd.openxmlformats-officedocument.spreadsheet.sheet",
            });
            const url = window.URL.createObjectURL(blob);
            const anchor = document.createElement("a");
            anchor.href = url;
            anchor.download = "GroupResourceClickReport.xlsx";
            anchor.click();
            window.URL.revokeObjectURL(url);
          });
          setLoader(false);
          // console.log(res.data, "res g")
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  /*========================= Resource Type Api Calling ============================*/

  const [groupType, setGroupType] = useState("");
  const [resourceTypedata, setResourceTypeData] = useState([]);
  const [resourceValue, setResourceValue] = useState("");
  const [resourceUserName, setResourceUserName] = useState("");
  const [userNameData, setUserNameData] = useState([]);
  const [groupName, setGroupName] = useState("");
  const [groupData, setGroupData] = useState([]);

  const selectRefUser = useRef(null);
  const selectRefGroup = useRef(null);

  const getAllGroup = () => {
    let data = {
      item_per_page: 20,
      page_number: 1,
      user_id: customerName,
    };
    // console.log(data, "Groupdata payload");
    setLoader(true);
    makePostRequest(URLS.GET_GROUPCLICK_LIST, null, data, null)
      .then((res) => {
        // console.log(res, "Groupdata");
        if (res.code == 200) {
          setGroupData(res.data.data);
          setLoader(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const displaySelectUser = (e) => {
    if (selectRefUser.current) {
      setDisplayValueSelectUser(!displayvalueSelectUser);
      selectRefUser.current.focus();
    }
  };

  const displaySelectGroup = (e) => {
    {
      setDisplayValueSelectGroup(!displayvalueSelectGroup);
      selectRefGroup.current.focus();
    }
  };
  /*===========================customer api =======================*/

  const getAllCustomerdata = () => {
    let data = {
      // item_per_page: 20,
      page_number: dropdownPage,
    };
    makePostRequest(URLS.CUSTOMER_FILTERDATA, null, data, null)
      .then((res) => {
        // console.log("responseCustomer", res);
        if (res.code == 200) {
          setCustomerData((prevOptions) => [...prevOptions, ...res?.data?.data]);
          setDropdownPage((prevPage) => prevPage + 1);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  // const getAllCustomerdata = () => {
  //     let data = {
  //       item_per_page: 20,
  //       page_number: 1,
  //     };

  //     setLoader(true);
  //     makePostRequest(URLS.CUSTOMER_FILTERDATA, null, data, null)
  //       .then((res) => {
  //         console.log(res, "Customerdata");
  //         if (res.code == 200) {
  //           setCustomerData(res.data.data);
  //           setLoader(false);
  //         }
  //       })
  //       .catch((error) => {
  //         console.log(error);
  //       });
  //   };

  useEffect(() => {
    getAllCustomerdata();
  }, []);

  const handleMenuScroll = (event) => {
    const { target } = event;
    if (target.scrollTop === target.scrollHeight - target.clientHeight) {
      getAllCustomerdata();
    }
  };

  const handleMenuScroll1 = (event) => {
    const { target } = event;
    if (target.scrollTop === target.scrollHeight - target.clientHeight) {
    }
  };

  const viewGroupClickDetailss = (row) => {
    let data = {
      user_id: row.tbl_user.id,
      start_date: row?.created_at,
      end_date: row?.created_at,
      zip_code: zipCode ? row.tbl_user.zipcode : "",
      group_id: groupName ? row?.tbl_group.id : "",
      page: 1,
      limit: 30,
    };
    // console.log("group details", data);
    makePostRequest(URLS.VIEW_GROUP_RESOURCE, null, data, null).then((res) => {
      if (res.code == 200) {
        // console.log("GroupDetails ", res);
        // setGroupreportModal((prevOptions) => [...prevOptions, ...res?.data?.data])
        setGroupreportModal(res?.data?.data);
        //  setDropdownPage(dropdownPage + 1);
        setIsOpen(!isOpen);
      } else {
        toast.error(res.message);
      }
    });
  };

  // console.log(groupType,"gtype")
  const handleResourceGroupTypeList = (e) => {
    setGroupType(e.target.value);

    if (e.target.value === "group") {
      setResourceUserName("");
      setResourceValue("");
    }

    if (e.target.value === "individual") {
      setGroupName("");
      setResourceValue("");
    }
    if (e.target.value === "") {
      setGroupName("");
      setResourceUserName("");
      setResourceValue("");
    }
  };

  const handleResourceTypeList = (e) => {
    setResourceValue(e.target.value);
  };

  const handleResourceUserList = (e) => {
    setResourceUserName(e.target.value);
  };

  const handleResourceGroupList = (e) => {
    setGroupName(e.value);
  };
  // console.log(groupName,"groupName")

  const [customerName, setCustomerName] = useState("");
  // console.log(customerName,"customerName")

  const handleCustomerGroup = (e) => {
    setCustomerName(e.value);

    setGroupName("");
    // selectRefGroup.current.clearValue()
    selectRefGroup.current.select.setValue("", "select-option");
  };

  useEffect(() => {
    getAllGroup();
  }, [customerName]);

  const openModal = () => {
    setIsOpen(!isOpen);
  };

  const handlesearch = () => {
    if (selectedfromdate != null && selectedtodate == null) {
      settoDateError("please select valid end date");
    } else if (selectedtodate != null && selectedfromdate == null) {
      setformDateError("please select valid start date ");
    } else if (selectedfromdate > selectedtodate) {
      toast.error("end date should be greater than start date");
    } else {
      getGroupClickListDataFunction();
      setCurrentPage(1);
    }
  };
  /*==============================Get Group click data ==============================*/

  const getGroupClickListDataFunction = () => {
    setLoader1(true);
    let data = {
      start_date: selectedfromdate ? moment(selectedfromdate).format("YYYY-MM-DD") : "",
      end_date: selectedtodate ? moment(selectedtodate).add(1, "d").format("YYYY-MM-DD") : "",
      export_data: "no",
      limit: limitvalue ? parseInt(limitvalue) : 10,
      page: currentPage ? currentPage : 1,
      //   resource_id: resourceValue ? resourceValue : "",
      group_id: groupName ? groupName : "",
      user_id: customerName ? customerName : "",
      zip_code: zipCode ? zipCode : "",
    };
    // console.log(data, "data group click");
    makePostRequest(URLS.GROUP_RESOURCE_CLICK, null, data, null)
      .then((res) => {
        if (res.code === 200) {
          // console.log(res, "Group resource Click data list");
          setGroupClickData(res?.data.data);
          setCount(Math.ceil(res.data.count / parseInt(limitvalue ? limitvalue : 10)));
          setLoader1(false);
          setTotalDataAdd(res.data.count);
        } else {
          setLoader1(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handlePageChange = (selectedPage) => {
    setCurrentPage(selectedPage.selected + 1);
  };
  // const fetchData = () => {
  //   // setLoader1(true)
  //   let data = {

  //     start_date: selectedfromdate ? moment(selectedfromdate).format("YYYY-MM-DD") : "",
  //     end_date: selectedtodate ? moment(selectedtodate).add(1, "d").format("YYYY-MM-DD") : "",
  //     export_data: "no",
  //     limit: limitvalue ? parseInt(limitvalue) : 10,
  //     page: currentPage ? currentPage : 1,
  //   //   resource_id: resourceValue ? resourceValue : "",
  //     group_id: groupName ? groupName : "",
  //       user_id:customerName ? customerName :"",
  //     zip_code: zipCode ? zipCode : "",
  //   }
  //   makePostRequest(URLS.GROUP_RESOURCE_CLICK, null, data, null)
  //     .then((res) => {
  //       if (res.code == 200) {
  //         setLoader1(false)
  //         setGroupClickData(res?.data.data);
  //       setCount(Math.ceil(res.data.count / parseInt(limitvalue ? limitvalue : 10)));
  //       setTotalDataAdd(res.data.count);
  //       }
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // };

  // useEffect(() => {
  //   fetchData()
  // }, [currentPage]);
  const clearfilterfun = () => {
    setSelectedFromDate("");
    setSelectedToDate("");
    setZipCode("");
    getGroupClickListDataFunction();
    setCustomerName("");
    setGroupName("");
    setCurrentPage(1);
    setClearState(!clearState);
    selectRefGroup.current.select.setValue("", "select-option");
    selectRefUser.current.select.setValue("", "select-option");
  };

  const clearfilter = () => {
    clearfilterfun();
  };

  useEffect(() => {
    getGroupClickListDataFunction();
  }, [clearState]);

  useEffect(() => {
    getGroupClickListDataFunction();
  }, [limitvalue, currentPage]);

  const columns = [
    {
      name: "#",
      selector: (row) => row.id,
      grow: 1,
      minWidth: "50px",
      style: { minWidth: "50px" },
      borderRadius: "4px",
      cell: (row, index) => (
        <>
          <div className="user-card mt-2 mb-2">
            {" "}
            {currentPage == 1
              ? index + 1
              : (currentPage - 1) * (limitvalue != "" ? parseInt(limitvalue) : 10) + (index + 1)}{" "}
          </div>
        </>
      ),
    },
    {
      name: "Click Date",
      selector: (row) => "",
      grow: 4,

      cell: (row, index) => (
        <>
          <div className="user-card mt-2 mb-2">
            <span className="tb-lead">{row?.created_at ? moment(row?.created_at).format("MM-DD-YYYY") : "--"}</span>
          </div>
        </>
      ),
    },
    {
      name: "Customer Name",
      selector: (row) => "",
      grow: 4,

      cell: (row, index) => (
        <>
          <div className="user-card mt-2 mb-2">
            <div className="user-info">
              <span className="sub-text">
                {row?.tbl_user.firstName ? row?.tbl_user.firstName + " " + row?.tbl_user?.lastName : "--"}
                {/* &nbsp; <Link to={`/view-resource/${row?.tbl_user_resource?.id}`}>  <button className="dropdown-item btn btn-md" >
              <em className="icon ni ni-eye"></em>
            </button>
            </Link> */}
              </span>
            </div>
          </div>
        </>
      ),
    },

    {
      name: "Zipcode",
      selector: (row) => "",
      grow: 3,
      cell: (row, index) => (
        <>
          <div className="user-card mt-2 mb-2">
            <div className="user-info">
              <span className="sub-text">{row?.tbl_user?.zipcode}</span>
            </div>
          </div>
        </>
      ),
    },

    // {
    //   name: "Group Name",
    //   selector: (row) => row?.month,
    //   grow: 4,
    //   cell: (row, index) => (
    //     <>
    //       <div className="user-card mt-2 mb-2">
    //         <div className="user-info">
    //           <span className="sub-text">
    //             {row?.tbl_group ? capital(row?.tbl_group?.group_name) : "--"}
    //             {/* &nbsp;<Link to={`/view-groupdetail/${row?.tbl_group?.id}`}>  <button className="dropdown-item btn btn-md" >
    //           <em className="icon ni ni-eye"></em>
    //         </button>
    //         </Link> */}
    //           </span>
    //         </div>
    //       </div>
    //     </>
    //   ),
    // },

    {
      name: "Click Count",
      selector: (row) => row?.tbl_user,
      grow: 3,
      justifyContent: "center",
      cell: (row, index) => (
        <>
          <div className="user-card user-cardCenter mt-2 mb-2">
            <div className="user-info text-center">
              <span className="tb-lead">{row?.total_group_click_count ? row?.total_group_click_count : "--"}</span>
            </div>
          </div>
        </>
      ),
    },

    {
      name: "View Details",

      grow: 2,
      center: true,
      sortable: true,
      cell: (row) => (
        <div className="user-card mt-2 mb-2">
          {" "}
          <span>
            <button
              className="btn btn-md"
              onClick={() => {
                viewGroupClickDetailss(row);
              }}
            >
              <em className="icon ni ni-eye"></em>
            </button>
          </span>{" "}
        </div>
      ),
    },
  ];
  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };
  const toggleIconTab = (icontab) => {
    if (activeIconTab !== icontab) setActiveIconTab(icontab);
  };
  const toggleAltTab = (alttab) => {
    if (activeAltTab !== alttab) setActiveAltTab(alttab);
  };
  const newRef = useRef(null);
  const newRef1 = useRef(null);
  const handleOutSideClick = (e) => {
    if (newRef.current && !newRef.current.contains(e.target)) {
      setDatePickerShow(false);
    }
  };
  const handleOutSideClick1 = (e) => {
    if (newRef1.current && !newRef1.current.contains(e.target)) {
      setDatePickerShow1(false);
    }
  };
  useEffect(() => {
    document.addEventListener("mousedown", handleOutSideClick);
    document.addEventListener("mousedown", handleOutSideClick1);
    return () => {
      document.removeEventListener("mousedown", handleOutSideClick);
      document.removeEventListener("mousedown", handleOutSideClick1);
    };
  });
  return (
    <React.Fragment>
      <Head title="Group Click Report"></Head>
      <Content>
        <h3 className="nk-block-title page-title mb5 pb-4">Group Resource Click Report</h3>
        <>
          <Card className="card-bordered">
            <Modal isOpen={isOpen} toggle={openModal}>
              <ModalHeader
                toggle={openModal}
                close={
                  <button className="close" onClick={openModal}>
                    <Icon name="cross" />
                  </button>
                }
              >
                Click Details(
                {groupreportModal && groupreportModal.length > 0 && groupreportModal[0]?.tbl_group?.group_name})
              </ModalHeader>
              <Card className="card-bordered">
                <div className="card-inner">
                  <BlockHeadContent>
                    <BlockHead size="lg">
                      <div className="customerDetailsTable">
                        {" "}
                        {/* onScroll={handleMenuScroll} */}
                        <table width="100%">
                          <tr className="border-bottom">
                            <th>#</th>
                            <th>Click Date/Time </th>
                            {/* <th>Customer Name</th> */}
                            <th>Group Name</th>
                            <th>Group Resource</th>
                            {/* <th>Address</th> */}
                          </tr>
                          {groupreportModal &&
                            groupreportModal.map((val, i) => {
                              return (
                                <tr className="border-bottom">
                                  <td>{i + 1}</td>
                                  <td>{moment(val?.created_at).format("MM-DD-YYYY hh:mm a")}</td>
                                  {/* <td>{capital(val?.tbl_user?.firstName.trim())} {(val?.tbl_user?.lastName)}</td> */}
                                  <td>{val?.tbl_group?.group_name}</td>
                                  <td>
                                    {val?.tbl_user_resource?.resource_title}
                                    <span>
                                     
                                      {/* <button 
    className="btn btn-md"
    onClick={() => {
        const newWindow = window.open(
            `/view-resource/${val?.tbl_user_resource.id}`,
            '_blank',
        );
        // newWindow.localStorage.setItem('passKey',"group");
        newWindow.passKey = "group";
    }}
>
    <em className="icon ni ni-eye"></em>
</button> */}
{/* <Link
    to={`/view-resource/${val?.tbl_user_resource.id}`}
    onClick={(e) => {
        e.preventDefault();
        const newWindow = window.open(e.currentTarget.href, '_blank');
        newWindow.passKey = "group";
    }}
>
    <button className="btn btn-md">
        <em className="icon ni ni-eye"></em>
    </button>
</Link> */}
<Link
    to={`/view-resource/${val?.tbl_user_resource.id}?passKey=group`}
    onClick={(e) => {
        e.preventDefault();
        const newWindow = window.open(e.currentTarget.href, '_blank');
    }}
>
    <button className="btn btn-md">
        <em className="icon ni ni-eye"></em>
    </button>
</Link>
                                    </span>
                                  </td>
                                  {/* <td>{val?.tbl_user?.address}</td> */}
                                </tr>
                              );
                            })}
                        </table>
                      </div>
                    </BlockHead>
                  </BlockHeadContent>
                </div>
              </Card>
              <ModalFooter className=""></ModalFooter>
            </Modal>
            <div className="card-inners relativeClass">
              <div className="card-inners relativeClass">
                <BlockHeadContent>
                  <BlockHead size="lg">
                    <div className="p-4">
                      <div className="d-flex justify-content-between mb-2">
                        <div className="filtersidebutton">
                          <h3 className="listheading mb-0 mt-1">List View</h3>
                          <button className="btn btn-md btn-primary" type="submit" onClick={clearfilter}>
                            Clear Filters
                          </button>
                        </div>
                        <div className="row">
                          <div className="col-lg-12 d-flex  justify-content-end">
                            <div>
                              <label className="mt-1 ">&nbsp;&nbsp;Show&nbsp;</label>
                            </div>
                            <div>
                              <select
                                value={limitvalue}
                                className="form-select"
                                style={{ width: "85px" }}
                                onChange={HandleLimitValue}
                              >
                                <option value="10">10</option>
                                <option value="20">20</option>
                                <option value="30">30</option>
                                <option value="50">50</option>
                                <option value="100">100</option>
                              </select>
                            </div>
                            <div>
                              &nbsp; &nbsp;
                              <button className="btn btn-primary " onClick={ExportEcelFile}>
                                <em className="icon ni ni-download-cloud"></em>
                                <span>Export</span>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="row pb-2">
                        <div className="col-lg-4">
                          <div className="form-control border-0 px-0">
                            <label>
                              <strong>Start Date</strong>
                            </label>
                            {/* <input type="date" name="formdate" className="form-control" onChange={HandleFromDate} /> */}

                            <div className="form-control-wrap" ref={newRef}>
                              <div className="form-icon form-icon-left">
                                <span onClick={() => handleclickdatepickericon()}>
                                  <Icon name="calendar"></Icon>
                                </span>
                              </div>
                              <DatePicker
                                placeholderText="MM/DD/YYYY"
                                selected={selectedfromdate}
                                onChange={HandleFromDate}
                                onFocus={() => setDatePickerShow(!datepickershow)}
                                className="form-control date-picker"
                                open={datepickershow}
                              />
                            </div>
                          </div>
                          <span className="error">{formDateError}</span>
                        </div>
                        <div className="col-lg-4">
                          <div className="form-control border-0 px-0">
                            <label>
                              <strong>End Date</strong>
                            </label>
                            {/* <input type="date" name="formdate" className="form-control" onChange={HandleFromDate} /> */}

                            <div className="form-control-wrap" ref={newRef1}>
                              <div className="form-icon form-icon-left">
                                <span onClick={() => handleclickdatepickericon1()}>
                                  <Icon name="calendar"></Icon>
                                </span>
                              </div>
                              <DatePicker
                                placeholderText="MM/DD/YYYY"
                                selected={selectedtodate}
                                onChange={HandleToDate}
                                onFocus={() => setDatePickerShow1(!datepickershow1)}
                                className="form-control date-picker"
                                open={datepickershow1}
                              />
                            </div>
                          </div>
                          <span className="error">{toDateError}</span>
                        </div>

                        <div className="col-md-4">
                          <label className="lavelinfo">
                            <strong>Zip Code</strong>
                          </label>
                          <div className="form-control-wrap">
                            <input
                              placeholder="Zipcode"
                              type="text"
                              className="form-control pl-2"
                              value={zipCode}
                              onChange={handleZipCode}
                            />
                          </div>
                        </div>

                        <div className="col-lg-4">
                          <label>
                            <strong>Customer Name</strong>
                          </label>
                          <div className="form-control-wrap paymetsession" style={{ marginBottom: "10px" }}>
                            <i className="fa fa-chevron-down chevron-downcustome" onClick={displaySelectUser}></i>
                            <Select
                              className="form-select"
                              defaultvalue={customerName}
                              ref={selectRefUser}
                              openMenuOnFocus={displayvalueSelectUser}
                              onChange={handleCustomerGroup}
                              //onMenuScrollToBottom={handleMenuScroll}
                              isClearable={true}
                              // options={resourceDropdown?.map((v) => ({ value: v?.resource_id, label: v?.tbl_user_resource?.resource_title }))}
                              options={[
                                { value: "", label: "All" },
                                ...customerData?.map((v) => ({
                                  value: v?.user_id,
                                  label: v?.tbl_user?.firstName.trim() + " " + v?.tbl_user?.lastName.trim(),
                                })),
                              ]}
                            ></Select>

                            {/* <select
                              className="form-select"
                              name="resourceType"
                              onChange={handleCustomerGroup}
                              value={customerName}
                            >
                              <option value=""> All Customer </option>
                              {customerData.map((val, index) => {
                                return (
                                  <>
                                    <option value={val?.tbl_user?.id} key={index + 1}>
                                      {(val?.tbl_user?.firstName)+" "+(val?.tbl_user?.lastName)}
                                    </option>
                                  </>
                                );
                              })}
                            </select> */}
                          </div>
                        </div>

                        <div className="col-lg-4">
                          <label>
                            <strong>Group Name</strong>
                          </label>
                          <div className="form-control-wrap paymetsession" style={{ marginBottom: "10px" }}>
                            <i className="fa fa-chevron-down chevron-downcustome" onClick={displaySelectGroup}></i>
                            <Select
                              className="form-select"
                              defaultvalue={groupName}
                              onChange={handleResourceGroupList}
                              openMenuOnFocus={displayvalueSelectGroup}
                              ref={selectRefGroup}
                              //onMenuScrollToBottom={handleMenuScroll}
                              // options={resourceDropdown?.map((v) => ({ value: v?.resource_id, label: v?.tbl_user_resource?.resource_title }))}
                              options={[
                                { value: "", label: "All" },
                                ...groupData?.map((v) => ({
                                  value: v?.tbl_group?.id,
                                  label: v?.tbl_group?.group_name.trim(),
                                })),
                              ]}
                            ></Select>

                            {/* <select
                              className="form-select"
                              name="resourceType"
                              onChange={handleResourceGroupList}
                              value={groupName}
                            >
                              <option value=""> All Group </option>
                              {groupData.map((val, index) => {
                                return (
                                  <>
                                    <option value={val?.tbl_group?.id} key={index + 1}>
                                      {capital(val?.tbl_group?.group_name.trim())}
                                    </option>
                                  </>
                                );
                              })}
                            </select> */}
                          </div>
                        </div>

                        <div className="col-lg-4">
                          <label className="label w-100">
                            <strong>&nbsp;</strong>
                          </label>
                          <div className="form-control border-0 p-0">
                            <button className="btn btn-primary" type="submit" onClick={handlesearch}>
                              {" "}
                              Search{" "}
                            </button>
                          </div>
                        </div>

                        {/* {groupName ? (
                          <div className="col-lg-3">
                            <div className="form-control border-0 px-0" style={{ marginBottom: "10px" }}>
                              <label>
                                <strong>Resource Title</strong>
                              </label>
                              <select
                                className="form-select"
                                name="resourceType"
                                onChange={handleResourceTypeList}
                                value={resourceValue}
                              >
                                <option value=""> All Resource </option>
                                {resourceTypedata.map((val, index) => {
                                  return (
                                    <>
                                      <option value={val?.tbl_user_resource?.id} key={index + 1}>
                                        {capital(val?.tbl_user_resource?.resource_title.trim())}
                                      </option>
                                    </>
                                  );
                                })}
                              </select>
                            </div>
                          </div>
                        ) : (
                          ""
                        )} */}
                      </div>
                      {/* <div class="d-flex flex-row" style={{ gap: "10px" }}>
                        
                        <div className="outerTypeBox mb-3">
                          <button className="btn btn-primary" type="submit" onClick={handlesearch}>
                            {" "}
                            Search{" "}
                          </button>
                        </div>
                      </div> */}
                    </div>
                    <div className="relativeClass newregisterGrouptable">
                      {loader1 ? (
                        <div className="overflowloader" id="preload">
                          <div className="circle-square">
                            <div className="red" style={{ background: "#AAC0CE" }}></div>
                            <div className="blue" style={{ background: "#265472" }}></div>
                            <div className="green" style={{ background: "#F19357" }}></div>
                            <div className="yellow" style={{ background: "#F0B285" }}></div>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}

                      <div className="totalRight">
                        <span style={{ paddingRight: "20px" }}>
                          <strong>Total Count:{totalDataAdd}</strong>
                        </span>
                      </div>
                      {groupClickData?.length === 0 ? (
                        <>
                          <p className="p-4" style={{ minHeight: "100px" }}>
                            {""}
                          </p>
                        </>
                      ) : (
                        <div className="color">
                          <ReactDataTable data={groupClickData} columns={columns} className="nk-tb-list" />
                        </div>
                      )}
                    </div>
                    <div className="p-4">
                      {groupClickData?.length > 0 ? (
                        <div>
                          <ReactPaginate
                            previousLabel={"<<"}
                            nextLabel={">>"}
                            pageCount={count}
                            pageRangeDisplayed={5}
                            marginPagesDisplayed={2}
                            onPageChange={handlePageChange}
                            containerClassName={"pagination"}
                            pageClassName={"page-item"}
                            activeClassName={"active"}
                            pageLinkClassName={"page-link"}
                            previousClassName={"page-item"}
                            previousLinkClassName={"page-link"}
                            nextClassName={"page-item"}
                            nextLinkClassName={"page-link"}
                            breakClassName={"page-item"}
                            breakLinkClassName={"page-link"}
                            forcePage={currentPage - 1}
                          />
                        </div>
                      ) : (
                        <p className="p-4" style={{ minHeight: "100px" }}>
                          No Data Found
                        </p>
                      )}
                    </div>
                  </BlockHead>
                </BlockHeadContent>
              </div>
            </div>
          </Card>
        </>
        {/* )} */}
      </Content>
    </React.Fragment>
  );
}
export default GroupResourceClickReport;
